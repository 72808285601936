import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import TEMPLATES from '.';
import {
  moveToScreen,
  nextScreen,
  previousScreen,
  unblockNavigation,
} from '../stores/viewSlice';
import {
  DELAY_BETWEEN_SCREENS_ON_CLICK,
  EMBEDDED_MOBILE_APP_HEIGHT_VALUE,
} from '../utils/constants';
import { withResponsiveUtils } from '../utils/responsive/ResponsiveUtils';
import TEMPLATE_TYPES from '../utils/enums/templateTypes';

const getRoombaAnimation = ({ embedMobileAppHeight, isDesktop, isQr }) => {
  const isiPadLandscape =
    window.innerWidth === 1024 && window.innerHeight === 768;

  const defaultInitialValues = {
    x: '155%',
    y: '30%',
    rotateZ: -36,
  };

  const iPadLandscapeInitialValues = {
    x: '170%',
    y: '40%',
    rotateZ: -36,
  };

  const initialValues =
    isiPadLandscape && isQr ? iPadLandscapeInitialValues : defaultInitialValues;

  const deskAni = {
    initialValues,
    afterClicked: {
      x: '-27%',
      y: '-30%',
      rotateZ: -36,
    },
  };

  const mobAni = {
    initialValues: {
      x: '-51%',
      y: isQr
        ? window.innerHeight - (window.innerHeight / window.innerWidth) * 90
        : embedMobileAppHeight - 150,
      rotateZ: 24.5,
    },
    afterClicked: {
      x: '-51%',
      y: -(embedMobileAppHeight / 2.5),
      rotateZ: 24.5,
    },
  };

  return isDesktop ? deskAni : mobAni;
};

const getPropsByTemplateName = (allProps, templateName) => {
  const {
    handleNextScreen,
    isNavigatingBack,
    pageData,
    roombaAnimation,
    handleUnblockNavigation,
    handleMoveToScreen,
    handleNavigationPrevScreen,
    userPreviousSelection,
    isTransitioning,
    blockNavigation,
    style,
  } = allProps;

  const commonProps = {
    pageData,
    handleUnblockNavigation,
  };

  const propsByTemplateObject = {
    [TEMPLATE_TYPES.QUIZ_START]: {
      ...commonProps,
      isNavigatingBack,
      handleNextScreen,
      roombaAnimation,
    },
    [TEMPLATE_TYPES.KICKOFF]: {
      ...commonProps,
      handleMoveToScreen,
      handleNextScreen,
      handleNavigationPrevScreen,
      userPreviousSelection,
      roombaAnimation,
    },
    [TEMPLATE_TYPES.FOUR_ANSWER_ILLUSTRATION]: {
      ...commonProps,
      handleNextScreen,
      handleNavigationPrevScreen,
      isTransitioning,
      userPreviousSelection,
    },
    [TEMPLATE_TYPES.SLIDER]: {
      ...commonProps,
      handleNextScreen,
      handleNavigationPrevScreen,
      isTransitioning,
      userPreviousSelection,
    },
    [TEMPLATE_TYPES.TWO_ANSWER_ILLUSTRATION]: {
      ...commonProps,
      handleNavigationPrevScreen,
      handleNextScreen,
      isTransitioning,
      userPreviousSelection,
    },
    [TEMPLATE_TYPES.YES_OR_NO]: {
      ...commonProps,
      handleNavigationPrevScreen,
      handleNextScreen,
      isNavigatingBack,
      userPreviousSelection,
      blockNavigation,
    },
    [TEMPLATE_TYPES.MULTIPLE_CHOICE]: {
      ...commonProps,
      handleNextScreen,
      handleNavigationPrevScreen,
      isTransitioning,
      userPreviousSelection,
    },
    [TEMPLATE_TYPES.RADIO_ICON]: {
      ...commonProps,
      handleNextScreen,
      handleNavigationPrevScreen,
      isTransitioning,
      userPreviousSelection,
    },
    [TEMPLATE_TYPES.SIMPLE_QUESTION]: {
      ...commonProps,
      handleNextScreen,
      handleNavigationPrevScreen,
      isTransitioning,
      userPreviousSelection,
    },
    [TEMPLATE_TYPES.BUDGET]: {
      ...commonProps,
      handleNextScreen,
      handleNavigationPrevScreen,
      isTransitioning,
      userPreviousSelection,
    },
    [TEMPLATE_TYPES.RESULTS]: {
      ...commonProps,
      handleNavigationPrevScreen,
      style,
    },
  };

  return propsByTemplateObject[templateName];
};

const GetCurrentScreen = withResponsiveUtils(
  ({
    style,
    item,
    setIsNavigatingBack,
    isNavigatingBack,
    mobileResponsiveUtil,
    isQr,
    isDesktop,
  }) => {
    const dispatch = useDispatch();
    const {
      blockNavigation,
      isScrollingBlocked,
      userPreviousSelection,
      screensConfigs,
    } = useSelector((state) => state.view);
    const pageData = screensConfigs[item];
    const Template = TEMPLATES[pageData.templateType];
    const [isTransitioning, setIsTransitioning] = useState(false);
    const embedMobileAppHeight = mobileResponsiveUtil.getWidthValue(
      EMBEDDED_MOBILE_APP_HEIGHT_VALUE,
    );

    const handleNextScreen = (payload = {}) => {
      const { delayTime = DELAY_BETWEEN_SCREENS_ON_CLICK, ...restPayload } =
        payload;

      if (!blockNavigation) {
        setIsNavigatingBack(false);
        setIsTransitioning(true);

        setTimeout(() => {
          dispatch(nextScreen({ ...restPayload }));
          setIsTransitioning(false);
        }, delayTime);
      }
    };

    const handleNavigationPrevScreen = (event) => {
      event.stopPropagation();

      if (!blockNavigation) {
        setIsNavigatingBack(true);
        dispatch(previousScreen());
      }
    };

    const handleMoveToScreen = (payload) => {
      if (!blockNavigation) {
        setIsNavigatingBack(false);
        setIsTransitioning(true);

        setTimeout(() => {
          dispatch(moveToScreen({ ...payload }));
          setIsTransitioning(false);
        }, DELAY_BETWEEN_SCREENS_ON_CLICK);
      }
    };

    const handleUnblockNavigation = () => {
      dispatch(unblockNavigation());
    };

    const getTemplateProps = () => {
      const allProps = {
        pageData: { ...pageData, isQr },
        style,
        handleNextScreen,
        handleMoveToScreen,
        handleNavigationPrevScreen,
        handleUnblockNavigation,
        isNavigatingBack,
        isTransitioning,
        userPreviousSelection,
        roombaAnimation: getRoombaAnimation({
          embedMobileAppHeight,
          isDesktop,
          isQr,
        }),
        blockNavigation,
      };

      return getPropsByTemplateName(allProps, pageData?.templateType);
    };

    return (
      <TemplateContainer isScrollingBlocked={isScrollingBlocked}>
        <Template {...getTemplateProps()} />
      </TemplateContainer>
    );
  },
);

const TemplateContainer = withResponsiveUtils(styled.div`
  &&& {
    z-index: 1;
    width: 100%;
    position: relative;
    overflow-y: auto;
    height: ${({ isQr, isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      // eslint-disable-next-line no-nested-ternary
      isQr
        ? `${window.innerHeight}px`
        : isDesktop
        ? `${webResponsiveUtil.getWidthValue(950)}px`
        : `${mobileResponsiveUtil.getWidthValue(
            EMBEDDED_MOBILE_APP_HEIGHT_VALUE,
          )}px`};

    overflow: ${({ isScrollingBlocked }) =>
      isScrollingBlocked ? 'hidden' : 'auto'};
  }
`);

export default GetCurrentScreen;
