import React from 'react';
import { animated, useSpring } from '@react-spring/web';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { fontVariables } from '../../styles/variables';
import {
  withResponsiveUtils,
  isMobile,
} from '../../utils/responsive/ResponsiveUtils';

const InputField = ({
  onChange,
  onBlur,
  value,
  placeholder,
  error,
  disabled,
  type,
  name,
}) => {
  const isDesktop = !isMobile();

  const changeColor = useSpring({
    background: value.length ? '#DCE9D5' : '#fff',
  });
  return (
    <StyledInputContainer
      className={`input-field__input-container ${
        error || Object.keys(error).length > 0 ? 'input-field--invalid' : ''
      }`}
      isDesktop={isDesktop}
    >
      <StyledEmailInput
        className="input-field__input"
        isDesktop={isDesktop}
        type={type}
        style={changeColor}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        name={name}
        backgroundColor
      />
      {(error || !isEmpty(error)) && (
        <ErrorContainer className="error" isDesktop={isDesktop}>
          {typeof error === 'string' ? error : Object.values(error).join('. ')}
        </ErrorContainer>
      )}
    </StyledInputContainer>
  );
};

const ErrorContainer = withResponsiveUtils(styled.span`
  &&& {
    font-size: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(11)
        : mobileResponsiveUtil.getWidthValue(10)}px;
    font-weight: ${fontVariables.weights.normal};
    color: darkred;
    position: absolute;
    line-height: normal;
    top: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getHeightValue(6)
        : mobileResponsiveUtil.getWidthValue(5)}px;
    left: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(10)
        : mobileResponsiveUtil.getWidthValue(10)}px;
    z-index: 1;
  }
`);

const StyledEmailInput = withResponsiveUtils(
  animated(styled.input`
    &&& {
      border: 0;
      height: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getHeightValue(48)
          : mobileResponsiveUtil.getWidthValue(39)}px;
      font-family: ${fontVariables.families.primaryFont};
      font-size: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getHeightValue(14)
          : mobileResponsiveUtil.getWidthValue(13)}px;
      color: #447355;
      font-weight: ${fontVariables.weights.semibold};
      display: block;
      width: 100%;
      outline: none;
      box-sizing: border-box;
      padding: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? `${webResponsiveUtil.getHeightValue(
              16,
            )}px ${webResponsiveUtil.getWidthValue(10)}px`
          : `${mobileResponsiveUtil.getWidthValue(
              13,
            )}px ${mobileResponsiveUtil.getWidthValue(
              10,
            )}px ${mobileResponsiveUtil.getWidthValue(9)}px`};

      border-radius: 5px !important;
      margin-bottom: ${({ isDesktop, mobileResponsiveUtil }) =>
        isDesktop ? 'auto' : mobileResponsiveUtil.getWidthValue(16)}px;

      appearance: none;

      &::placeholder {
        color: #447355;
      }
      &:disabled {
        opacity: 0.75;
      }
      .input-field--invalid & {
        padding-top: ${({
          isDesktop,
          mobileResponsiveUtil,
          webResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(24)
            : mobileResponsiveUtil.getWidthValue(24)}px;
        padding-bottom: ${({
          isDesktop,
          mobileResponsiveUtil,
          webResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(8)
            : mobileResponsiveUtil.getWidthValue(8)}px;
      }
    }
  `),
);

const StyledInputContainer = withResponsiveUtils(styled.div`
  &&& {
    position: relative;
    width: 100%;
    margin-right: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(17)
        : mobileResponsiveUtil.getWidthValue(0)}px;
  }
`);

InputField.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
};

InputField.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  value: '',
  placeholder: '',
  error: '',
  disabled: true,
  type: 'text',
  name: '',
};

export default InputField;
