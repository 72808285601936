export const DELAY_BETWEEN_SCREENS_ON_CLICK = 400; // ms
export const BLOCKING_SCREEN_TIME = 1000; // ms - this is to prevent the user to speed run the quiz
export const YES_OR_NO_BLOCKING_SCREEN_TIME = 500;
export const QUIZ_EXPERIENCE_ID = 'irobot-embed-quiz-v2';
export const WEIGHTS_BASE_NUMBER = 10;
export const RECOMMENDED_PRODUCTS_NUM = 3;
export const EMBEDDED_MOBILE_APP_HEIGHT_VALUE = 735;

const CONSTANTS = {
  DELAY_BETWEEN_SCREENS_ON_CLICK,
  QUIZ_EXPERIENCE_ID,
  WEIGHTS_BASE_NUMBER,
  RECOMMENDED_PRODUCTS_NUM,
  BLOCKING_SCREEN_TIME,
  YES_OR_NO_BLOCKING_SCREEN_TIME,
  EMBEDDED_MOBILE_APP_HEIGHT_VALUE,
};

export default CONSTANTS;
