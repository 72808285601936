import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring } from '@react-spring/web';

import DIRECTIONS from '../utils/enums/directions';
import SVGWrapper from './SVGWrapper';
import Image from './Image';

import {
  withResponsiveUtils,
  isMobile,
} from '../utils/responsive/ResponsiveUtils';
import { fontVariables } from '../styles/variables';

const OptionCard = ({
  text,
  ComponentImage,
  imgPosition,
  onClick,
  style,
  ComponentText,
  active,
  src,
  altText,
  special,
  imgStyle,
}) => {
  const animationStyle = useSpring(style);
  let optionCardClass = 'option-card';

  if (imgPosition) {
    optionCardClass += ` option-card--${imgPosition.toLowerCase()}`;
  }

  return (
    <StyledOptionCard
      imgPosition={imgPosition}
      onClick={onClick}
      className={`${optionCardClass} ${active ? 'active' : ''}`}
      style={animationStyle}
    >
      <div className="img-container">
        {special ? (
          <CardImage
            alt={altText}
            src={src}
            imgPosition={imgPosition}
            style={imgStyle}
          />
        ) : (
          <ComponentImage />
        )}
      </div>
      <div className="text-container">
        {ComponentText ? <ComponentText /> : text}
      </div>
    </StyledOptionCard>
  );
};

const CardImage = withResponsiveUtils(styled(Image)`
  &&& {
    position: absolute;
    bottom: 0;
    height: 120%;
    left: ${({ imgPosition, webResponsiveUtil, mobileResponsiveUtil }) =>
      // eslint-disable-next-line no-nested-ternary
      imgPosition === DIRECTIONS.RIGHT
        ? isMobile()
          ? `${mobileResponsiveUtil.getWidthValue(-45)}px`
          : `${webResponsiveUtil.getWidthValue(-105)}px`
        : 'auto'};
    right: ${({ imgPosition, webResponsiveUtil, mobileResponsiveUtil }) =>
      // eslint-disable-next-line no-nested-ternary
      imgPosition === DIRECTIONS.LEFT
        ? isMobile()
          ? `${mobileResponsiveUtil.getWidthValue(-25)}px`
          : `${webResponsiveUtil.getWidthValue(-75)}px`
        : 'auto'};
  }
`);

const StyledOptionCard = animated(
  withResponsiveUtils(styled.div`
    &&& {
      font-size: ${({ webResponsiveUtil, mobileResponsiveUtil }) =>
        isMobile()
          ? `${mobileResponsiveUtil.getWidthValue(18)}px`
          : `${webResponsiveUtil.getWidthValue(31)}px`};

      font-weight: ${fontVariables.weights.normal};
      background-color: #fff;
      border-radius: 16px;
      display: flex;
      align-items: stretch;
      cursor: pointer;
      transition: background-color 0.5s;
      flex-direction: ${({ imgPosition }) =>
        imgPosition === DIRECTIONS.RIGHT ? 'row-reverse' : 'row'};

      &:hover,
      &.active {
        background-color: #dedede;
        transition: background-color 0.5s;
      }

      span {
        display: block;
      }

      .img-container {
        position: relative;
        width: 50%;
      }

      .image {
        position: absolute;
        bottom: 0;
      }
      .text-container {
        line-height: 1.2;
        padding: ${({ mobileResponsiveUtil, webResponsiveUtil }) =>
          isMobile()
            ? `${mobileResponsiveUtil.getWidthValue(
                20,
              )}px ${mobileResponsiveUtil.getWidthValue(22)}px`
            : ` ${webResponsiveUtil.getWidthValue(
                45,
              )}px ${webResponsiveUtil.getWidthValue(40)}px`};

        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        white-space: pre-wrap;
      }
    }
  `),
);

OptionCard.propTypes = {
  text: PropTypes.string,
  ComponentText: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  ComponentImage: PropTypes.elementType,
  imgPosition: PropTypes.oneOf([DIRECTIONS.RIGHT, DIRECTIONS.LEFT]).isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  active: PropTypes.bool,
  src: PropTypes.string,
  altText: PropTypes.string,
  special: PropTypes.bool,
  imgStyle: PropTypes.shape({}),
};

OptionCard.defaultProps = {
  text: null,
  ComponentText: null,
  style: {},
  active: false,
  src: '',
  altText: '',
  special: false,
  ComponentImage: null,
  imgStyle: {},
};

export const optionCardParser =
  (dimensions) =>
  ({ text, svgSrc, altText, value, onClick, ...item }, index) => ({
    ...item,
    ComponentImage: () => (
      <SVGWrapper
        svgSrc={svgSrc}
        altText={altText || ''}
        dimensions={dimensions[index]}
      />
    ),
    text,
    value,
    onClick,
  });

export default OptionCard;
