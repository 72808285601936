import React, { useState, useEffect, useRef } from 'react';
import { animated, useSpring } from '@react-spring/web';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import axios from 'axios';
import NavFooter from '../components/NavFooter';
import { fontVariables } from '../styles/variables';
import {
  withResponsiveUtils,
  isMobile,
  RESPONSIVE_UTIL_PROPTYPES,
} from '../utils/responsive/ResponsiveUtils';
import { rightToLeftConfig } from '../utils/animations';
import { WEIGHTS_PROPTYPES } from '../utils/templatesPropTypes';
import { WEIGHT_CONFIG_NAMES } from '../utils/getResultsCopy';
import { BLOCKING_SCREEN_TIME } from '../utils/constants';
import LottieWrapper from '../components/LottieWrapper';
import normalizeLineBreaks from '../utils/normalizeLineBreaks';

const deskFigureTransition = {
  config: {
    duration: 700,
  },
  from: {
    x: '50%',
    opacity: 0.2,
  },
  to: {
    x: '0%',
    opacity: 1,
  },
  delay: 200,
};

const mobFigureTransition = {
  config: {
    duration: 700,
  },
  from: {
    y: '-50%',
    x: '0%',
    opacity: 0.2,
  },
  to: {
    y: '0%',
    x: '0%',
    opacity: 1,
  },
  delay: 200,
};

const SliderQuestionTemplate = withResponsiveUtils(
  ({
    mobileResponsiveUtil,
    webResponsiveUtil,
    handleNextScreen,
    handleNavigationPrevScreen,
    pageData,
    isTransitioning,
    userPreviousSelection: { selectedValue },
    handleUnblockNavigation,
    isDesktop,
  }) => {
    // TODO: use ref here and move the animation with the input together
    // to gain performance. OR create a separate component as a container for
    // lotties animations and pass the ref to the state
    const value = useRef(selectedValue || 0);
    const [animationState, setAnimationState] = useState({
      status: 'idle',
      data: {},
    });

    const imageWidth = 418.8;
    const imageWidthMobile = 289.55;
    const {
      title,
      lottieAnimation,
      navConfig,
      // inputConfig: { minLabel, maxLabel },
      // weightsConfig,
      // fallbackImage,
      // fallbackImageAltText,
      options,
      position,
    } = pageData;

    const setValue = (newValue) => {
      value.current = newValue;
    };

    const updateImagePosition = (newValue) => {
      setValue(parseInt(newValue, 10));
    };

    const getSliderTypeAndWeights = () => {
      // min by default
      let matchedWeights = [...options[0].weights];
      let weightsRange = WEIGHT_CONFIG_NAMES.minWeights;
      let summary = options[0].summaryText;
      let selectedText = options[0].text;

      if (value.current > 50) {
        matchedWeights = [...options[options.length - 1].weights];
        weightsRange = WEIGHT_CONFIG_NAMES.maxWeights;
        summary = options[options.length - 1].summaryText;
        selectedText = options[options.length - 1].text;
      }

      if (options.length > 2 && value.current >= 33 && value.current <= 66) {
        matchedWeights = [...options[1].weights];
        weightsRange = WEIGHT_CONFIG_NAMES.midWeights;
        summary = options[1].summaryText;
        selectedText = options[1].text;
      }

      return [weightsRange, matchedWeights, summary, selectedText];
    };

    const handleNavNextScreen = (event) => {
      event.stopPropagation();

      const [weightsRange, weights, summary, selectedText] =
        getSliderTypeAndWeights();

      handleNextScreen({
        selectedOptions: weightsRange,
        weights,
        selectedText,
        selectedValue: value.current,
        questionPosition: position,
        summaryText: summary,
      });
    };

    const getFigureTransition = () =>
      !isDesktop ? mobFigureTransition : deskFigureTransition;

    const titleAnimation = useSpring(rightToLeftConfig);
    const figureAnimation = useSpring(getFigureTransition());
    const rangeInputAnimation = useSpring(rightToLeftConfig);

    useEffect(() => {
      const handleFetchAnimationJson = async () => {
        try {
          const { data } = await axios.get(lottieAnimation);

          setAnimationState({
            status: 'done',
            data,
          });
        } catch (error) {
          setAnimationState({
            status: 'error',
            data: {},
          });
          console.error(error);
        }
      };

      if (animationState.status === 'idle') {
        handleFetchAnimationJson();
      }
    }, [animationState.status, lottieAnimation]);

    useEffect(() => {
      const timer = setTimeout(() => {
        handleUnblockNavigation();
      }, BLOCKING_SCREEN_TIME);

      return () => clearTimeout(timer);
    }, [handleUnblockNavigation]);

    if (animationState.status === 'idle') {
      return null;
    }

    return (
      <Container
        className={isTransitioning ? 'fade' : ''}
        imageWidth={imageWidth}
        imageWidthMobile={imageWidthMobile}
      >
        <animated.h1 className="screen--title" style={titleAnimation}>
          {normalizeLineBreaks(title)}
        </animated.h1>
        <LottieWrapper
          goTo={value.current}
          style={{
            width: isDesktop
              ? webResponsiveUtil.getWidthValue(imageWidth)
              : mobileResponsiveUtil.getWidthValue(imageWidthMobile),
          }}
          updateImagePosition={updateImagePosition}
          figureAnimation={figureAnimation}
          // fallbackImage={fallbackImage}
          // fallbackImageAltText={fallbackImageAltText}
          animationState={animationState}
          rangeInputAnimation={rangeInputAnimation}
          minLabel={options[0].text}
          maxLabel={options[options.length - 1].text}
          isRangeValue
        />
        <NavFooter
          prevAction={handleNavigationPrevScreen}
          nextAction={handleNavNextScreen}
          color="white"
          showPrev
          showNext
          nextText={navConfig.nextText}
        />
      </Container>
    );
  },
);

SliderQuestionTemplate.propTypes = {
  pageData: PropTypes.shape({
    pageID: PropTypes.string,
    templateType: PropTypes.string,
    title: PropTypes.string,
    mobFigureTransition: PropTypes.shape({}),
    lottieAnimation: PropTypes.string,
    navConfig: PropTypes.shape({}),
    inputConfig: PropTypes.shape({
      minLabelP: PropTypes.string,
      maxLabel: PropTypes.string,
    }),
    weightsConfig: PropTypes.shape({
      minWeights: WEIGHTS_PROPTYPES,
      midWeights: WEIGHTS_PROPTYPES,
      maxWeights: WEIGHTS_PROPTYPES,
    }),
    fallbackImage: PropTypes.string,
    fallbackImageAltText: PropTypes.string,
  }),
  handleNextScreen: PropTypes.func,
  handleNavigationPrevScreen: PropTypes.func,
  isTransitioning: PropTypes.bool,
  userPreviousSelection: PropTypes.shape({
    selectedValue: PropTypes.number,
  }),
  handleUnblockNavigation: PropTypes.func,
  ...RESPONSIVE_UTIL_PROPTYPES,
};

SliderQuestionTemplate.defaultProps = {
  pageData: {
    mobFigureTransition: {},
    navConfig: {},
    inputConfig: {},
    weightsConfig: {
      minWeights: [],
      midWeights: [],
      maxWeights: [],
    },
  },
  handleNextScreen: () => null,
  handleNavigationPrevScreen: () => null,
  isTransitioning: false,
  userPreviousSelection: {
    selectedValue: 0,
  },
  handleUnblockNavigation: () => null,
};

const Container = withResponsiveUtils(styled.div`
  &&& {
    min-height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
    padding-left: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(18)
        : mobileResponsiveUtil.getWidthValue(18)}px;

    padding-right: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(18)
        : mobileResponsiveUtil.getWidthValue(18)}px;

    padding-top: ${({ webResponsiveUtil, mobileResponsiveUtil }) =>
      isMobile()
        ? mobileResponsiveUtil.getWidthValue(32)
        : webResponsiveUtil.getWidthValue(50)}px;

    .screen--title {
      color: #fff;
      font-size: ${({ webResponsiveUtil, mobileResponsiveUtil }) =>
        isMobile()
          ? mobileResponsiveUtil.getWidthValue(26)
          : webResponsiveUtil.getWidthValue(58)}px;
      letter-spacing: 0.5px;
      font-weight: ${fontVariables.weights.bold};
      margin: 0 auto;
      margin-bottom: ${({ mobileResponsiveUtil, webResponsiveUtil }) =>
        isMobile()
          ? mobileResponsiveUtil.getWidthValue(20)
          : webResponsiveUtil.getWidthValue(20)}px;
      max-width: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(1250)
          : mobileResponsiveUtil.getWidthValue(350)}px;
      text-align: center;
      font-family: ${fontVariables.families.primaryFont}, sans-serif;
      line-height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(60)
          : mobileResponsiveUtil.getWidthValue(35)}px;
      white-space: ${({ isDesktop }) => (isDesktop ? 'pre-wrap' : 'normal')};
    }

    .content {
      display: flex;
      flex-direction: column;
      margin: auto;
      width: 100%;
      max-width: ${({
        isDesktop,
        mobileResponsiveUtil,
        webResponsiveUtil,
        imageWidthMobile,
      }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(737)
          : mobileResponsiveUtil.getWidthValue(imageWidthMobile)}px;

      .figure {
        margin: 0 auto;
        svg {
          height: ${({ isDesktop }) =>
            isDesktop ? '100%' : 'auto !important'};
        }
      }

      .input {
        margin: 0 auto;
        margin-top: ${({ mobileResponsiveUtil, webResponsiveUtil }) =>
          isMobile()
            ? mobileResponsiveUtil.getWidthValue(10)
            : webResponsiveUtil.getWidthValue(40)}px;

        margin-bottom: ${({ mobileResponsiveUtil, webResponsiveUtil }) =>
          isMobile()
            ? mobileResponsiveUtil.getWidthValue(20)
            : webResponsiveUtil.getWidthValue(40)}px;

        width: 100%;
      }
    }

    &.fade {
      .screen--title,
      .content {
        opacity: 0;
        transition: 0.5s all ease-in;
      }
    }
  }
`);

export default SliderQuestionTemplate;
