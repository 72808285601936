import React from 'react';

const IconClose = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.522"
    height="14.522"
    viewBox="0 0 14.522 14.522"
    {...props}
  >
    <g
      id="Group_3004"
      data-name="Group 3004"
      transform="translate(-5518.403 -3036.16)"
    >
      <path
        id="Path_6272"
        data-name="Path 6272"
        d="M0,0V19.536"
        transform="translate(5532.571 3036.514) rotate(45)"
        fill="none"
        stroke="#fff"
        strokeWidth="1"
      />
      <path
        id="Path_6273"
        data-name="Path 6273"
        d="M0,19.536V0"
        transform="translate(5532.571 3050.327) rotate(135)"
        fill="none"
        stroke="#fff"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default IconClose;
