import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring } from '@react-spring/web';
import { fontVariables } from '../styles/variables';
import {
  withResponsiveUtils,
  RESPONSIVE_UTIL_PROPTYPES,
} from '../utils/responsive/ResponsiveUtils';
import NavFooter from '../components/NavFooter';
import OptionCard from '../components/OptionCard';
import { WEIGHTS_PROPTYPES } from '../utils/templatesPropTypes';
import { leftToRightConfig, rightToLeftConfig } from '../utils/animations';
import DIRECTIONS from '../utils/enums/directions';
import isEven from '../utils/isEven';
import { BLOCKING_SCREEN_TIME } from '../utils/constants';
import normalizeLineBreaks from '../utils/normalizeLineBreaks';

const Template = ({
  pageData,
  handleNextScreen,
  handleNavigationPrevScreen,
  isTransitioning,
  userPreviousSelection: { selectedOptions },
  handleUnblockNavigation,
  isDesktop,
}) => {
  const { deskTitle, mobTitle, titleColor, options, position } = pageData;
  const titleAnimation = useSpring(
    isDesktop
      ? {
          ...rightToLeftConfig,
          from: { ...rightToLeftConfig.from, x: '35%' },
        }
      : null,
  );

  const getTitle = () => {
    if (mobTitle) {
      return !isDesktop ? mobTitle : deskTitle;
    }
    return deskTitle;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleUnblockNavigation();
    }, BLOCKING_SCREEN_TIME);

    return () => clearTimeout(timer);
  }, [handleUnblockNavigation]);

  return (
    <Container
      className={isTransitioning ? 'fade' : ''}
      titleColor={titleColor}
    >
      <animated.h1 className="page__title" style={titleAnimation}>
        {normalizeLineBreaks(getTitle())}
      </animated.h1>
      <OptionsContainer className="options-container">
        {options.map(
          ({ value, weights, svgSrc, altText, text, summaryText }, index) => (
            <OptionCard
              key={value}
              active={value === selectedOptions}
              imgPosition={isEven(index) ? DIRECTIONS.RIGHT : DIRECTIONS.LEFT}
              text={normalizeLineBreaks(text)}
              value={value}
              src={svgSrc}
              altText={altText}
              style={
                !isDesktop && isEven(index)
                  ? leftToRightConfig
                  : rightToLeftConfig
              }
              onClick={() =>
                handleNextScreen({
                  selectedOptions: value,
                  weights,
                  selectedText: text,
                  summaryText,
                  questionPosition: position,
                })
              }
              special
            />
          ),
        )}
      </OptionsContainer>
      <NavFooter showPrev prevAction={handleNavigationPrevScreen} />
    </Container>
  );
};

Template.propTypes = {
  pageData: PropTypes.shape({
    pageID: PropTypes.string,
    templateType: PropTypes.string,
    deskTitle: PropTypes.string,
    mobTitle: PropTypes.string,
    titleColor: PropTypes.string,
    bgColor: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        svgSrc: PropTypes.string,
        altText: PropTypes.string,
        value: PropTypes.string,
        weights: WEIGHTS_PROPTYPES,
      }),
    ),
  }),
  handleNextScreen: PropTypes.func,
  handleNavigationPrevScreen: PropTypes.func,
  isTransitioning: PropTypes.bool,
  userPreviousSelection: PropTypes.shape({
    selectedOptions: PropTypes.string,
  }),
  handleUnblockNavigation: PropTypes.func,
  ...RESPONSIVE_UTIL_PROPTYPES,
};

Template.defaultProps = {
  pageData: {
    options: [],
  },
  handleNextScreen: () => null,
  handleNavigationPrevScreen: () => null,
  isTransitioning: false,
  userPreviousSelection: {
    selectedOptions: '',
  },
  handleUnblockNavigation: () => null,
};

const FourAnswerIllustrationQuestionTemplate = animated(
  withResponsiveUtils(Template),
);

const Container = withResponsiveUtils(styled.div`
  &&& {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    padding-top: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(50)
        : mobileResponsiveUtil.getWidthValue(32)}px;

    padding-left: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(18)
        : mobileResponsiveUtil.getWidthValue(18)}px;

    padding-right: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(18)
        : mobileResponsiveUtil.getWidthValue(18)}px;

    padding-bottom: 0;

    .page__title {
      font-family: ${fontVariables.families.primaryFont}, sans-serif;
      color: ${({ titleColor }) => titleColor || 'black'};
      font-weight: ${fontVariables.weights.bold};
      margin: 0 auto;
      max-width: ${({ isDesktop }) => (isDesktop ? 'auto' : '16ch')};
      font-size: ${({ mobileResponsiveUtil, webResponsiveUtil, isDesktop }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(58)
          : mobileResponsiveUtil.getWidthValue(30)}px;
      margin-bottom: ${({ mobileResponsiveUtil, isDesktop }) =>
        isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(10)}px;
      white-space: ${({ isDesktop }) => (isDesktop ? 'pre-wrap' : 'normal')};
      line-height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(60)
          : mobileResponsiveUtil.getWidthValue(35)}px;
    }

    &.fade {
      .page__title,
      .options-container {
        opacity: 0;
        transition: 0.5s all ease-in;
      }
    }
  }
`);

const OptionsContainer = withResponsiveUtils(styled.div`
  &&& {
    display: ${({ isDesktop }) => (isDesktop ? 'grid' : 'flex')};
    grid-template-columns: repeat(2, auto);
    grid-column-gap: ${({ webResponsiveUtil }) =>
      webResponsiveUtil.getWidthValue(150)}px;
    grid-row-gap: ${({ webResponsiveUtil }) =>
      webResponsiveUtil.getWidthValue(100)}px;

    flex-direction: column;
    max-width: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(1140)
        : mobileResponsiveUtil.getWidthValue(263)}px;

    width: 100%;
    margin: 0 auto;
    justify-content: center;

    .option-card {
      width: ${({ webResponsiveUtil, isDesktop }) =>
        isDesktop ? `${webResponsiveUtil.getWidthValue(600)}px` : '100%'};

      max-width: ${({ webResponsiveUtil, isDesktop }) =>
        isDesktop ? `${webResponsiveUtil.getWidthValue(600)}px` : '100%'};

      margin-bottom: ${({ mobileResponsiveUtil, isDesktop }) =>
        isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(15)}px;

      .text-container {
        font-family: ${fontVariables.families.primaryFont};
        z-index: 2;
        margin: 0 auto;
        min-height: ${({
          mobileResponsiveUtil,
          webResponsiveUtil,
          isDesktop,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(180)
            : mobileResponsiveUtil.getWidthValue(70)}px;
        padding: ${({ mobileResponsiveUtil, webResponsiveUtil, isDesktop }) =>
          isDesktop
            ? `${webResponsiveUtil.getWidthValue(
                10,
              )}px ${webResponsiveUtil.getWidthValue(20)}`
            : mobileResponsiveUtil.getWidthValue(10)}px;
        width: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(400)
            : mobileResponsiveUtil.getWidthValue(130)}px;
      }

      .img-container {
        z-index: 1;
      }
    }
  }
`);

export default FourAnswerIllustrationQuestionTemplate;
