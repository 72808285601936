import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { animated, useSpring } from '@react-spring/web';
import { fontVariables } from '../styles/variables';
import {
  isMobile,
  withResponsiveUtils,
  RESPONSIVE_UTIL_PROPTYPES,
} from '../utils/responsive/ResponsiveUtils';
import NavFooter from '../components/NavFooter';
import Image from '../components/Image';
import {
  bottomToTopConfig,
  leftToRightConfig,
  rightToLeftConfig,
  topToBottomConfig,
} from '../utils/animations';
import isEven from '../utils/isEven';
import { WEIGHTS_PROPTYPES } from '../utils/templatesPropTypes';
import { BLOCKING_SCREEN_TIME } from '../utils/constants';
import normalizeLineBreaks from '../utils/normalizeLineBreaks';

const Option = ({
  optionId,
  imgSrc,
  altText,
  hoverImgSrc,
  hoverAltText,
  text,
  onClick,
  selectedOption,
  style,
}) => {
  const isSelected = selectedOption === optionId;
  const [active, setActive] = useState(isSelected);

  return (
    <animated.div style={style}>
      <button
        type="button"
        id={optionId}
        className="option"
        onClick={onClick}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
      >
        {active ? (
          <Image
            className="option-image"
            src={hoverImgSrc}
            alt={hoverAltText}
          />
        ) : (
          <Image className="option-image" src={imgSrc} alt={altText} />
        )}
        <label htmlFor={optionId} className="label">
          {text}
        </label>
      </button>
    </animated.div>
  );
};

Option.propTypes = {
  onClick: PropTypes.func.isRequired,
  optionId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  selectedOption: PropTypes.string,
  style: PropTypes.shape({}).isRequired,
  imgSrc: PropTypes.string.isRequired,
  altText: PropTypes.string,
  hoverImgSrc: PropTypes.string.isRequired,
  hoverAltText: PropTypes.string,
};

Option.defaultProps = {
  selectedOption: null,
  altText: '',
  hoverAltText: '',
};

const SimpleQuestionTemplate = ({
  handleNextScreen,
  handleNavigationPrevScreen,
  pageData,
  isTransitioning,
  userPreviousSelection: { selectedOptions },
  handleUnblockNavigation,
}) => {
  const [selectedOption, setSelectedOption] = useState(selectedOptions || null);
  const isDesktop = !isMobile();
  const { title, options, position } = pageData;

  const rightToLeft = useSpring(rightToLeftConfig);
  const leftToRight = useSpring(leftToRightConfig);
  const topToBottom = useSpring(topToBottomConfig);
  const bottomToTop = useSpring(bottomToTopConfig);

  const handleNavNextScreen = (payload) => {
    setSelectedOption(payload.selectedOptions);
    handleNextScreen({ ...payload });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleUnblockNavigation();
    }, BLOCKING_SCREEN_TIME);

    return () => clearTimeout(timer);
  }, [handleUnblockNavigation]);

  return (
    <Container
      className={isTransitioning ? 'fade' : ''}
      isDesktop={isDesktop}
      titleColor={pageData.titleColor}
    >
      <animated.h1
        className="screen--title"
        style={isDesktop ? rightToLeft : topToBottom}
      >
        {normalizeLineBreaks(title)}
      </animated.h1>
      <animated.div className="options" style={isDesktop ? bottomToTop : null}>
        {options.map(
          (
            {
              value,
              text,
              weights,
              imgSrc,
              altText,
              hoverImgSrc,
              hoverAltText,
              summaryText,
            },
            index,
          ) => {
            const getStyle = (idx) => (isEven(idx) ? leftToRight : rightToLeft);
            return (
              <Option
                key={value}
                optionId={value}
                imgSrc={imgSrc}
                altText={altText}
                hoverImgSrc={hoverImgSrc}
                hoverAltText={hoverAltText}
                text={normalizeLineBreaks(text)}
                selectedOption={selectedOption}
                style={isDesktop ? {} : getStyle(index)}
                onClick={() =>
                  handleNavNextScreen({
                    selectedOptions: value,
                    weights,
                    selectedText: text,
                    summaryText,
                    questionPosition: position,
                  })
                }
              />
            );
          },
        )}
      </animated.div>
      <NavFooter
        prevAction={handleNavigationPrevScreen}
        showPrev
        color="black"
      />
    </Container>
  );
};

SimpleQuestionTemplate.propTypes = {
  pageData: PropTypes.shape({
    pageID: PropTypes.string,
    templateType: PropTypes.string,
    bgColor: PropTypes.string,
    title: PropTypes.string,
    appearInResults: PropTypes.bool,
    resultIcon: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        text: PropTypes.string,
        roomsText: PropTypes.string,
        weights: WEIGHTS_PROPTYPES,
      }),
    ),
    navConfig: PropTypes.shape({}),
  }),
  handleNextScreen: PropTypes.func,
  handleNavigationPrevScreen: PropTypes.func,
  isTransitioning: PropTypes.bool,
  userPreviousSelection: PropTypes.shape({
    selectedOptions: PropTypes.string,
  }),
  handleUnblockNavigation: PropTypes.func,
  ...RESPONSIVE_UTIL_PROPTYPES,
};

SimpleQuestionTemplate.defaultProps = {
  pageData: {
    options: {},
    navConfig: {},
  },
  handleNextScreen: () => null,
  handleNavigationPrevScreen: () => null,
  isTransitioning: false,
  userPreviousSelection: {
    selectedOptions: '',
  },
  handleUnblockNavigation: () => null,
};

export default SimpleQuestionTemplate;

const Container = withResponsiveUtils(styled.div`
  &&& {
    min-height: 100%;
    max-height: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(50)
        : mobileResponsiveUtil.getWidthValue(32)}px;

    padding-left: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(18)
        : mobileResponsiveUtil.getWidthValue(18)}px;

    padding-right: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(18)
        : mobileResponsiveUtil.getWidthValue(18)}px;

    .screen--title {
      font-family: ${fontVariables.families.primaryFont};
      color: ${({ titleColor }) => titleColor || '#000'};
      font-size: ${({ mobileResponsiveUtil, webResponsiveUtil }) =>
        isMobile()
          ? mobileResponsiveUtil.getWidthValue(26)
          : webResponsiveUtil.getWidthValue(58)}px;

      font-weight: ${fontVariables.weights.bold};
      max-width: ${({ mobileResponsiveUtil }) =>
        isMobile() ? `${mobileResponsiveUtil.getWidthValue(260)}px` : '100%'};

      margin-bottom: ${({ isDesktop, mobileResponsiveUtil }) =>
        isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(20)}px;
      line-height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(60)
          : mobileResponsiveUtil.getWidthValue(35)}px;
      white-space: ${({ isDesktop }) => (isDesktop ? 'pre-wrap' : 'normal')};
    }

    .options {
      display: flex;
      flex-direction: ${isMobile() ? 'column' : 'row'};
      width: ${({ webResponsiveUtil }) =>
        isMobile() ? '100%' : `${webResponsiveUtil.getWidthValue(1721)}px`};
      justify-content: space-between;
      align-items: ${isMobile() ? 'center' : 'flex-end'};
      margin: 0 auto;
    }

    .option {
      font-family: ${fontVariables.families.primaryFont};
      display: flex;
      flex-direction: column;
      align-items: center;
      border: none;
      background: none;
      margin-bottom: ${({ mobileResponsiveUtil, webResponsiveUtil }) =>
        isMobile()
          ? mobileResponsiveUtil.getWidthValue(20)
          : webResponsiveUtil.getWidthValue(20)}px;

      .option-image {
        cursor: pointer;
        width: ${({ webResponsiveUtil }) =>
          isMobile() ? 'auto' : `${webResponsiveUtil.getWidthValue(492)}px`};
        max-width: ${() => (isMobile() ? '100%' : 'auto')};
        height: auto;
      }
      .label {
        color: ${({ titleColor }) => titleColor || '#000'};
        white-space: pre-wrap;
        font-size: ${({ mobileResponsiveUtil, webResponsiveUtil }) =>
          isMobile()
            ? mobileResponsiveUtil.getWidthValue(18)
            : webResponsiveUtil.getWidthValue(32)}px !important;
        margin-top: ${({ mobileResponsiveUtil, webResponsiveUtil }) =>
          isMobile()
            ? mobileResponsiveUtil.getWidthValue(10)
            : webResponsiveUtil.getWidthValue(15)}px;
        line-height: ${({
          isDesktop,
          webResponsiveUtil,
          mobileResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(35)
            : mobileResponsiveUtil.getWidthValue(30)}px;
      }

      width: ${({ webResponsiveUtil, isDesktop }) =>
        isDesktop ? `${webResponsiveUtil.getWidthValue(412)}px` : '100%'};

      .option__icon {
        cursor: pointer;
        pointer-events: none;
        width: ${({ webResponsiveUtil }) =>
          isMobile() ? 'auto' : `${webResponsiveUtil.getWidthValue(492)}px`};
        max-width: ${() => (isMobile() ? '100%' : 'auto')};
        height: auto;
        margin-bottom: ${({ mobileResponsiveUtil, webResponsiveUtil }) =>
          isMobile()
            ? mobileResponsiveUtil.getWidthValue(10)
            : webResponsiveUtil.getWidthValue(15)}px;
        transition: all 0.2s;
      }

      &.selected .option__icon {
        background: #518a70;
      }

      @media (hover: hover) {
        &:hover .option__icon {
          background: #518a70;
        }
      }
    }

    &.fade {
      .screen--title,
      .options {
        opacity: 0;
        transition: 0.5s all ease-in;
      }
    }
  }
`);
