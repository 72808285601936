import React from 'react';
import PropTypes from 'prop-types';
import { animated } from '@react-spring/web';
import styled from 'styled-components';
import { fontVariables } from '../styles/variables';
import {
  withResponsiveUtils,
  isMobile,
} from '../utils/responsive/ResponsiveUtils';

const StyledRangeInput = animated(
  withResponsiveUtils(styled.div`
    &&& {
      input {
        all: unset;
        -webkit-appearance: none;
        appearance: none;
        background: linear-gradient(
          to right,
          #fff ${({ value }) => value}%,
          ${({ value }) => `rgba(255,255,255, .25) ${value}%`}
        );

        height: ${({ mobileResponsiveUtil, webResponsiveUtil }) =>
          isMobile()
            ? mobileResponsiveUtil.getWidthValue(11)
            : webResponsiveUtil.getWidthValue(20)}px;

        width: 100%;
        border-radius: 20px;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          border: none;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: #fff;
          transition: background 0.1s ease-in-out;
          cursor: grab;
          box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
        }

        &::-moz-range-thumb {
          -webkit-appearance: none;
          border: none;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: #fff;
          transition: background 0.1s ease-in-out;
          cursor: grab;
          box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
        }
      }

      .labels {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
      }

      label {
        font-size: ${({ webResponsiveUtil, mobileResponsiveUtil }) =>
          isMobile()
            ? mobileResponsiveUtil.getWidthValue(18)
            : webResponsiveUtil.getWidthValue(32)}px;
        font-weight: ${fontVariables.weights.bold};
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }
    }
  `),
);

const RangeInput = ({
  value,
  onChange,
  onMouseUp,
  onTouchEnd,
  minLabel,
  maxLabel,
  step,
  ...props
}) => (
  <StyledRangeInput value={value} {...props}>
    <input
      type="range"
      name="input"
      id=""
      value={value}
      onChange={onChange}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
      step={step}
    />
    <div className="thumb" />
    <div className="labels">
      <label htmlFor="input">{minLabel}</label>
      <label htmlFor="input">{maxLabel}</label>
    </div>
  </StyledRangeInput>
);

RangeInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onMouseUp: PropTypes.func,
  onTouchEnd: PropTypes.func,
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  step: PropTypes.number,
};

RangeInput.defaultProps = {
  value: 0,
  minLabel: '',
  maxLabel: '',
  step: 1,
  onMouseUp: () => null,
  onTouchEnd: () => null,
};

export default RangeInput;
