import React from 'react';
import PropTypes from 'prop-types';

const ArrowNext = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.011"
    height="16.769"
    viewBox="0 0 9.011 16.769"
  >
    <path
      id="Back"
      d="M0,0,7.2,7.328.009,14.648"
      transform="translate(1.061 1.061)"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

ArrowNext.propTypes = {
  color: PropTypes.string,
};

ArrowNext.defaultProps = {
  color: '#000000',
};

export default ArrowNext;
