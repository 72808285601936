import React from 'react';
import PropTypes from 'prop-types';

const ArrowBack = ({ color, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.011"
    height="16.769"
    viewBox="0 0 9.011 16.769"
    className={className}
  >
    <g
      id="Button_Back_Black"
      data-name="Button_Back Black"
      transform="translate(0.75 1.061)"
    >
      <path
        id="Back"
        d="M7.2,0,0,7.328l7.191,7.319"
        transform="translate(0)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

ArrowBack.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

ArrowBack.defaultProps = {
  color: '#000000',
  className: 'arrow-back',
};

export default ArrowBack;
