import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useTransition } from '@react-spring/web';

import fetchQuizData from './stores/viewThunks';
import getResetCSS from './utils/styles';
import Background from './components/Background';
import Loader from './components/Loader';
import { withResponsiveUtils } from './utils/responsive/ResponsiveUtils';
import GetCurrentScreen from './screens/getCurrentScreen';
import { EMBEDDED_MOBILE_APP_HEIGHT_VALUE } from './utils/constants';

const App = () => {
  const { currentPageIDX, status } = useSelector((state) => state.view);
  const dispatch = useDispatch();
  const [isNavigatingBack, setIsNavigatingBack] = useState(false);

  const transitions = useTransition(currentPageIDX, {
    from: { opacity: 0.3 },
    enter: { opacity: 1 },
    leave: { opacity: 0.3 },
    delay: 0,
    config: { duration: 0 },
  });

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchQuizData());
    }
  }, [status, dispatch]);

  return (
    <Container className="App">
      <Background isNavigatingBack={isNavigatingBack} />
      {status !== 'succeeded' ? (
        <Loader />
      ) : (
        transitions((style, item) => (
          <GetCurrentScreen
            style={style}
            item={item}
            isNavigatingBack={isNavigatingBack}
            setIsNavigatingBack={setIsNavigatingBack}
          />
        ))
      )}
    </Container>
  );
};

const Container = withResponsiveUtils(styled.div`
  font-family: 'HarmoniaSans', sans-serif;

  * {
    max-width: none;
  }

  & {
    ${getResetCSS()}
    text-align: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: ${({ isQr, isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      // eslint-disable-next-line no-nested-ternary
      isQr
        ? `${window.innerHeight}px`
        : isDesktop
        ? `${webResponsiveUtil.getWidthValue(950)}px`
        : `${mobileResponsiveUtil.getWidthValue(
            EMBEDDED_MOBILE_APP_HEIGHT_VALUE,
          )}px`};
  }
`);

export default App;
