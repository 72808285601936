import React from 'react';
import ResponsiveDimensions from 'responsive-dimensions-web/dist/responsive-dimensions-web';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { QUIZ_EXPERIENCE_ID } from '../constants';

const DESKTOP_SKETCH = {
  width: 1920,
  height: 950,
};

const getDesktopSketch = () => DESKTOP_SKETCH;

const MOBILE_SKETCH = {
  width: 375,
  height: 635,
};

const getMobileSketch = () => MOBILE_SKETCH;

export const isMobile = () => {
  const width =
    document.documentElement.clientWidth || document.body.clientWidth;
  return width <= 768;
};

const quizTagData = document.getElementById(QUIZ_EXPERIENCE_ID).dataset;

export function withResponsiveUtils(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        webResponsiveUtil: this.getWebResponsiveUtil(),
        mobileResponsiveUtil: this.getMobileResponsiveUtil(),
      };
      this.onResize = debounce(this.onResize.bind(this), 200); // bind function once
    }

    componentDidMount() {
      window.addEventListener('resize', this.onResize, false);
      this.updateUtils();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize, false);
    }

    onResize() {
      this.updateUtils();
    }

    getBaseValues = () => ({
      width: document.getElementById('irobot-embed-quiz-v2').offsetWidth,
      height: document.getElementById('irobot-embed-quiz-v2').offsetHeight,
    });

    getWebResponsiveUtil = () => ({
      getHeightValue: (value) =>
        ResponsiveDimensions.getHeightValue(
          value,
          getDesktopSketch(),
          this.getBaseValues(),
        ),
      getWidthValue: (value) =>
        ResponsiveDimensions.getWidthValue(
          value,
          getDesktopSketch(),
          this.getBaseValues(),
        ),
    });

    getMobileResponsiveUtil = () => ({
      getHeightValue: (value) =>
        ResponsiveDimensions.getHeightValue(
          value,
          getMobileSketch(),
          this.getBaseValues(),
        ),
      getWidthValue: (value) =>
        ResponsiveDimensions.getWidthValue(
          value,
          getMobileSketch(),
          this.getBaseValues(),
        ),
    });

    updateUtils = () => {
      this.setState({
        webResponsiveUtil: this.getWebResponsiveUtil(),
        mobileResponsiveUtil: this.getMobileResponsiveUtil(),
      });
    };

    render() {
      const { webResponsiveUtil, mobileResponsiveUtil } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          webResponsiveUtil={webResponsiveUtil}
          mobileResponsiveUtil={mobileResponsiveUtil}
          isDesktop={!isMobile()}
          isQr={quizTagData?.isQr}
        />
      );
    }
  };
}

export const RESPONSIVE_UTIL_PROPTYPES = {
  webResponsiveUtil: PropTypes.object,
  mobileResponsiveUtil: PropTypes.object,
  getResponsiveValue: PropTypes.func,
  isDesktop: PropTypes.bool,
  isQr: PropTypes.bool,
};

export default withResponsiveUtils;
