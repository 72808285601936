import React from 'react';
import { animated, useSpring } from '@react-spring/web';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import {
  withResponsiveUtils,
  isMobile,
  RESPONSIVE_UTIL_PROPTYPES,
} from '../../utils/responsive/ResponsiveUtils';
import { fontVariables } from '../../styles/variables';
import IconCheck from '../IconCheck';

const SubmitButton = withResponsiveUtils(
  ({
    mobileResponsiveUtil,
    webResponsiveUtil,
    onClick,
    status,
    text,
    disabled,
  }) => {
    const isDesktop = !isMobile();

    const submitBtnWidth = isDesktop
      ? webResponsiveUtil.getWidthValue(285)
      : mobileResponsiveUtil.getWidthValue(253) + 10 * 2;

    const submitBtnWidthWhenSubmited = isDesktop
      ? webResponsiveUtil.getWidthValue(52)
      : mobileResponsiveUtil.getWidthValue(52);

    const submitButtonStyle = useSpring({
      width: status ? submitBtnWidthWhenSubmited : submitBtnWidth,
    });

    return (
      <StyledSubmitButton
        type="submit"
        className={`submit ${status ? 'sent' : ''}`}
        onClick={() => {
          onClick();
        }}
        style={submitButtonStyle}
        isDesktop={isDesktop}
        disabled={disabled || status}
      >
        <IconCheck className="submit--icon" />
        <span className="submit--text">{text}</span>
      </StyledSubmitButton>
    );
  },
);

const StyledSubmitButton = withResponsiveUtils(
  animated(styled.button`
    &&& {
      font-family: HarmoniaSans;
      font-weight: ${fontVariables.weights.semibold};
      font-size: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(17)
          : mobileResponsiveUtil.getWidthValue(17)}px;

      color: #fff;
      box-sizing: border-box;
      background: transparent;
      border: 1px solid #fff;
      border-radius: 50px;
      padding: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? `0 ${webResponsiveUtil.getWidthValue(14)}`
          : `${mobileResponsiveUtil.getWidthValue(12)}px 0`}px;
      height: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(45)
          : mobileResponsiveUtil.getWidthValue(45)}px;
      .submit--icon {
        display: none;
      }

      .submit--text {
        max-width: 100px;
        overflow: hidden;
        text-align: center;
        white-space: nowrap;
        transition: max-width 1s;
        transition-delay: 1s;
      }

      &.sent {
        display: flex;
        justify-content: center;
        align-items: center;

        .submit--icon {
          position: relative;
          display: block;
        }

        .submit--text {
          max-width: 0px;
          transition: max-width 0s;
          transition-delay: 0s;
        }
      }
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        opacity: 0.75;
        &:hover {
          cursor: default;
        }
      }
    }
  `),
);

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  status: PropTypes.bool,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  ...RESPONSIVE_UTIL_PROPTYPES,
};

SubmitButton.defaultProps = {
  onClick: () => {},
  status: false,
  text: '',
  disabled: true,
};

export default SubmitButton;
