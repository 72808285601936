import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const getRightBorderRadius = ({ squareRightBorder, value }) =>
  squareRightBorder && value < 100 ? 0 : '1em';

const StyledProgressBar = styled.div`
  &&& {
    display: block;
    position: relative;
    width: 100%;
    height: 11px;
    border-radius: 1em;

    ${({ color }) => `
      border: 1px solid ${color};
    `}

    @media screen and (min-width: 1025px) {
      height: 18px;
    }

    .value {
      position: absolute;
      border-radius: inherit;
      border-top-right-radius: ${getRightBorderRadius};
      border-bottom-right-radius: ${getRightBorderRadius};
      height: inherit;
      border: inherit;
      top: -1px;

      ${({ color, value }) => `
        background-color: ${color};
        width: ${value}%;
      `}
    }
  }
`;

const ProgressBar = ({ color = '#000', value, squareRightBorder = false }) => (
  <StyledProgressBar
    className="progress-bar"
    value={value > 100 ? 100 : value} // limit to 100
    {...{ color, squareRightBorder }}
  >
    <div className="value" />
  </StyledProgressBar>
);

ProgressBar.propTypes = {
  color: PropTypes.string,
  value: PropTypes.number.isRequired,
  squareRightBorder: PropTypes.bool,
};

ProgressBar.defaultProps = {
  color: '#000000',
  squareRightBorder: false,
};

export default ProgressBar;
