import React from 'react';
import PropTypes from 'prop-types';

const LoaderIcon = ({ color, className, width, height }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
  >
    <g>
      <g transform="rotate(90 85 85)">
        <g transform="rotate(-90 85 85)">
          <g transform="rotate(-45 85 85)">
            <g>
              <path
                className="alt-color"
                fill="none"
                stroke="#61af5f"
                strokeLinecap="round"
                strokeMiterlimit="20"
                strokeWidth="6"
                d="M76.024 107.615a24.6 24.6 0 0 1-14.092-14.743"
              />
            </g>
            <g>
              <path
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeMiterlimit="20"
                strokeWidth="6"
                d="M108.48 92.295a24.597 24.597 0 0 1-14.284 15.32"
              />
            </g>
            <g>
              <path
                className="alt-color"
                fill="none"
                stroke="#61af5f"
                strokeLinecap="round"
                strokeMiterlimit="20"
                strokeWidth="6"
                d="M93.16 61.647a24.594 24.594 0 0 1 15.248 15.483"
              />
            </g>
            <g>
              <path
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeMiterlimit="20"
                strokeWidth="6"
                d="M61.82 77.106a24.593 24.593 0 0 1 15.24-15.46"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

LoaderIcon.defaultProps = {
  className: 'loader-icon',
  color: '#feffff',
  width: 170,
  height: 170,
};

LoaderIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LoaderIcon;
