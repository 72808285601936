import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring } from '@react-spring/web';
import { fontVariables } from '../styles/variables';
import {
  withResponsiveUtils,
  RESPONSIVE_UTIL_PROPTYPES,
} from '../utils/responsive/ResponsiveUtils';
import NavFooter from '../components/NavFooter';
import Image from '../components/Image';
import { ReactComponent as ThumbsUp } from '../assets/svgs/thumbsUp.svg';
import { ReactComponent as ThumbsDown } from '../assets/svgs/thumbsDown.svg';
import {
  fadingFromFarRight,
  fadingToFarLeft,
  fadingFromFarLeft,
  fadingToFarRight,
} from '../utils/animations';
import { YES_OR_NO_BLOCKING_SCREEN_TIME } from '../utils/constants';
import { WEIGHTS_PROPTYPES } from '../utils/templatesPropTypes';
import normalizeLineBreaks from '../utils/normalizeLineBreaks';

const ICONS_MAP = {
  thumbsUp: ThumbsUp,
  thumbsDown: ThumbsDown,
};

export const YesOrNoPage = ({
  pageData,
  handleNavigationPrevScreen,
  handleNextScreen,
  isNavigatingBack,
  userPreviousSelection: { selectedOptions },
  handleUnblockNavigation,
  blockNavigation,
  isDesktop,
  isQr,
}) => {
  const {
    deskTitle,
    mobTitle,
    subTitle,
    titleColor,
    bgColor,
    options,
    imgUrl,
    imgAlt,
    navConfig,
    skipWights,
    position,
  } = pageData;

  const [aniState, setAniState] = useState('in');

  const inAnimation = !isNavigatingBack
    ? fadingFromFarRight
    : fadingFromFarLeft;
  const outAnimation =
    !isNavigatingBack || aniState === 'out'
      ? fadingToFarLeft
      : fadingToFarRight;

  const cardAnimation = useSpring(
    aniState === 'in' ? inAnimation : outAnimation,
  );

  // since desk screen is bigger, the animation should take more time before the
  // next screen show up.
  const delayTime = isDesktop ? 500 : 150;

  const handleClick = ({
    optionsSelected,
    weights = [],
    selectedText,
    summaryText,
    questionPosition,
  }) => {
    if (!blockNavigation) {
      setAniState('out');

      handleNextScreen({
        weights,
        selectedOptions: optionsSelected,
        delayTime,
        selectedText,
        summaryText,
        questionPosition,
      });
    }
  };

  const handleSkipQuestion = (event) => {
    event.stopPropagation();

    handleClick({
      weights: skipWights,
      optionsSelected: 'skip',
      selectedText: 'skip',
    });
  };

  const getTitle = () => {
    if (mobTitle) {
      return !isDesktop ? mobTitle : deskTitle;
    }
    return deskTitle;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleUnblockNavigation();
    }, YES_OR_NO_BLOCKING_SCREEN_TIME);

    return () => clearTimeout(timer);
  }, [handleUnblockNavigation]);

  return (
    <Container bgColor={bgColor}>
      <h1 className="title">{normalizeLineBreaks(getTitle())}</h1>
      <animated.div
        className={`img-container${isQr ? ' qr' : ''}`}
        style={cardAnimation}
      >
        <Image className="digital-feature" src={imgUrl} alt={imgAlt} />
        <div className="sub-title__container">
          <h3 className="sub-title">{normalizeLineBreaks(subTitle)}</h3>
        </div>
      </animated.div>
      <div className="cta-container">
        {options.map(({ icon, value, altIcon, weights, summaryText }) => {
          const SvgComponent = ICONS_MAP[icon];

          return (
            <SvgComponent
              key={value}
              className={`icon${value === selectedOptions ? ' active' : ''}`}
              alt={altIcon}
              onClick={() =>
                handleClick({
                  optionsSelected: value,
                  weights,
                  selectedText: value,
                  summaryText,
                  questionPosition: position,
                })
              }
            />
          );
        })}
      </div>
      <NavFooter
        color={titleColor}
        nextAction={handleSkipQuestion}
        prevAction={handleNavigationPrevScreen}
        nextText={navConfig.nextText}
        showPrev
        showNext
      />
    </Container>
  );
};

YesOrNoPage.propTypes = {
  pageData: PropTypes.shape({
    pageID: PropTypes.string,
    templateType: PropTypes.string,
    deskTitle: PropTypes.string,
    subTitle: PropTypes.string,
    titleColor: PropTypes.string,
    mobTitle: PropTypes.string,
    appearInResults: PropTypes.bool,
    bgColor: PropTypes.string, // this is needs to be passed to the bgComponent
    imgUrl: PropTypes.string,
    imgAlt: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        value: PropTypes.string,
        altIcon: PropTypes.string,
        weights: WEIGHTS_PROPTYPES,
      }),
    ),
    navConfig: PropTypes.shape({
      showPrev: PropTypes.bool,
      showNext: PropTypes.bool,
      nextText: PropTypes.string,
    }), // TODO: move this config to the template and just left the text props in the payload for translations
    skipWights: WEIGHTS_PROPTYPES,
    isQr: PropTypes.bool,
  }),
  handleNextScreen: PropTypes.func,
  isNavigatingBack: PropTypes.bool,
  userPreviousSelection: PropTypes.shape({
    selectedOptions: PropTypes.string,
  }),
  handleUnblockNavigation: PropTypes.func,
  blockNavigation: PropTypes.bool,
  ...RESPONSIVE_UTIL_PROPTYPES,
};

YesOrNoPage.defaultProps = {
  pageData: {
    options: [],
    navConfig: {},
  },
  handleNextScreen: () => null,
  isNavigatingBack: false,
  userPreviousSelection: {
    selectedOptions: '',
  },
  handleUnblockNavigation: () => null,
  blockNavigation: true,
};

export const YesOrNoTemplate = withResponsiveUtils(YesOrNoPage);

const Container = withResponsiveUtils(
  animated(styled.div`
    &&& {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      margin-bottom: ${({
        webResponsiveUtil,
        mobileResponsiveUtil,
        isDesktop,
      }) =>
        isDesktop
          ? `${webResponsiveUtil.getWidthValue(0)}px`
          : `${mobileResponsiveUtil.getWidthValue(60)}px`};
      padding-top: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(50)
          : mobileResponsiveUtil.getWidthValue(32)}px;

      padding-left: ${({
        webResponsiveUtil,
        mobileResponsiveUtil,
        isDesktop,
      }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(18)
          : mobileResponsiveUtil.getWidthValue(18)}px;

      padding-right: ${({
        webResponsiveUtil,
        mobileResponsiveUtil,
        isDesktop,
      }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(18)
          : mobileResponsiveUtil.getWidthValue(18)}px;

      .title {
        font-family: ${fontVariables.families.primaryFont}, sans-serif;
        color: ${({ titleColor }) => titleColor || 'black'};
        font-weight: ${fontVariables.weights.bold};
        font-size: ${({ mobileResponsiveUtil, webResponsiveUtil, isDesktop }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(58)
            : mobileResponsiveUtil.getWidthValue(26)}px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: ${({
          mobileResponsiveUtil,
          webResponsiveUtil,
          isDesktop,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(40)
            : mobileResponsiveUtil.getWidthValue(40)}px;
        width: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(750)
            : mobileResponsiveUtil.getWidthValue(350)}px;
        white-space: ${({ isDesktop }) => (isDesktop ? 'pre-wrap' : 'normal')};
        line-height: ${({
          isDesktop,
          webResponsiveUtil,
          mobileResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(60)
            : mobileResponsiveUtil.getWidthValue(35)}px;
      }

      .img-container {
        box-sizing: initial;
        margin: 0 auto;
        background-color: white;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        height: ${({ mobileResponsiveUtil, webResponsiveUtil, isDesktop }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(380)
            : mobileResponsiveUtil.getWidthValue(300)}px;

        max-height: ${({
          mobileResponsiveUtil,
          webResponsiveUtil,
          isDesktop,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(380)
            : mobileResponsiveUtil.getWidthValue(300)}px;

        min-height: ${({
          mobileResponsiveUtil,
          webResponsiveUtil,
          isDesktop,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(380)
            : mobileResponsiveUtil.getWidthValue(300)}px;

        width: ${({ mobileResponsiveUtil, webResponsiveUtil, isDesktop }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(336)
            : mobileResponsiveUtil.getWidthValue(275)}px;

        padding: ${({ mobileResponsiveUtil, webResponsiveUtil, isDesktop }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(40)
            : mobileResponsiveUtil.getWidthValue(20)}px;

        .digital-feature {
          margin: 0 auto;
          margin-bottom: ${({
            mobileResponsiveUtil,
            webResponsiveUtil,
            isDesktop,
          }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(20)
              : mobileResponsiveUtil.getWidthValue(10)}px;
          height: auto;
          max-height: ${({
            mobileResponsiveUtil,
            webResponsiveUtil,
            isDesktop,
          }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(280)
              : mobileResponsiveUtil.getWidthValue(200)}px;
          width: 100%;
          max-width: ${({
            mobileResponsiveUtil,
            webResponsiveUtil,
            isDesktop,
          }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(280)
              : mobileResponsiveUtil.getWidthValue(200)}px;
        }

        box-shadow: 2px 2px 6px 3px rgb(0 0 0 / 0.1);
        margin-bottom: ${({
          mobileResponsiveUtil,
          webResponsiveUtil,
          isDesktop,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(35)
            : mobileResponsiveUtil.getWidthValue(40)}px;

        transition: all ease-in-out;
      }

      .sub-title__container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: ${({ mobileResponsiveUtil, webResponsiveUtil, isDesktop }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(15)
              : mobileResponsiveUtil.getWidthValue(15)}px
          0;

        .sub-title {
          font-family: ${fontVariables.families.primaryFont};
          color: ${({ titleColor }) => titleColor || 'black'};
          font-size: ${({
            mobileResponsiveUtil,
            webResponsiveUtil,
            isDesktop,
          }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(30)
              : mobileResponsiveUtil.getWidthValue(20)}px;
          white-space: pre-wrap;
          line-height: ${({
            isDesktop,
            webResponsiveUtil,
            mobileResponsiveUtil,
          }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(35)
              : mobileResponsiveUtil.getWidthValue(30)}px;
        }
      }

      .cta-container {
        margin-bottom: ${({
          mobileResponsiveUtil,
          webResponsiveUtil,
          isDesktop,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(30)
            : mobileResponsiveUtil.getWidthValue(21)}px;

        .icon {
          height: auto;
          cursor: pointer;
          width: ${({ mobileResponsiveUtil, webResponsiveUtil, isDesktop }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(103)
              : mobileResponsiveUtil.getWidthValue(62)}px;

          path[fill='#fff'],
          path[fill='#656666'] {
            transition: all 0.5s ease-in-out;
          }

          &.active {
            path[fill='#fff'] {
              // no
              fill: #f80038;
            }

            path[fill='#656666'] {
              fill: white;
            }
          }

          @media (hover: hover) {
            :hover {
              path[fill='#fff'] {
                // no
                fill: #f80038;
              }

              path[fill='#656666'] {
                fill: white;
              }
            }
          }

          :first-child {
            margin-right: ${({
              mobileResponsiveUtil,
              webResponsiveUtil,
              isDesktop,
            }) =>
              isDesktop
                ? webResponsiveUtil.getWidthValue(54)
                : mobileResponsiveUtil.getWidthValue(28)}px;

            &.active {
              path[fill='#fff'] {
                // yes
                fill: #6cb86a;
              }
            }

            @media (hover: hover) {
              :hover {
                path[fill='#fff'] {
                  // yes
                  fill: #6cb86a;
                }
              }
            }
          }
        }
      }
    }
  `),
);
