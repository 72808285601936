// grab the last entry from selectedAnswers
export function getCurrentSelection(selectedAnswers = []) {
  const currentAnswer = selectedAnswers[selectedAnswers.length - 1] || {};
  // selectedText is defined on ~/store/viewSlice
  return currentAnswer?.selectedText;
}
// returns all the user selections separated by pipes `|`
export function getCurrentSelections(selectedAnswers) {
  return selectedAnswers.map((ans) => ans.selectedText).join(' | ');
}

function parseProduct(product) {
  return {
    name: product?.productData?.name,
    id: product?.sku,
    category: product?.productData?.productType,
  };
}

function trackProductQuizEvent(dataLayer, selectedAnswers = []) {
  dataLayer.push({
    event: 'productQuiz',
    stepCompleted: selectedAnswers.length,
    currentSelection: getCurrentSelection(selectedAnswers),
    runningSelection: getCurrentSelections(selectedAnswers),
  });
}

function trackQuizResultsEvent({
  dataLayer = {},
  resultsProducts = [],
  eventName = 'quizResults',
  eventListName = 'Quiz: Results',
}) {
  dataLayer.push({
    event: eventName,
    ecommerce: {
      impressions: resultsProducts.map((product, idx) => ({
        ...parseProduct(product),
        position: idx + 1,
        list: eventListName,
      })),
    },
  });
}

function trackProductClickEvent(dataLayer, trackingProductData) {
  const { productData, currencyCode, trackingActionName } = trackingProductData;

  dataLayer.push({
    event: 'productClick',
    ecommerce: {
      currencyCode: currencyCode || '',
      click: {
        actionField: { list: trackingActionName || 'productQuiz' },
        products: [{ ...parseProduct(productData) }],
      },
    },
  });
}

function trackSubmitEmailEvent(dataLayer = [], sourceCta = '') {
  dataLayer.push({
    event: 'customBehavior',
    eventCategory: 'Quiz Engagement',
    eventAction: 'Submits Email',
    eventLabel: sourceCta,
  });
}

function trackQuizLoadEvent(dataLayer = [], metadata) {
  dataLayer.push({
    event: 'quizStart',
    metadata,
  });
}

export function onSelectAnswer(dataLayer = [], selectedAnswers = []) {
  trackProductQuizEvent(dataLayer, selectedAnswers);
}

export function onLoadResults(dataLayer = [], resultsProducts = []) {
  trackQuizResultsEvent({ dataLayer, resultsProducts });
}

export function onProductClick(dataLayer = [], trackingProductData = {}) {
  trackProductClickEvent(dataLayer, trackingProductData);
}

export function onOtherRecommendationsVisible(
  dataLayer = [],
  otherProducts = [],
) {
  trackQuizResultsEvent({
    dataLayer,
    resultsProducts: otherProducts,
    eventName: 'productImpressions',
    eventListName: 'Quiz: You May Also Like',
  });
}

export function onSubmitEmail(dataLayer = [], sourceCta = '') {
  try {
    trackSubmitEmailEvent(dataLayer, sourceCta);
  } catch (error) {
    console.error(error);
  }
}

export function onQuizLoad(dataLayer = [], metadata = {}) {
  trackQuizLoadEvent(dataLayer, metadata);
}
