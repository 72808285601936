import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import {
  withResponsiveUtils,
  isMobile,
  RESPONSIVE_UTIL_PROPTYPES,
} from '../../utils/responsive/ResponsiveUtils';
import { fontVariables } from '../../styles/variables';
import IconClose from '../IconClose';
import SendEmailForm from './SendEmailForm';

const SaveYourResultsPopUpMobile = ({
  onClose,
  bgColor,
  title,
  emailCaptureBtn,
  emailCapturePlaceholder,
  emailCaptureErrors,
  showEmailContactForm,
  showDropAHintBtn,
}) =>
  (showDropAHintBtn || showEmailContactForm) && (
    <StyledMobilePopup className="mobile" {...{ bgColor }}>
      <IconClose className="icon-close" onClick={onClose} />
      <div className="title">{title}</div>
      <SendEmailForm
        onFormSent={() => {}}
        inputPlaceholder={emailCapturePlaceholder}
        button={emailCaptureBtn}
        errorMessages={emailCaptureErrors}
      />
    </StyledMobilePopup>
  );

SaveYourResultsPopUpMobile.propTypes = {
  onClose: PropTypes.func.isRequired,
  bgColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  ...RESPONSIVE_UTIL_PROPTYPES,
};

const StyledMobilePopup = withResponsiveUtils(styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    background-color: ${({ bgColor }) => bgColor};
    min-width: 100%;
    box-sizing: border-box;
    padding: ${({ mobileResponsiveUtil }) =>
      `${mobileResponsiveUtil.getWidthValue(
        30,
      )}px ${mobileResponsiveUtil.getWidthValue(
        50,
      )}px ${mobileResponsiveUtil.getWidthValue(45)}px`};
    border-radius: ${({ mobileResponsiveUtil }) =>
      `${mobileResponsiveUtil.getWidthValue(
        30,
      )}px ${mobileResponsiveUtil.getWidthValue(30)}px 0px 0px`};
    box-shadow: rgb(0 0 0 / 5%) 0px -17px 15px 12px;

    .icon-close {
      position: absolute;
      top: ${({ mobileResponsiveUtil }) =>
        mobileResponsiveUtil.getWidthValue(27)}px;
      right: ${({ mobileResponsiveUtil }) =>
        mobileResponsiveUtil.getWidthValue(25)}px;
    }

    .title {
      text-transform: uppercase;
      color: #fff;
      font-weight: ${fontVariables.weights.bold};
      letter-spacing: 1.2px;
      margin-bottom: ${({ mobileResponsiveUtil }) =>
        mobileResponsiveUtil.getWidthValue(15)}px;
    }

    .input-container {
      margin: 0 auto;
      margin-bottom: ${({ mobileResponsiveUtil, webResponsiveUtil }) =>
        isMobile()
          ? mobileResponsiveUtil.getWidthValue(16)
          : webResponsiveUtil.getWidthValue(16)}px;
    }

    .submit {
      margin: 0 auto;
    }
  }
`);

export default SaveYourResultsPopUpMobile;
