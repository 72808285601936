import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import {
  withResponsiveUtils,
  RESPONSIVE_UTIL_PROPTYPES,
} from '../utils/responsive/ResponsiveUtils';
import NavFooter from '../components/NavFooter';
import Image from '../components/Image';
import { WEIGHTS_PROPTYPES } from '../utils/templatesPropTypes';
import { fontVariables } from '../styles/variables';
import { BLOCKING_SCREEN_TIME } from '../utils/constants';
import normalizeLineBreaks from '../utils/normalizeLineBreaks';

const MultipleChoiceQuestionTemplate = ({
  handleNextScreen,
  handleNavigationPrevScreen,
  pageData,
  isTransitioning,
  userPreviousSelection,
  handleUnblockNavigation,
}) => {
  const {
    title,
    titleColor,
    subTitle,
    bgColor,
    answers,
    answersConfig: { textColor, outlineColor, hoverFillColor },
    navConfig,
    imgUrl,
    imgAlt,
    position,
  } = pageData;

  const { selectedOptions: previouslySelectedOptions = [] } =
    userPreviousSelection;

  const [options, setOptions] = useState(
    answers.map((option) => ({
      ...option,
      checked: !!previouslySelectedOptions.includes(option.value),
    })),
  );

  const selectOption = (value) => {
    setOptions((currentState) =>
      currentState.map((option) => ({
        ...option,
        checked: option.value === value ? !option.checked : option.checked,
      })),
    );
  };

  const handleNavNextScreen = (event) => {
    event.stopPropagation();
    let weights = [];
    const answerNames = [];

    const selectedOptions = options
      .filter((opt) => opt.checked)
      .map((selected) => {
        // gather all the selected weights
        weights = [...weights, ...selected.weights];
        answerNames.push(selected.position);
        return selected.text;
      });
    const kids = !!answerNames.find((element) => element === 0);
    const adults = !!answerNames.find((element) => element === 1);
    const pets = !!answerNames.find((element) => element === 2);
    const shedPets = !!answerNames.find((element) => element === 3);

    let finalSummary = 'person';
    if (adults) {
      finalSummary = 'person';
    }
    if (kids && adults) {
      finalSummary = 'family member';
    }
    if (pets || (adults && pets)) {
      finalSummary = 'pet parent';
    }
    if (
      (kids && !adults) ||
      (kids && pets) ||
      (kids && adults && pets && shedPets)
    ) {
      finalSummary = 'parent';
    }
    // since text and value props are the same on this template, instead of traversing
    // the whole state to grab the texts, I just concatenate selectedOptions
    // with `,` instead of `-` due to shedding-pets has that char in it.
    const flattedSelectedOptions = selectedOptions.join(',');
    handleNextScreen({
      selectedOptions: flattedSelectedOptions,
      weights,
      selectedText: flattedSelectedOptions,
      questionPosition: position,
      summaryText: finalSummary,
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleUnblockNavigation();
    }, BLOCKING_SCREEN_TIME);

    return () => clearTimeout(timer);
  }, [handleUnblockNavigation]);

  return (
    <Container
      className={isTransitioning ? 'fade' : ''}
      bgColor={bgColor}
      titleColor={titleColor}
      textColor={textColor}
      outlineColor={outlineColor}
      hoverFillColor={hoverFillColor}
    >
      <div className="heading">
        <h1 className="screen__title">{normalizeLineBreaks(title)}</h1>
        <p className="screen__subtitle">{normalizeLineBreaks(subTitle)}</p>
      </div>

      <div className="mess-source-figure">
        <Image className="mess-source-figure__img" src={imgUrl} alt={imgAlt} />

        {options.map(({ text, value = '', checked }, index) => (
          <label
            key={value}
            className={`mess-source-figure__option option-${index} ${
              checked ? 'checked' : ''
            }`}
            htmlFor={value}
          >
            {text}
            <input
              className="mess-source-figure__input"
              id={value}
              type="checkbox"
              onClick={() => selectOption(value)}
            />
          </label>
        ))}
      </div>

      <NavFooter
        showPrev
        showNext
        color={textColor}
        nextText={navConfig.nextText}
        nextAction={handleNavNextScreen}
        prevAction={handleNavigationPrevScreen}
        disableNext={!options.filter((opt) => opt.checked).length}
      />
    </Container>
  );
};

const Container = withResponsiveUtils(styled.div`
  &&& {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow-y: auto;
    padding-top: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(50)
        : mobileResponsiveUtil.getWidthValue(32)}px;

    padding-left: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(18)
        : mobileResponsiveUtil.getWidthValue(18)}px;

    padding-right: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(18)
        : mobileResponsiveUtil.getWidthValue(18)}px;

    .screen__title,
    .screen__subtitle {
      color: ${({ titleColor }) => titleColor};
      font-weight: ${fontVariables.weights.bold};
      margin: 0 auto;
      text-align: center;
      font-family: ${fontVariables.families.primaryFont}, sans-serif;
      white-space: ${({ isDesktop }) => (isDesktop ? 'pre-wrap' : 'normal')};
    }

    .screen__title {
      font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(58)
          : mobileResponsiveUtil.getWidthValue(30)}px;

      margin-bottom: ${({
        isDesktop,
        webResponsiveUtil,
        mobileResponsiveUtil,
      }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(20)
          : mobileResponsiveUtil.getWidthValue(10)}px;

      max-width: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(950)
          : mobileResponsiveUtil.getWidthValue(350)}px;
      line-height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(60)
          : mobileResponsiveUtil.getWidthValue(35)}px;
    }

    .screen__subtitle {
      font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(28)
          : mobileResponsiveUtil.getWidthValue(16)}px;

      margin-bottom: ${({
        isDesktop,
        webResponsiveUtil,
        mobileResponsiveUtil,
      }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(30)
          : mobileResponsiveUtil.getWidthValue(30)}px;
    }

    .mess-source-figure {
      position: relative;
      padding-bottom: ${({
        isDesktop,
        mobileResponsiveUtil,
        webResponsiveUtil,
      }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(50)
          : mobileResponsiveUtil.getWidthValue(50)}px;
      max-width: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(370)
          : mobileResponsiveUtil.getWidthValue(265)}px;
      margin: 0 auto;

      .mess-source-figure__img {
        max-width: 100%;
      }

      .mess-source-figure__option {
        font-family: ${fontVariables.families.primaryFont};
        background-color: ${({ bgColor }) => bgColor || '#6a768c'};
        font-size: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(24)
            : mobileResponsiveUtil.getWidthValue(16)}px;
        border: 1.25px solid ${({ outlineColor }) => outlineColor};
        border-radius: 50px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        color: ${({ textColor }) => textColor};
        padding: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
          isDesktop
            ? `${webResponsiveUtil.getWidthValue(
                5,
              )}px ${webResponsiveUtil.getWidthValue(
                10,
              )}px ${webResponsiveUtil.getWidthValue(1)}px`
            : `${mobileResponsiveUtil.getWidthValue(
                5,
              )}px ${mobileResponsiveUtil.getWidthValue(
                10,
              )}px ${mobileResponsiveUtil.getWidthValue(2)}px`};
        font-weight: ${fontVariables.weights.semibold};
        position: absolute;
        opacity: 0.8;
        cursor: pointer;
        transition: all 0.5s;
        line-height: 1.3;
        width: max-content;

        .mess-source-figure__input {
          display: none;
        }

        &.option-2 {
          max-width: ${({ isDesktop, mobileResponsiveUtil }) =>
            isDesktop
              ? 'auto'
              : `${mobileResponsiveUtil.getWidthValue(150)}px`};
        }

        &.option-3 {
          max-width: ${({ isDesktop, mobileResponsiveUtil }) =>
            isDesktop
              ? 'auto'
              : `${mobileResponsiveUtil.getWidthValue(250)}px`};
        }
      }

      .mess-source-figure__option.checked {
        background-color: ${({ hoverFillColor }) => hoverFillColor};
        color: ${({ bgColor }) => bgColor};
        opacity: 1;
      }
    }

    /* https://css-tricks.com/solving-sticky-hover-states-with-media-hover-hover/ */
    @media (hover: hover) {
      option:hover {
        background-color: ${({ hoverFillColor }) => hoverFillColor};
        color: ${({ bgColor }) => bgColor};
        opacity: 1;
      }
    }

    .option-1 {
      left: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(220)
          : mobileResponsiveUtil.getWidthValue(150)}px;
      top: 0px;
    }

    .option-0 {
      left: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(-35)
          : mobileResponsiveUtil.getWidthValue(-25)}px;
      top: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(170)
          : mobileResponsiveUtil.getWidthValue(80)}px;
    }

    .option-2 {
      right: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(-60)
          : mobileResponsiveUtil.getWidthValue(15)}px;

      top: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(125)
          : mobileResponsiveUtil.getWidthValue(110)}px;
    }

    .option-3 {
      bottom: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(25)
          : mobileResponsiveUtil.getWidthValue(27)}px;
      right: 0;
    }

    &.fade {
      .heading,
      .mess-source-figure {
        opacity: 0;
        transition: 0.5s all ease-in;
      }
    }
  }
`);

MultipleChoiceQuestionTemplate.propTypes = {
  pageData: PropTypes.shape({
    pageID: PropTypes.string,
    templateType: PropTypes.string,
    title: PropTypes.string,
    titleColor: PropTypes.string,
    subTitle: PropTypes.string,
    bgColor: PropTypes.string,
    imgUrl: PropTypes.string,
    imgAlt: PropTypes.string,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.string,
        weights: WEIGHTS_PROPTYPES,
      }),
    ),
    answersConfig: PropTypes.shape({
      textColor: PropTypes.string,
      outlineColor: PropTypes.string,
      hoverFillColor: PropTypes.string,
    }),
    navConfig: PropTypes.shape({
      nextText: PropTypes.string,
    }),
  }),
  handleNextScreen: PropTypes.func,
  handleNavigationPrevScreen: PropTypes.func,
  isTransitioning: PropTypes.bool,
  userPreviousSelection: PropTypes.shape({}),
  handleUnblockNavigation: PropTypes.func,
  ...RESPONSIVE_UTIL_PROPTYPES,
};

MultipleChoiceQuestionTemplate.defaultProps = {
  pageData: {
    answers: [],
    answersConfig: {},
    navConfig: {},
  },
  isTransitioning: false,
  handleNextScreen: () => {},
  handleNavigationPrevScreen: () => {},
  userPreviousSelection: {
    selectedOptions: [],
  },
  handleUnblockNavigation: () => {},
};

export default MultipleChoiceQuestionTemplate;
