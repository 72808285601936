import styled from 'styled-components';
import { withResponsiveUtils } from '../../utils/responsive/ResponsiveUtils';
import { fontVariables } from '../../styles/variables';

const Button = withResponsiveUtils(styled.button`
  &&& {
    color: #fff;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;

    .text {
      font-family: HarmoniaSans;
      font-weight: ${fontVariables.weights.bold};
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      font-size: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(19)
          : mobileResponsiveUtil.getWidthValue(12)}px;

      margin-left: 15px;
    }

    svg {
      width: auto;
      height: 100%;
    }
  }
`);

export default Button;
