import React, { useState, useEffect } from 'react';
import { fetchSaveYourResults as postEmailCapture } from '../../../api';
import { QUIZ_EXPERIENCE_ID } from '../../../utils/constants';
import { trackSubmitEmailEvent } from '../../../utils/analytics';

const { quizId } = document.getElementById(QUIZ_EXPERIENCE_ID).dataset;

const parseEmailCapturePayload = (payloadToParse = {}) => ({
  quiz_partner_uuid: quizId || '',
  contact_email: payloadToParse?.contactEmail || '',
  email_collection_opt_in: payloadToParse?.optIn || false,
});

export const connect = (WrappedComponent) => (props) => {
  const [success, setSuccess] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const [loading, setLoading] = useState(false);

  const doSubmitEmailCapture = async (payload = {}) => {
    const parsedPayload = parseEmailCapturePayload(payload);

    if (!parsedPayload.quiz_partner_uuid) {
      setErrorCode('generic');
      throw Error('Missing QuizPartner uuid');
    }

    try {
      setLoading(true);
      setResetForm(false);
      const response = await postEmailCapture(parsedPayload);
      const { data } = response;
      if (data?.success) {
        setSuccess(data.success);
        trackSubmitEmailEvent('Sign up');
        setErrorCode('');
      } else {
        setSuccess(false);
        setErrorCode(data?.message);
      }
      setLoading(false);
      return data?.success;
    } catch (error) {
      const {
        response: { data },
      } = error;
      if (data?.message) {
        setErrorCode(data?.message);
      } else {
        setErrorCode('generic');
      }
      setLoading(false);
      console.error("There was an error capturing the user's email", error);
    }
    return false;
  };

  useEffect(() => {
    let timeOut = null;
    if (success) {
      timeOut = setTimeout(() => {
        setSuccess(false);
        setResetForm(true);
      }, 4500);
    }
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [success]);

  return (
    <WrappedComponent
      handleEmailCaptureSubmit={doSubmitEmailCapture}
      emailCaptureSuccess={success}
      resetFormFlag={resetForm}
      sending={loading}
      errorCode={errorCode}
      {...props}
    />
  );
};

export default connect;
