import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ProgressBar from './ProgressBar';
import ArrowNext from './ArrowNext';
import ArrowBack from './ArrowBack';
import {
  withResponsiveUtils,
  isMobile,
} from '../utils/responsive/ResponsiveUtils';
import useProgressValue from '../utils/useProgressValue';
import { fontVariables } from '../styles/variables';

const NavFooter = ({
  color,
  prevText,
  nextText,
  prevAction,
  nextAction,
  showPrev,
  showNext,
  showProgress,
  disableNext,
}) => {
  const progressValue = useProgressValue();
  const isDesktop = !isMobile();
  return (
    <StyledNavFooter
      className="nav-footer"
      {...{ color, isDesktop, disableNext }}
    >
      {showPrev ? (
        <button type="button" className="prev-btn" onClick={prevAction}>
          <ArrowBack className="arrow-back" color={color} />
          {prevText ? <label htmlFor="prev">{prevText}</label> : null}
        </button>
      ) : (
        <div className="button--empty" />
      )}

      {showProgress ? (
        <ProgressBar
          max="100"
          value={progressValue}
          color={color}
          squareRightBorder
        />
      ) : (
        <div className="progress--empty" />
      )}

      {showNext ? (
        <button
          type="button"
          className="next-btn"
          onClick={!disableNext ? nextAction : () => null}
        >
          {nextText ? <label htmlFor="next">{nextText}</label> : null}
          <ArrowNext className="arrow-next" color={color} />
        </button>
      ) : (
        <div className="button--empty" />
      )}
    </StyledNavFooter>
  );
};

NavFooter.propTypes = {
  color: PropTypes.string,
  prevText: PropTypes.string,
  nextText: PropTypes.string,
  prevAction: PropTypes.func,
  nextAction: PropTypes.func,
  showPrev: PropTypes.bool,
  showNext: PropTypes.bool,
  showProgress: PropTypes.bool,
  disableNext: PropTypes.bool,
};

NavFooter.defaultProps = {
  color: '#000',
  prevText: '',
  nextText: '',
  prevAction: () => null,
  nextAction: () => null,
  showPrev: false,
  showNext: false,
  showProgress: true,
  disableNext: false,
};

export const StyledNavFooter = withResponsiveUtils(styled.div`
  &&& {
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-row-start: 3;
    align-self: end;
    max-width: ${({ mobileResponsiveUtil, webResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(1500)
        : mobileResponsiveUtil.getWidthValue(375)}px;

    margin: 0 auto;
    margin-bottom: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(50)
        : mobileResponsiveUtil.getWidthValue(30)}px;

    button {
      display: flex;
      align-items: center;
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      flex: 1;

      label {
        cursor: inherit;
        font-size: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(36)
            : mobileResponsiveUtil.getWidthValue(16)}px;
        font-weight: ${fontVariables.weights.bold};
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: ${({ color }) => color};
        margin: 0
          ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(15)
              : mobileResponsiveUtil.getWidthValue(10)}px;
        position: relative;
        white-space: nowrap;
      }

      svg {
        width: auto;
        height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(34)
            : mobileResponsiveUtil.getWidthValue(15)}px;
      }
    }

    .button--empty {
      flex: 1;
    }

    .next-btn {
      justify-content: flex-end;
      cursor: ${({ disableNext }) => (disableNext ? 'not-allowed' : 'pointer')};
      opacity: ${({ disableNext }) => (disableNext ? 0.3 : 1)};
    }

    .progress-bar {
      background-color: transparent;
      height: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(18)
          : mobileResponsiveUtil.getWidthValue(11)}px;

      max-width: ${({ isDesktop }) => (isDesktop ? '50%' : 'auto')};
      width: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(536)
          : mobileResponsiveUtil.getWidthValue(170)}px;
    }
  }
`);

const MemoizedNavFooter = React.memo(NavFooter);

export default MemoizedNavFooter;
