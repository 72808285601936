import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';
import { animated } from '@react-spring/web';
import styled from 'styled-components';
import { withResponsiveUtils } from '../utils/responsive/ResponsiveUtils';
import RangeInput from './RangeInput';
import DIRECTIONS from '../utils/enums/directions';

const LottieWrapper = ({
  goTo,
  style,
  updateImagePosition,
  figureAnimation,
  fallbackImage,
  fallbackImageAltText,
  animationState,
  isRangeValue,
  rangeInputAnimation,
  minLabel,
  maxLabel,
}) => {
  const [value, setValue] = useState(goTo || 0);

  // when user releases the slider input, it sets back to 0 or 100,
  // depending if value is less or more than 50
  const roundResult = (newValue, updateStateValue) => {
    const minValue = 0;
    const maxValue = 100;
    const direction = newValue >= 50 ? DIRECTIONS.RIGHT : DIRECTIONS.LEFT;
    const final = newValue >= 50 ? maxValue : minValue;
    let count = Math.abs(final - newValue);

    const updateValue = (valueToUpdate) => {
      setTimeout(() => {
        updateStateValue(valueToUpdate);
      }, 50);
    };

    while (count >= 0) {
      const calculatedValue =
        direction === DIRECTIONS.RIGHT ? final - count : final + count;
      updateValue(calculatedValue);
      count -= 1;
    }
  };

  const onSlide = (newValue) => {
    setValue(newValue);
    updateImagePosition(newValue);
  };

  const onMouseOrTouchEnd = isRangeValue
    ? null
    : ({ target }) => {
        roundResult(target?.value, onSlide);
      };

  return (
    <div className="content">
      <animated.div style={figureAnimation} className="figure" position={goTo}>
        {animationState.status !== 'error' ? (
          <Lottie
            goTo={value / 1}
            animationData={animationState.data}
            style={style}
          />
        ) : (
          <FallbackImage src={fallbackImage} alt={fallbackImageAltText} />
        )}
      </animated.div>
      <RangeInput
        className="input"
        onChange={({ target }) => onSlide(target?.value)}
        onTouchEnd={onMouseOrTouchEnd}
        onMouseUp={onMouseOrTouchEnd}
        style={rangeInputAnimation}
        {...{ value: Number(value), minLabel, maxLabel }}
      />
    </div>
  );
};

LottieWrapper.propTypes = {
  goTo: PropTypes.number.isRequired,
  animationData: PropTypes.shape({}),
  style: PropTypes.shape({}),
  figureAnimation: PropTypes.shape({}),
  fallbackImage: PropTypes.string,
  fallbackImageAltText: PropTypes.string,
  animationState: PropTypes.shape({
    status: PropTypes.string,
    data: PropTypes.shape({}),
  }),
  updateImagePosition: PropTypes.func.isRequired,
  isRangeValue: PropTypes.bool.isRequired,
  rangeInputAnimation: PropTypes.shape({}).isRequired,
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
};

LottieWrapper.defaultProps = {
  animationData: {},
  style: {},
  figureAnimation: {},
  fallbackImage: '',
  fallbackImageAltText: '',
  animationState: {},
  minLabel: '',
  maxLabel: '',
};

const FallbackImage = withResponsiveUtils(styled.img`
  &&& {
    max-height: ${({ isDesktop, webResponsiveUtil }) =>
      isDesktop ? `${webResponsiveUtil.getWidthValue(450)}px` : 'auto'};

    max-width: ${({ isDesktop }) => (isDesktop ? 'auto' : '100%')};
  }
`);

export default LottieWrapper;
