import React from 'react';

const IconHeartLetter = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32.428"
    height="24.001"
    viewBox="0 0 32.428 24.001"
  >
    <g id="Group_2967" data-name="Group 2967" transform="translate(1 1)">
      <rect
        id="Rectangle_223"
        data-name="Rectangle 223"
        width="30.428"
        height="22.001"
        rx="2.906"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        id="Path_6275"
        data-name="Path 6275"
        d="M-1720.93,1872.968l14.114,10.469a1.853,1.853,0,0,0,2.2,0l14.115-10.469"
        transform="translate(1720.93 -1870.884)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        id="Path_6276"
        data-name="Path 6276"
        d="M-1703.2,1890.254a22.176,22.176,0,0,0,5.29-5.641c2.146-3.5-1.255-4.991-1.255-4.991-1.917-.878-4.035,1.448-4.035,1.448s-2.118-2.326-4.035-1.448c0,0-3.4,1.488-1.255,4.991A22.178,22.178,0,0,0-1703.2,1890.254Z"
        transform="translate(1718.414 -1871.924)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default IconHeartLetter;
