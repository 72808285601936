import axios from 'axios';
import { QUIZ_EXPERIENCE_ID } from '../utils/constants';

export const fetchPageData = (baseUrl, UUID) => {
  // const apiUrl = `${baseUrl}/external/static_resources/${UUID}?resource_type=quiz`;
  const apiUrl = `${baseUrl}/external/quiz_partners/${UUID}`;

  return axios.get(apiUrl);
};

export const fetchLocalPageData = () => {
  const apiUrl = './mockedResponse.json';

  return axios.get(apiUrl);
};

export const fetchSaveYourResults = (data) => {
  const { apiUrl } = document.getElementById(QUIZ_EXPERIENCE_ID).dataset;

  return axios.post(`${apiUrl}/quiz_experience/contact_form`, data);
};

export const fetchResultsCopy = (copy = '') => {
  const { apiUrl, quizId } =
    document.getElementById(QUIZ_EXPERIENCE_ID).dataset;

  return axios.post(`${apiUrl}/quiz_experience/results_copy_translate`, {
    quiz_partner_uuid: quizId,
    results_copy: copy,
  });
};
