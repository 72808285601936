import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withResponsiveUtils } from '../../utils/responsive/ResponsiveUtils';
import { fontVariables } from '../../styles/variables';
import IconLetter from '../IconLetter';
import Button from './Button';
import FormArea from './FormArea';
import DropAHintBtn from './DropAHintBtn';

const DesktopSaveYourResults = ({
  showForm,
  setShowForm,
  emailCaptureTitle,
  bgColor,
  saveYourResultsLinkText,
  dropAHintLinkText,
  handleDropAHintClick,
  showDropAHint,
  showEmailContactForm,
  emailCapturePlaceholder,
  emailCaptureErrors,
  emailCaptureBtn,
}) =>
  (showDropAHint || showEmailContactForm) && (
    <StyledDesktopSaveYourResults bgColor={bgColor}>
      <ButtonsContainer showForm={showForm}>
        {showEmailContactForm && (
          <>
            <StyledButton
              type="button"
              className={` save-results-btn ${showForm ? 'clicked' : ''}`}
              onClick={() => {
                setShowForm(true);
              }}
            >
              <IconLetter color={showForm ? '#DCE9D5' : '#fff'} />{' '}
              <Label>{saveYourResultsLinkText}</Label>
            </StyledButton>
            <DesktopFormContainer>
              <FormArea
                title={emailCaptureTitle}
                onClose={() => null}
                showForm={showForm}
                emailCaptureBtn={emailCaptureBtn}
                emailCapturePlaceholder={emailCapturePlaceholder}
                emailCaptureErrors={emailCaptureErrors}
              />
            </DesktopFormContainer>
          </>
        )}
        {showDropAHint && (
          <DropAHintBtn
            text={dropAHintLinkText}
            handleClick={handleDropAHintClick}
          />
        )}
      </ButtonsContainer>
    </StyledDesktopSaveYourResults>
  );

DesktopSaveYourResults.propTypes = {
  showForm: PropTypes.bool,
  setShowForm: PropTypes.func,
  emailCaptureTitle: PropTypes.string,
  bgColor: PropTypes.string,
  saveYourResultsLinkText: PropTypes.string,
  dropAHintLinkText: PropTypes.string,
  handleDropAHintClick: PropTypes.func,
  showEmailContactForm: PropTypes.bool,
  showDropAHint: PropTypes.bool,
  emailCapturePlaceholder: PropTypes.string,
  emailCaptureErrors: PropTypes.shape(),
  emailCaptureBtn: PropTypes.shape({
    submit: PropTypes.string,
    sending: PropTypes.string,
  }),
};

DesktopSaveYourResults.defaultProps = {
  showForm: false,
  setShowForm: () => {},
  emailCaptureTitle: '',
  bgColor: '',
  saveYourResultsLinkText: '',
  dropAHintLinkText: '',
  handleDropAHintClick: () => {},
  showEmailContactForm: false,
  showDropAHint: false,
  emailCapturePlaceholder: '',
  emailCaptureErrors: {},
  emailCaptureBtn: {},
};

const StyledDesktopSaveYourResults = withResponsiveUtils(styled.div`
  &&& {
    background-color: ${({ bgColor }) => bgColor};
    padding: ${({ webResponsiveUtil }) =>
      `${webResponsiveUtil.getWidthValue(45)}px 0px`};
  }
`);

const ButtonsContainer = withResponsiveUtils(styled.div`
  &&& {
    display: flex;
    justify-content: center;
    height: ${({ showForm, webResponsiveUtil }) =>
      showForm ? 'auto' : `${webResponsiveUtil.getWidthValue(60)}px`};
  }
`);

const DesktopFormContainer = withResponsiveUtils(styled.div`
  .email-capture-form__wrapper {
    display: flex;
    align-items: center;
    padding: 0;
    width: ${({ webResponsiveUtil }) => webResponsiveUtil.getWidthValue(750)}px;

    .email-capture__input-container,
    .email-capture__input {
      width: ${({ webResponsiveUtil }) =>
        webResponsiveUtil.getWidthValue(337)}px;
    }

    .email-capture__input-container {
      margin: 0
        ${({ webResponsiveUtil }) => webResponsiveUtil.getWidthValue(20)}px;
    }

    .submit {
      font-size: ${({ webResponsiveUtil }) =>
        webResponsiveUtil.getWidthValue(17)}px;
      font-weight: ${fontVariables.weights.semibold};

      width: ${({ webResponsiveUtil }) =>
        webResponsiveUtil.getWidthValue(285)}px;
    }

    .divisor {
      display: block;
      height: ${({ webResponsiveUtil }) =>
        webResponsiveUtil.getWidthValue(48)}px;

      width: 2px;
      background: #fff;
      margin: 0
        ${({ webResponsiveUtil }) => webResponsiveUtil.getWidthValue(60)}px;
    }
  }

  .email-capture-form__wrapper.sent {
    .submit {
      padding: ${({ webResponsiveUtil }) =>
        webResponsiveUtil.getWidthValue(15)}px;

      .submit--icon {
        top: ${({ webResponsiveUtil }) =>
          webResponsiveUtil.getWidthValue(-1)}px;

        height: ${({ webResponsiveUtil }) =>
          webResponsiveUtil.getWidthValue(18)}px;
      }
    }
  }
`);

const Label = withResponsiveUtils(styled.span`
  &&& {
    display: block;
    font-size: ${({ webResponsiveUtil }) =>
      webResponsiveUtil.getWidthValue(19)}px;
    line-height: normal;
    min-width: ${({ webResponsiveUtil }) =>
      `${webResponsiveUtil.getWidthValue(190)}px`};
    max-width: ${({ webResponsiveUtil }) =>
      webResponsiveUtil.getWidthValue(190)}px;
    font-family: ${fontVariables.families.primaryFont};
    font-weight: ${fontVariables.weights.bold};
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    margin-left: ${({ webResponsiveUtil }) =>
      webResponsiveUtil.getWidthValue(15)}px;
    box-sizing: border-box;
  }
`);

const StyledButton = withResponsiveUtils(styled(Button)`
  &&& {
    svg {
      height: 100%;
      width: ${({ webResponsiveUtil }) =>
        `${webResponsiveUtil.getWidthValue(70)}px`};
    }
  }
`);

export default DesktopSaveYourResults;
