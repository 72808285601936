import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { animated } from '@react-spring/web';
import { withResponsiveUtils } from '../utils/responsive/ResponsiveUtils';
import { ReactComponent as GiftSVG } from '../assets/svgs/gift.svg';
import IconClose from './IconClose';
import { fontVariables } from '../styles/variables';
import { EMBEDDED_MOBILE_APP_HEIGHT_VALUE } from '../utils/constants';
import InputField from './SaveYourResults/InputField';

const replaceTmplPlaceholders = (template = '', recipientName, yourName) => {
  let parsedText = '';
  const span = (text) =>
    text ? `<span class="hello-text__name">${text}</span>` : '_____';
  try {
    parsedText = template
      .split('[[recipient_name]]')
      .join(span(recipientName))
      .split('[[your_name]]')
      .join(span(yourName));
  } catch (error) {
    console.error('Message template parsing failed');
  }

  return parsedText;
};

const DropAHint = ({ onClose, dropAHintData }) => {
  const [recipientsName, setRecipientsName] = useState('');
  const [recipientsEmail, setRecipientsEmail] = useState('');
  const [yourName, setYourName] = useState('');
  const [yourEmail, setYourEmail] = useState('');
  const {
    title,
    recipientNamePlaceholder,
    recipientEmailPlaceholder,
    yourNamePlaceholder,
    yourEmailPlaceholder,
    helloText,
    helloCaption,
    button: { submit },
  } = dropAHintData;

  return (
    <Container>
      <ClosePageButton type="button" onClick={onClose}>
        <IconClose className="icon-close" />
      </ClosePageButton>
      <GiftSVG className="gift-icon" />
      <Title>{title}</Title>
      <DropAHintForm>
        <InputField
          value={recipientsName}
          placeholder={recipientNamePlaceholder}
          onChange={setRecipientsName}
          isValid
          disabled={false}
        />
        <InputField
          value={recipientsEmail}
          type="email"
          placeholder={recipientEmailPlaceholder}
          onChange={setRecipientsEmail}
          isValid
          disabled={false}
        />
        <InputField
          value={yourName}
          placeholder={yourNamePlaceholder}
          onChange={setYourName}
          isValid
          disabled={false}
        />
        <InputField
          value={yourEmail}
          type="email"
          placeholder={yourEmailPlaceholder}
          onChange={setYourEmail}
          isValid
          disabled={false}
        />
        <HelloContainer>
          <HelloText
            dangerouslySetInnerHTML={{
              __html: replaceTmplPlaceholders(
                helloText,
                recipientsName,
                yourName,
              ),
            }}
          />
          <HelloCaption>{helloCaption}</HelloCaption>
        </HelloContainer>
        <SendButton type="submit">{submit}</SendButton>
      </DropAHintForm>
    </Container>
  );
};

const DROP_A_HINT_PROPTYPES = {
  resultsShowShareWithFriends: PropTypes.bool,
  title: PropTypes.string,
  recipientNamePlaceholder: PropTypes.string,
  recipientEmailPlaceholder: PropTypes.string,
  yourNamePlaceholder: PropTypes.string,
  yourEmailPlaceholder: PropTypes.string,
  helloText: PropTypes.string,
  helloCaption: PropTypes.string,
  button: PropTypes.shape({
    submit: PropTypes.string,
    sending: PropTypes.string,
  }),
  errors: PropTypes.shape({
    empty: PropTypes.string,
    invalidEmail: PropTypes.string,
    generic: PropTypes.string,
  }),
};

DropAHint.propTypes = {
  onClose: PropTypes.func.isRequired,
  dropAHintData: PropTypes.shape(DROP_A_HINT_PROPTYPES),
};

DropAHint.defaultProps = {
  dropAHintData: {
    resultsShowShareWithFriends: false,
    title: '',
    recipientNamePlaceholder: '',
    recipientEmailPlaceholder: '',
    yourNamePlaceholder: '',
    yourEmailPlaceholder: '',
    helloText: '',
    helloCaption: '',
    button: {
      submit: '',
      sending: '',
    },
    errors: {
      empty: '',
      invalidEmail: '',
      generic: '',
    },
  },
};

const ClosePageButton = withResponsiveUtils(styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
    isDesktop
      ? `${webResponsiveUtil.getWidthValue(85)}px`
      : `${mobileResponsiveUtil.getWidthValue(35)}px`};
  right: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
    isDesktop
      ? `${webResponsiveUtil.getWidthValue(100)}px`
      : `${mobileResponsiveUtil.getWidthValue(30)}px`};

  .icon-close {
    height: ${({ isDesktop, webResponsiveUtil }) =>
      isDesktop ? `${webResponsiveUtil.getWidthValue(30)}px` : 'auto'};
    width: ${({ isDesktop, webResponsiveUtil }) =>
      isDesktop ? `${webResponsiveUtil.getWidthValue(30)}px` : 'auto'};
  }
`);

const Title = withResponsiveUtils(styled.h1`
  &&& {
    font-family: ${fontVariables.families.primaryFont};
    color: #fff;
    font-size: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      isDesktop
        ? `${webResponsiveUtil.getWidthValue(58)}px`
        : `${mobileResponsiveUtil.getWidthValue(26)}px`};
    font-weight: ${fontVariables.weights.bold};
    margin-bottom: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      isDesktop
        ? `${webResponsiveUtil.getWidthValue(50)}px`
        : `${mobileResponsiveUtil.getWidthValue(25)}px`};
  }
`);

const DropAHintForm = withResponsiveUtils(styled.form`
  &&& {
    display: ${({ isDesktop }) => (isDesktop ? 'grid' : 'flex')};
    flex-direction: column;
    margin: 0 auto;
    max-width: ${({ isDesktop, webResponsiveUtil }) =>
      isDesktop ? `${webResponsiveUtil.getWidthValue(948)}px` : 'auto'};

    grid-template-columns: ${({ isDesktop }) =>
      isDesktop ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'};
    grid-column-gap: ${({ isDesktop, webResponsiveUtil }) =>
      isDesktop ? `${webResponsiveUtil.getWidthValue(40)}px` : '0px'};
    grid-row-gap: ${({ isDesktop, webResponsiveUtil }) =>
      isDesktop ? `${webResponsiveUtil.getWidthValue(20)}px` : '0px'};

    .input-field__input {
      font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? `${webResponsiveUtil.getWidthValue(18)}px`
          : `${mobileResponsiveUtil.getWidthValue(13)}px`};
      border-radius: 5px;
      height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? `${webResponsiveUtil.getWidthValue(66)}px`
          : `${mobileResponsiveUtil.getWidthValue(40)}px`};
    }
  }
`);

const HelloContainer = styled.figure`
  grid-column: ${({ isDesktop }) => (isDesktop ? `1 / 3` : `1 / -1`)};
`;

const HelloText = withResponsiveUtils(styled.p`
  &&& {
    font-family: ${fontVariables.families.primaryFont};
    font-size: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      isDesktop
        ? `${webResponsiveUtil.getWidthValue(41)}px`
        : `${mobileResponsiveUtil.getWidthValue(22)}px`};
    font-weight: ${fontVariables.weights.light};
    color: #fff;
    line-height: 1.5;
    margin: 0 auto;
    margin-top: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      isDesktop
        ? `${webResponsiveUtil.getWidthValue(50)}px`
        : `${mobileResponsiveUtil.getWidthValue(25)}px`};
    margin-bottom: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      isDesktop
        ? `${webResponsiveUtil.getWidthValue(30)}px`
        : `${mobileResponsiveUtil.getWidthValue(30)}px`};

    max-width: ${({ isDesktop }) => (isDesktop ? '80%' : '100%')};

    .hello-text__name {
      font-weight: ${fontVariables.weights.bold};
    }
  }
`);

const HelloCaption = withResponsiveUtils(styled.figcaption`
  &&& {
    font-family: ${fontVariables.families.primaryFont};
    font-size: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      isDesktop
        ? `${webResponsiveUtil.getWidthValue(22)}px`
        : `${mobileResponsiveUtil.getWidthValue(14)}px`};
    font-weight: ${fontVariables.weights.semibold};
    color: #fff;
  }
`);

const SendButton = withResponsiveUtils(styled.button`
  &&& {
    font-family: ${fontVariables.families.primaryFont};
    font-size: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      isDesktop
        ? `${webResponsiveUtil.getWidthValue(28)}px`
        : `${mobileResponsiveUtil.getWidthValue(17)}px`};
    font-weight: ${fontVariables.weights.semibold};
    color: #fff;
    background-color: #518a70;
    border: 1px solid #fff;
    border-radius: 100px;
    max-width: 100%;
    height: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      isDesktop
        ? `${webResponsiveUtil.getWidthValue(76)}px`
        : `${mobileResponsiveUtil.getWidthValue(45)}px`};
    grid-column: 1 / 3;
    padding: 0
      ${({ isDesktop, webResponsiveUtil }) =>
        isDesktop ? `${webResponsiveUtil.getWidthValue(140)}px` : '0'};
    margin: ${({ isDesktop }) => (isDesktop ? '0 auto' : 'unset')};
    margin-top: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      isDesktop
        ? `${webResponsiveUtil.getWidthValue(50)}px`
        : `${mobileResponsiveUtil.getWidthValue(25)}px`};
    cursor: pointer;
  }
`);

const Container = withResponsiveUtils(
  animated(styled.div`
    &&& {
      position: absolute;
      top: 0;
      left: 0;
      overflow-y: auto;
      height: ${({
        isQr,
        isDesktop,
        mobileResponsiveUtil,
        webResponsiveUtil,
      }) =>
        // eslint-disable-next-line no-nested-ternary
        isQr
          ? '100vh'
          : isDesktop
          ? `${webResponsiveUtil.getWidthValue(950)}px`
          : `${mobileResponsiveUtil.getWidthValue(
              EMBEDDED_MOBILE_APP_HEIGHT_VALUE,
            )}px`};
      width: 100%;
      box-sizing: border-box;
      background-color: #518a70;
      z-index: 5;
      padding: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? `${webResponsiveUtil.getWidthValue(50)}px`
          : `${mobileResponsiveUtil.getWidthValue(
              25,
            )}px ${mobileResponsiveUtil.getWidthValue(50)}px`};

      .gift-icon {
        margin-bottom: ${({
          isDesktop,
          mobileResponsiveUtil,
          webResponsiveUtil,
        }) =>
          isDesktop
            ? `${webResponsiveUtil.getWidthValue(50)}px`
            : `${mobileResponsiveUtil.getWidthValue(30)}px`};

        height: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
          isDesktop
            ? `${webResponsiveUtil.getWidthValue(150)}px`
            : `${mobileResponsiveUtil.getWidthValue(65)}px`};
        width: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
          isDesktop
            ? `${webResponsiveUtil.getWidthValue(165)}px`
            : `${mobileResponsiveUtil.getWidthValue(80)}px`};
      }

      .input-field__input {
        font-size: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
          isDesktop
            ? `${webResponsiveUtil.getWidthValue(18)}px`
            : `${mobileResponsiveUtil.getWidthValue(13)}px`};
        height: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
          isDesktop
            ? `${webResponsiveUtil.getWidthValue(66)}px`
            : `${mobileResponsiveUtil.getWidthValue(40)}px`};

        &::placeholder {
          opacity: 1;
        }
      }
    }
  `),
);

export default DropAHint;
