import * as ga from './ga';
import * as pixel from './pixel';

let selectedAnswers = [];

export function trackSelectedAnswer(currentAnswer = {}) {
  selectedAnswers = [...selectedAnswers, currentAnswer];
  ga.onSelectAnswer(window.dataLayer, selectedAnswers);
  pixel.onSelectAnswer(window.genomeTP, currentAnswer, selectedAnswers);
}

// pops from the n element of the selectedAnswers array
export function trackBacktracking(steps = 1) {
  // ex: array.length = 5 [0..4]
  // nIDX = 5 - 1 =  4
  // mutate the array and delete from the nIDX 4 n amounts of steps
  const nIDX = selectedAnswers.length - steps;

  selectedAnswers.splice(nIDX, steps);
}

export function trackQuizResults(resultsProducts) {
  ga.onLoadResults(window.dataLayer, resultsProducts);
  pixel.onLoadResults(window.genomeTP, resultsProducts, selectedAnswers);
}

export function trackShopLinkClick(trackingProductData) {
  ga.onProductClick(window.dataLayer, trackingProductData);
  pixel.onClickProduct(window.genomeTP, trackingProductData);
}

export function trackOtherRecommendationsImpressions(otherProductsData) {
  ga.onOtherRecommendationsVisible(window.dataLayer, otherProductsData);
}

export function trackSubmitEmailEvent(sourceCta = '') {
  ga.onSubmitEmail(window.dataLayer, sourceCta);
  pixel.onEmailSubmit(window.genomeTP, sourceCta);
}

export function trackQuizLoad(metadata) {
  ga.onQuizLoad(window.dataLayer, metadata);
}
