export const fontVariables = {
  families: {
    primaryFont: 'HarmoniaSansPro-Regular',
    secondaryFont: 'HarmoniaSans-Condensed',
  },
  weights: {
    light: 300,
    normal: 400,
    semibold: 600,
    bold: 700,
    black: 900,
  },
};

export const colorVariables = {
  white: '#FFFFFF',
  green1: '#518A70', // primary green color
  green2: '#447355', // secondary dark green
};

export default { fontVariables, colorVariables };
