import React from 'react';

import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import {
  withResponsiveUtils,
  isMobile,
} from '../../utils/responsive/ResponsiveUtils';
import IconHeartLetter from '../IconHeartLetter';
import Button from './Button';

const DropAHintBtn = ({ text, handleClick }) => (
  <StyledButton className="btn drop-a-hint" onClick={handleClick}>
    <IconHeartLetter />
    <span className="text">{text}</span>
  </StyledButton>
);

DropAHintBtn.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func,
};

DropAHintBtn.defaultProps = {
  text: '',
  handleClick: () => {},
};

const StyledButton = withResponsiveUtils(styled(Button)`
  &&& {
    svg {
      height: 100%;
    }

    .text {
      font-size: ${({ mobileResponsiveUtil, webResponsiveUtil }) =>
        isMobile()
          ? mobileResponsiveUtil.getWidthValue(12)
          : webResponsiveUtil.getWidthValue(19)}px;

      line-height: 1.2;
      max-width: 7ch;
    }
  }
`);

export default DropAHintBtn;
