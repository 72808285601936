import React, { useCallback, useState } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import {
  withResponsiveUtils,
  isMobile,
} from '../../utils/responsive/ResponsiveUtils';
import DesktopPopUp from './SaveYourResultsPopupDesktop';
import MobilePopUp from './SaveYourResultsPopupMobile';

const SaveResultsPopUp = ({
  onClose,
  showDropAHintBtn,
  showEmailContactForm,
  emailCaptureTitle,
  bgColor,
  dropAHintBtnLabel,
  emailCapturePlaceholder,
  emailCaptureErrors,
  emailCaptureBtn,
}) => {
  const [popupHeight, setPopupHeight] = useState(0);
  const isDesktop = !isMobile();

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setPopupHeight(node.getBoundingClientRect().height);
    }
  }, []);

  return (
    <Container height={popupHeight} className="pop-up" isDesktop={isDesktop}>
      <div ref={measuredRef}>
        {isDesktop ? (
          <DesktopPopUp
            onClose={onClose}
            bgColor={bgColor}
            showDropAHintBtn={showDropAHintBtn}
            showEmailContactForm={showEmailContactForm}
            dropAHintLinkText={dropAHintBtnLabel}
            title={emailCaptureTitle}
            emailCaptureBtn={emailCaptureBtn}
            emailCaptureErrors={emailCaptureErrors}
            emailCapturePlaceholder={emailCapturePlaceholder}
          />
        ) : (
          <MobilePopUp
            onClose={onClose}
            bgColor={bgColor}
            showDropAHintBtn={showDropAHintBtn}
            showEmailContactForm={showEmailContactForm}
            dropAHintLinkText={dropAHintBtnLabel}
            title={emailCaptureTitle}
            emailCaptureBtn={emailCaptureBtn}
            emailCaptureErrors={emailCaptureErrors}
            emailCapturePlaceholder={emailCapturePlaceholder}
          />
        )}
      </div>
    </Container>
  );
};

SaveResultsPopUp.propTypes = {
  onClose: PropTypes.func,
  showDropAHintBtn: PropTypes.bool,
  showEmailContactForm: PropTypes.bool,
  emailCaptureTitle: PropTypes.string,
  bgColor: PropTypes.string,
  dropAHintBtnLabel: PropTypes.string,
  emailCapturePlaceholder: PropTypes.string,
  emailCaptureErrors: PropTypes.shape(),
  emailCaptureBtn: PropTypes.shape({
    submit: PropTypes.string,
    sending: PropTypes.string,
  }),
};

SaveResultsPopUp.defaultProps = {
  onClose: () => {},
  showDropAHintBtn: false,
  showEmailContactForm: true,
  emailCaptureTitle: '',
  bgColor: '',
  dropAHintBtnLabel: '',
  emailCapturePlaceholder: '',
  emailCaptureErrors: {},
  emailCaptureBtn: {},
};

const Container = withResponsiveUtils(styled.div`
  &&& {
    position: sticky;
    bottom: ${({ isDesktop, mobileResponsiveUtil }) =>
      isDesktop ? '0' : `${mobileResponsiveUtil.getWidthValue(-10)}px`};
    z-index: 2;
    width: 100%;
    left: 0;
    box-sizing: border-box;
    transform: ${({ height }) => `translateY(-${height}px)`};
    height: 0;
  }
`);

export default SaveResultsPopUp;
