import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ArrowBack from '../../components/ArrowBack';
import { withResponsiveUtils } from '../../utils/responsive/ResponsiveUtils';
import { fontVariables } from '../../styles/variables';

const PreviousButton = ({
  titleColor,
  navConfig,
  handleNavigationPrevScreen,
}) => (
  <Button
    type="button"
    className="prev-btn"
    onClick={handleNavigationPrevScreen}
  >
    <ArrowBack className="prev-btn__arrow" color={titleColor} />
    {navConfig?.prevText ? (
      <label className="prev-btn__label" htmlFor="prev">
        {navConfig.prevText}
      </label>
    ) : null}
  </Button>
);

PreviousButton.propTypes = {
  titleColor: PropTypes.string.isRequired,
  navConfig: PropTypes.shape({
    prevText: PropTypes.string,
  }),
  handleNavigationPrevScreen: PropTypes.func.isRequired,
};

PreviousButton.defaultProps = {
  navConfig: {},
};

const Button = withResponsiveUtils(styled.button`
  &&& {
    align-items: center;
    cursor: pointer;
    appearance: none;
    background: none;
    border: none;
    display: flex;
    position: ${({ isDesktop }) => (isDesktop ? 'absolute' : 'relative')};
    margin: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
      isDesktop
        ? `${webResponsiveUtil.getWidthValue(
            96,
          )}px 0 0 ${webResponsiveUtil.getWidthValue(139)}px`
        : `${mobileResponsiveUtil.getWidthValue(
            34,
          )}px auto ${mobileResponsiveUtil.getWidthValue(31)}px`};

    .prev-btn__arrow {
      width: auto;
      height: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(34)
          : mobileResponsiveUtil.getWidthValue(16)}px;
    }

    .prev-btn__label {
      cursor: pointer;
      font-family: ${fontVariables.families.primaryFont};
      font-weight: ${fontVariables.weights.bold};
      letter-spacing: ${({
        isDesktop,
        webResponsiveUtil,
        mobileResponsiveUtil,
      }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(1.6)
          : mobileResponsiveUtil.getWidthValue(1.6)}px;
      color: ${({ titleColor }) => titleColor || 'white'};
      text-transform: uppercase;
      line-height: 1;
      font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(37)
          : mobileResponsiveUtil.getWidthValue(18)}px;
      margin-left: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(10)
          : mobileResponsiveUtil.getWidthValue(10)}px;
      position: relative;
    }
  }
`);

export default PreviousButton;
