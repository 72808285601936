import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring } from '@react-spring/web';
import { fontVariables } from '../styles/variables';
import OptionCard from '../components/OptionCard';
import NavFooter from '../components/NavFooter';
import Input from '../components/Input';
import Image from '../components/Image';

import {
  withResponsiveUtils,
  RESPONSIVE_UTIL_PROPTYPES,
} from '../utils/responsive/ResponsiveUtils';
import DIRECTIONS from '../utils/enums/directions';
import {
  rightToLeftConfig,
  leftToRightConfig,
  fadingInConfig,
} from '../utils/animations';
import { WEIGHTS_PROPTYPES } from '../utils/templatesPropTypes';
import { BLOCKING_SCREEN_TIME } from '../utils/constants';
import normalizeLineBreaks from '../utils/normalizeLineBreaks';

const TITLE_ANI_CONFIGS = {
  leftToRightConfig,
  fadingInConfig,
};

const Page = ({
  pageData,
  handleNextScreen,
  handleNavigationPrevScreen,
  isTransitioning,
  userPreviousSelection,
  handleUnblockNavigation,
  isDesktop,
}) => {
  const {
    titleAniConfig,
    bgColor,
    pageID,
    deskTitle,
    mobTitle,
    titleColor,
    options,
    position,
  } = pageData;

  const { selectedOptions } = userPreviousSelection;

  const aniLtR = useSpring({
    ...leftToRightConfig,
  });

  const aniRtL = useSpring({
    ...rightToLeftConfig,
  });

  const aniTitle = useSpring({
    ...TITLE_ANI_CONFIGS[titleAniConfig],
  });

  const handleCardConfig = (pos) => {
    if (isDesktop) {
      return null;
    }

    return pos === DIRECTIONS.RIGHT ? aniLtR : aniRtL;
  };

  const handleCardContainerConfig = () => {
    if (!isDesktop) {
      return null;
    }

    return aniRtL;
  };

  const getTitle = () => {
    if (mobTitle) {
      return !isDesktop ? mobTitle : deskTitle;
    }
    return deskTitle;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleUnblockNavigation();
    }, BLOCKING_SCREEN_TIME);

    return () => clearTimeout(timer);
  }, [handleUnblockNavigation]);

  return (
    <Container
      className={`${isTransitioning ? 'fade' : ''} screen screen--${pageID}`}
      bgColor={bgColor}
      titleColor={titleColor}
    >
      <animated.h1 className="screen__title" style={aniTitle}>
        {normalizeLineBreaks(getTitle())}
      </animated.h1>
      <animated.div
        className="screen__card-container"
        style={handleCardContainerConfig()}
      >
        {options.map(
          ({
            value,
            text,
            imgSrc,
            altText,
            imgPosition,
            weights,
            summaryText,
          }) => (
            <OptionCard
              key={value}
              active={value === selectedOptions}
              style={handleCardConfig(imgPosition)}
              onClick={() =>
                handleNextScreen({
                  weights,
                  selectedOptions: value,
                  selectedText: text,
                  summaryText,
                  questionPosition: position,
                })
              }
              ComponentText={() => (
                <Input
                  type="radio"
                  name={value}
                  label={normalizeLineBreaks(text)}
                />
              )}
              ComponentImage={() => (
                <Image src={imgSrc} alt={altText || text} />
              )}
              value={value}
              imgPosition={imgPosition}
            />
          ),
        )}
      </animated.div>
      <NavFooter
        prevAction={handleNavigationPrevScreen}
        showPrev
        color={titleColor}
      />
    </Container>
  );
};

Page.propTypes = {
  pageData: PropTypes.shape({
    pageID: PropTypes.string,
    templateType: PropTypes.string,
    deskTitle: PropTypes.string,
    titleColor: PropTypes.string,
    bgColor: PropTypes.string,
    titleAniConfig: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        imgSrc: PropTypes.string,
        value: PropTypes.string,
        imgPosition: PropTypes.string,
        weights: WEIGHTS_PROPTYPES,
      }),
    ),
  }),
  handleNextScreen: PropTypes.func,
  handleNavigationPrevScreen: PropTypes.func,
  isTransitioning: PropTypes.bool,
  userPreviousSelection: PropTypes.shape({
    selectedOptions: PropTypes.string,
  }),
  handleUnblockNavigation: PropTypes.func,
  ...RESPONSIVE_UTIL_PROPTYPES,
};

Page.defaultProps = {
  pageData: {
    options: {},
  },
  handleNextScreen: () => null,
  handleNavigationPrevScreen: () => null,
  isTransitioning: false,
  handleUnblockNavigation: () => null,
  userPreviousSelection: {
    selectedOptions: '',
  },
};

const RadioIconQuestionTemplate = animated(withResponsiveUtils(Page));

const Container = withResponsiveUtils(styled.div`
  &&& {
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding-top: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(50)
        : mobileResponsiveUtil.getWidthValue(32)}px;

    padding-left: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(18)
        : mobileResponsiveUtil.getWidthValue(18)}px;

    padding-right: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(18)
        : mobileResponsiveUtil.getWidthValue(18)}px;

    .screen__title {
      color: ${({ titleColor }) => titleColor};
      font-size: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(58)
          : mobileResponsiveUtil.getWidthValue(26)}px;
      font-weight: ${fontVariables.weights.bold};
      white-space: ${({ isDesktop }) => (isDesktop ? 'pre-wrap' : 'normal')};
      margin-left: auto;
      margin-right: auto;
      margin-bottom: ${({ isDesktop, mobileResponsiveUtil }) =>
        isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(50)}px;
      line-height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(60)
          : mobileResponsiveUtil.getWidthValue(35)}px;
    }

    .screen__card-container {
      display: flex;
      flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
      justify-content: space-around;

      .option-card {
        align-items: center;
        background-color: transparent;
        flex-direction: ${({ isDesktop }) => (isDesktop ? 'column' : '')};
        margin-bottom: ${({ isDesktop, mobileResponsiveUtil }) =>
          isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(50)}px;
        width: ${({ webResponsiveUtil, isDesktop }) =>
          isDesktop ? `${webResponsiveUtil.getWidthValue(412)}px` : '100%'};

        .text-container {
          padding: 0;
          padding-left: ${({ mobileResponsiveUtil, isDesktop }) =>
            isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(15)}px;
          text-align: start;
        }

        .img-container {
          width: 100%;
          margin-bottom: ${({ isDesktop, webResponsiveUtil }) =>
            isDesktop ? `${webResponsiveUtil.getWidthValue(20)}px` : 0};

          svg,
          img {
            width: ${({ mobileResponsiveUtil, isDesktop }) =>
              isDesktop
                ? '100%'
                : `${mobileResponsiveUtil.getWidthValue(157)}px`};
            height: auto;
          }
        }

        :nth-child(odd) {
          .text-container {
            padding-right: ${({ mobileResponsiveUtil, isDesktop }) =>
              isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(10)}px;
            text-align: end;
          }
        }

        @media (hover: hover) {
          :hover {
            cursor: pointer;

            .input-component .input-container input[type='radio']:before {
              box-shadow: inset 0 0 0 3px ${({ bgColor }) => bgColor || 'gray'};
              background-color: white;
              outline: none;
            }
          }
        }
      }

      .option-card.active {
        .input-component .input-container input[type='radio']:before {
          box-shadow: inset 0 0 0 3px ${({ bgColor }) => bgColor || 'gray'};
          background-color: white;
          outline: none;
        }
      }

      .input-component {
        align-items: ${({ isDesktop }) => (isDesktop ? 'center' : 'stretch')};
        display: flex;
        flex-direction: column;

        .input-container {
          margin: ${({ isDesktop }) => (isDesktop ? 10 : 0)}px;

          input[type='radio'] {
            -webkit-appearance: none;
            border: none;
            height: ${({
              webResponsiveUtil,
              mobileResponsiveUtil,
              isDesktop,
            }) =>
              isDesktop
                ? webResponsiveUtil.getWidthValue(33)
                : mobileResponsiveUtil.getWidthValue(33)}px;
            margin: 0;
            width: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
              isDesktop
                ? webResponsiveUtil.getWidthValue(33)
                : mobileResponsiveUtil.getWidthValue(33)}px;

            :before {
              background: transparent;
              border-radius: 100%;
              border: 2px solid white;
              box-shadow: 0 1px 3px 1px rgb(0 0 0 / 20%);
              content: '';
              cursor: pointer;
              display: inline-block;
              height: ${({
                webResponsiveUtil,
                mobileResponsiveUtil,
                isDesktop,
              }) =>
                isDesktop
                  ? webResponsiveUtil.getWidthValue(33)
                  : mobileResponsiveUtil.getWidthValue(33)}px;
              position: relative;
              top: -0.2em;
              width: ${({
                webResponsiveUtil,
                mobileResponsiveUtil,
                isDesktop,
              }) =>
                isDesktop
                  ? webResponsiveUtil.getWidthValue(33)
                  : mobileResponsiveUtil.getWidthValue(33)}px;
            }
          }
        }

        .label-container label {
          color: ${({ titleColor }) => titleColor};
          font-family: ${fontVariables.families.primaryFont}, sans-serif;
          :hover {
            cursor: inherit;
          }
        }
      }
    }

    .label-container {
      text-align: ${({ isDesktop }) => (isDesktop ? 'center' : '')};
      width: ${({ mobileResponsiveUtil, isDesktop }) =>
        isDesktop ? 'unset' : `${mobileResponsiveUtil.getWidthValue(135)}px`};
    }

    &.fade {
      .screen__title,
      .screen__card-container {
        opacity: 0;
        transition: 0.5s all ease-in;
      }
    }
  }
`);

export default RadioIconQuestionTemplate;
