import PropTypes from 'prop-types';
import DIRECTIONS from './enums/directions';

/**
 * DEPRECATED
 */
const TEMPLATES_PAGES_PROPTYPES = {
  pageID: PropTypes.string.isRequired,
  templateType: PropTypes.string.isRequired,
  title: PropTypes.string,
  deskTitle: PropTypes.string,
  mobTitle: PropTypes.string,
  titleColor: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleColor: PropTypes.string,
  bgColor: PropTypes.string, // url with the image
  bgSkin: PropTypes.string, // landing and result page
  nextScreen: PropTypes.string, // for actions in 'multi' optionTypes btns
  buttonCopy: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
  transitionText: PropTypes.string,
  imgUrl: PropTypes.string,
  imgAlt: PropTypes.string,
  optionsType: PropTypes.oneOf(['cta', 'multi', 'rangeInput']), // opt in
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      nextScreenID: PropTypes.string, // for fast forward or backwards
      // OptionCard props
      Text: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
      ]),
      Image: PropTypes.elementType,
      imgPosition: PropTypes.oneOf([DIRECTIONS.RIGHT, DIRECTIONS.LEFT]),
      onClick: PropTypes.func,
      value: PropTypes.string,
      style: PropTypes.objectOf(PropTypes.any),
    }),
  ),
  navConfig: PropTypes.shape({
    showNext: PropTypes.bool,
    nextText: PropTypes.string,
    showPrev: PropTypes.bool,
    prevText: PropTypes.string,
    progressValue: PropTypes.number,
    showProgress: PropTypes.bool,
  }),
  inputConfig: PropTypes.shape({
    minLabel: PropTypes.string.isRequired,
    maxLabel: PropTypes.string.isRequired,
  }),
};

// resultTemplate special props
export const RESULTS_TEMPLATE_PROPS = {
  recommendationTitle: PropTypes.string,
  compareCta: PropTypes.shape({
    show: PropTypes.bool,
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    webCompareLink: PropTypes.string,
    inStoreCompareLink: PropTypes.string,
    hoverConfig: PropTypes.shape({
      textColor: PropTypes.string,
      borderColor: PropTypes.string,
      bgColor: PropTypes.string,
    }),
    emailSection: PropTypes.shape({
      bgColor: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      inputPlaceholder: PropTypes.string.isRequired,
      inputRequiredText: PropTypes.string.isRequired,
      submitButtonText: PropTypes.string.isRequired,
      saveYourResultsLinkText: PropTypes.string.isRequired,
      dropAHintLinkText: PropTypes.string.isRequired,
    }),
  }),
};

export const WEIGHTS_PROPTYPES = PropTypes.arrayOf(
  PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }),
);

export const PRODUCT_PROPTYPES = {
  productUuid: PropTypes.string,
  sku: PropTypes.string,
  productData: PropTypes.shape({
    name: PropTypes.string,
    imgSrc: PropTypes.string,
    description: PropTypes.string,
    shopLink: PropTypes.string,
    price: PropTypes.string,
    showPrice: PropTypes.bool,
    productType: PropTypes.string,
  }),
  weightedBars: PropTypes.arrayOf(
    PropTypes.shape({
      iconType: PropTypes.string,
      iconAlt: PropTypes.string,
      affinityValue: PropTypes.number,
    }),
  ),
};

export default TEMPLATES_PAGES_PROPTYPES;
