import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring } from '@react-spring/web';
import isEmpty from 'lodash/isEmpty';
import { fontVariables } from '../styles/variables';

import {
  withResponsiveUtils,
  RESPONSIVE_UTIL_PROPTYPES,
} from '../utils/responsive/ResponsiveUtils';
import NavFooter from '../components/NavFooter';
import { fadingInConfig } from '../utils/animations';
import { WEIGHTS_PROPTYPES } from '../utils/templatesPropTypes';
import OPTIONS_TYPE from '../utils/enums/optionsType';
import { S9Svg } from '../assets/svgs';
import { BLOCKING_SCREEN_TIME } from '../utils/constants';
import normalizeLineBreaks from '../utils/normalizeLineBreaks';

function getPreviousState(selectedOptions) {
  return typeof selectedOptions === 'string'
    ? [selectedOptions]
    : [...selectedOptions];
}

const KickoffQuestionPage = ({
  handleMoveToScreen,
  handleNextScreen,
  handleNavigationPrevScreen,
  handleUnblockNavigation,
  pageData,
  userPreviousSelection,
  roombaAnimation,
  isDesktop,
}) => {
  const {
    mobTitle,
    deskTitle,
    subTitle,
    options,
    optionsType,
    navConfig,
    isQr,
  } = pageData;

  const isMultipleQuestion = optionsType === OPTIONS_TYPE.MULTI;

  const previousState = isEmpty(userPreviousSelection.selectedOptions)
    ? []
    : getPreviousState(userPreviousSelection.selectedOptions);

  const [selectedOptions, setSelected] = useState(previousState);

  const aniRoomba = useSpring({
    from: roombaAnimation.afterClicked,
  });

  // value === uuid
  const handleSelection = (uuid) => {
    if (selectedOptions.includes(uuid)) {
      // des selecting
      const filtered = selectedOptions.filter((opt) => opt !== uuid);
      setSelected(filtered);
    } else {
      setSelected([...selectedOptions, uuid]); // selecting
    }
  };

  const handleCtaClick = ({ nextScreenID, value, weights, selectedText }) => {
    // if optionsType is 'multi' we want to hold the active status instead
    // of moving the user to another screen
    if (isMultipleQuestion) {
      return handleSelection(value);
    }

    return handleMoveToScreen({
      selectedOptions: value,
      nextScreenID,
      weights,
      selectedText,
    });
  };

  const navNextScreen = (event) => {
    event.stopPropagation();
    const selectedWeights = selectedOptions
      .map((answerValue) => {
        const { weights } = options.find((opt) => opt.value === answerValue);

        return weights;
      })
      .flat(); // [ [w1], [w2] ] => [w1+2]

    const selectedText = options
      .filter((opt) => selectedOptions.includes(opt.value))
      .map((opt) => opt.text)
      .join('-');

    handleNextScreen({
      selectedOptions,
      weights: selectedWeights,
      selectedText,
    });
  };

  const aniFadingIn = useSpring(fadingInConfig);

  const getTitle = () => {
    if (mobTitle) {
      return !isDesktop ? mobTitle : deskTitle;
    }
    return deskTitle;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleUnblockNavigation();
    }, BLOCKING_SCREEN_TIME);

    return () => clearTimeout(timer);
  }, [handleUnblockNavigation]);

  return (
    <Container>
      <animated.div className="screen" style={aniFadingIn}>
        <h1 className="screen__title">{normalizeLineBreaks(getTitle())}</h1>
        {subTitle && (
          <h2 className="screen__sub-title">{normalizeLineBreaks(subTitle)}</h2>
        )}
        {options && (
          <div className="screen-btn-container">
            {options.map(({ text, value, nextScreenID, weights }) => {
              let classString = 'screen__cta-button';

              if (selectedOptions.includes(value)) {
                classString += ' selected';
              }
              return (
                <button
                  key={value}
                  type="button"
                  name={value}
                  className={classString}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleCtaClick({
                      nextScreenID,
                      value,
                      weights,
                      selectedText: text,
                    });
                  }}
                >
                  {text}
                </button>
              );
            })}
          </div>
        )}
      </animated.div>
      <animated.div
        style={aniRoomba}
        className={`roomba-container${isQr ? ' qr' : ''}`}
      >
        <S9Svg className="roomba-container__svg" />
      </animated.div>
      {navConfig ? (
        <NavFooter
          nextAction={navNextScreen}
          prevAction={handleNavigationPrevScreen}
          disableNext={isMultipleQuestion && !selectedOptions.length}
          showPrev
          showNext={isMultipleQuestion}
          color="black"
          prevText={navConfig.prevText}
          nextText={navConfig.nextText}
          showProgress={false}
        />
      ) : null}
    </Container>
  );
};

KickoffQuestionPage.propTypes = {
  pageData: PropTypes.shape({
    pageID: PropTypes.string,
    templateType: PropTypes.string,
    mobTitle: PropTypes.string,
    deskTitle: PropTypes.string,
    subTitle: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.string,
        nextScreenID: PropTypes.string,
        weights: WEIGHTS_PROPTYPES,
      }),
    ),
    optionsType: PropTypes.string,
    navConfig: PropTypes.shape({}),
    isQr: PropTypes.bool,
  }),
  handleMoveToScreen: PropTypes.func,
  handleNextScreen: PropTypes.func,
  handleNavigationPrevScreen: PropTypes.func,
  handleUnblockNavigation: PropTypes.func,
  userPreviousSelection: PropTypes.shape({
    selectedOptions: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  }),
  roombaAnimation: PropTypes.shape({
    initialValues: PropTypes.shape({}),
    afterClicked: PropTypes.shape({}),
  }),
  ...RESPONSIVE_UTIL_PROPTYPES,
};

const KickoffQuestionTemplate = animated(KickoffQuestionPage);

KickoffQuestionPage.defaultProps = {
  pageData: {
    options: [],
  },
  handleMoveToScreen: () => null,
  handleNextScreen: () => null,
  handleNavigationPrevScreen: () => null,
  handleUnblockNavigation: () => null,
  userPreviousSelection: {
    selectedOptions: '',
  },
  roombaAnimation: {
    initialValues: {},
    afterClicked: {},
  },
};

const Container = withResponsiveUtils(styled.div`
  &&& {
    font-family: ${fontVariables.families.primaryFont};
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-rows: repeat(3, auto);
    box-sizing: border-box;
    padding-left: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(18)
        : mobileResponsiveUtil.getWidthValue(18)}px;

    padding-right: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(18)
        : mobileResponsiveUtil.getWidthValue(18)}px;

    .screen {
      grid-row-start: 1;
      z-index: 1;
      position: absolute;
      width: 100%;
      color: black;
      top: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(240)
          : mobileResponsiveUtil.getWidthValue(228)}px;

      .screen__title {
        color: black;
        font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(55)
            : mobileResponsiveUtil.getWidthValue(30)}px;
        font-weight: ${fontVariables.weights.normal};
        width: 20ch;
        padding: ${({ isDesktop }) => (isDesktop ? 'initial' : 0)};

        padding-top: ${({
          isDesktop,
          webResponsiveUtil,
          mobileResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(60)
            : mobileResponsiveUtil.getWidthValue(10)}px;
        padding-bottom: ${({
          isDesktop,
          webResponsiveUtil,
          mobileResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(10)
            : mobileResponsiveUtil.getWidthValue(10)}px;
        margin: 0 auto;
        white-space: ${({ isDesktop }) => (isDesktop ? 'pre-wrap' : 'normal')};
        line-height: ${({
          isDesktop,
          webResponsiveUtil,
          mobileResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(60)
            : mobileResponsiveUtil.getWidthValue(35)}px;
      }

      .screen__sub-title {
        white-space: pre-wrap;
        font-weight: ${fontVariables.weights.bold};
        margin: 0 auto;
        font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(32)
            : mobileResponsiveUtil.getWidthValue(18)}px;
        line-height: ${({
          isDesktop,
          webResponsiveUtil,
          mobileResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(34)
            : mobileResponsiveUtil.getWidthValue(25)}px;
        padding: 10px
          ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(610)
              : mobileResponsiveUtil.getWidthValue(81)}px;
      }

      .screen-btn-container {
        display: flex;
        flex-direction: column;

        .screen__cta-button {
          line-height: 1.3;
          color: #447355;
          font-size: ${({
            isDesktop,
            webResponsiveUtil,
            mobileResponsiveUtil,
          }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(31)
              : mobileResponsiveUtil.getWidthValue(22)}px;
          align-self: center;
          background-color: transparent;
          width: auto;
          height: ${({ isDesktop, webResponsiveUtil }) =>
            isDesktop ? `${webResponsiveUtil.getWidthValue(51)}px` : 'auto'};

          border: 1.5px solid #447355;
          border-radius: 50px;
          padding: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
              isDesktop
                ? `${webResponsiveUtil.getWidthValue(7)}px
            ${webResponsiveUtil.getWidthValue(20)}px`
                : `${mobileResponsiveUtil.getWidthValue(5)}px
            ${mobileResponsiveUtil.getWidthValue(20)}px`}
            0px;
          margin-top: ${({
            isDesktop,
            webResponsiveUtil,
            mobileResponsiveUtil,
          }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(35)
              : mobileResponsiveUtil.getWidthValue(23)}px;

          transition: color, background-color 0.3s ease-out;
          cursor: pointer;

          :hover,
          :focus {
            background-color: white;
            color: #6a768c;
          }
        }

        .screen__cta-button.selected,
        .screen__cta-button:active {
          background-color: #447355;
          border-color: white;
          color: white;
        }

        /* https://css-tricks.com/solving-sticky-hover-states-with-media-hover-hover/ */
        @media (hover: hover) {
          .screen__cta-button:hover {
            background-color: #447355;
            border-color: white;
            color: white;
          }
        }
      }
    }

    /* Artifacts */
    .roomba-container {
      top: 0;
      bottom: ${({ isDesktop, webResponsiveUtil }) =>
        isDesktop ? `${webResponsiveUtil.getWidthValue(-395)}px` : 'unset'};
      position: absolute;
      left: ${({ isDesktop }) => (isDesktop ? 'unset' : '50%')};
      transform: ${({ isDesktop }) =>
        isDesktop ? 'unset' : 'translateX(-50%)'};

      width: ${({ isDesktop, webResponsiveUtil }) =>
        isDesktop
          ? `${webResponsiveUtil.getWidthValue(915)}px`
          : 'calc(100% + 75px)'};

      .roomba-container__svg {
        width: 100%;
        height: auto;
      }
    }
  }
`);

export default KickoffQuestionTemplate;
