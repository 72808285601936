import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { withResponsiveUtils } from '../utils/responsive/ResponsiveUtils';
import LoaderIcon from '../assets/svgs/LoaderIcon';
import { colorVariables } from '../styles/variables';

const Loader = ({ bgColor, color }) => (
  <LoaderWrapper bgColor={bgColor}>
    <LoaderSpinner color={color} />
  </LoaderWrapper>
);

const rotationAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
`;

Loader.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
};

Loader.defaultProps = {
  bgColor: colorVariables.white,
  color: colorVariables.green1,
};

const LoaderWrapper = styled.div`
  &&& {
    align-items: center;
    background-color: ${({ bgColor }) => bgColor};
    color: ${({ color }) => color};
    display: flex;
    justify-content: center;
    height: 100%;
  }
`;

const LoaderSpinner = withResponsiveUtils(styled(LoaderIcon)`
  &&& {
    animation: ${rotationAnimation} 1.5s infinite linear;
    height: auto;
    width: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(170)
        : mobileResponsiveUtil.getWidthValue(170)}px;
  }
`);

export default Loader;
