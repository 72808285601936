import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { setLocale } from 'yup';
import { isEmpty } from 'lodash';
import EmailField from '../InputField';
import SubmitButton from '../SubmitButton';
import {
  withResponsiveUtils,
  isMobile,
} from '../../../utils/responsive/ResponsiveUtils';
import { fontVariables } from '../../../styles/variables';
import { OptInCheck } from '../../../assets/svgs';

const SendEmailForm = ({
  onFormSent,
  inputPlaceholder,
  errorMessages,
  button,
  sending,
  emailCaptureSuccess,
  handleEmailCaptureSubmit,
  errorCode,
  resetFormFlag,
}) => {
  const [buttonText, setButtonText] = useState(button?.submit || '');

  const {
    currentScreenConfig: {
      emailSection: {
        emailCollectionOptInShow,
        emailCollectionOptInPreselect,
        emailCollectionOptInLegalLink1Show,
        emailCollectionOptInLegalLink1Link,
        emailCollectionOptInLegalLink2Show,
        emailCollectionOptInLegalLink2Link,
        emailCollectionOptInTitle,
        emailCollectionOptInLegalLink1Copy,
        emailCollectionOptInLegalLink2Copy,
      } = {},
    },
  } = useSelector((state) => state.view);

  const setErrorMessages = useCallback(
    (errorMessagesObj = {}) => {
      if (isEmpty(errorMessagesObj)) return;
      setLocale({
        mixed: {
          required: errorMessages?.empty,
        },
        string: {
          email: errorMessages?.invalidEmail,
        },
      });
    },
    [errorMessages],
  );

  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email(),
    optIn: Yup.boolean()
      .required(errorMessages?.no_opt_in)
      .oneOf([true], errorMessages?.no_opt_in),
  });

  const formikBag = useFormik({
    initialValues: {
      email: '',
      optIn: emailCollectionOptInPreselect,
    },
    validateOnBlur: true,
    validationSchema,
    enableReinitialize: true,
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    setTouched,
    isValid,
    resetForm,
  } = formikBag;

  const isDesktop = !isMobile();

  const [optInChecked, setOptInChecked] = useState(
    emailCollectionOptInPreselect,
  );

  const handleSubmitButtonClick = () => {
    setTouched(['email', 'optIn'], true);
    if (isValid) {
      const payload = {
        contactEmail: values?.email,
        optIn: emailCollectionOptInShow ? values?.optIn : true,
      };
      handleEmailCaptureSubmit(payload);
    }
  };

  const handleOptInCheckClick = () => {
    setFieldTouched('optIn', true);
    setOptInChecked(!optInChecked);
  };

  useEffect(() => {
    if (!isEmpty(errorMessages)) {
      setErrorMessages(errorMessages);
    }
    return () => {};
  }, [errorMessages, setErrorMessages]);

  useEffect(() => {
    setFieldValue('optIn', optInChecked);
    return () => {};
  }, [optInChecked, setFieldValue]);

  useEffect(() => {
    if (resetFormFlag) {
      resetForm();
    }
    return () => {};
  }, [resetFormFlag, resetForm]);

  useEffect(() => {
    if (sending) {
      setButtonText(button?.sending || 'Saving');
    } else if (emailCaptureSuccess) {
      setButtonText('');
      onFormSent(true);
    } else {
      setButtonText(button?.submit || 'Submit');
    }
    return () => {};
  }, [sending, button.sending, button.submit, emailCaptureSuccess, onFormSent]);
  return (
    <SendEmailFormContainer>
      <SendEmailFormInner isDesktop={isDesktop}>
        <EmailField
          name="email"
          value={values?.email}
          onChange={handleChange}
          placeholder={inputPlaceholder}
          error={
            (Object.values(touched).length > 0 && !isEmpty(errors) && errors) ||
            (errorCode && errorMessages[errorCode])
          }
          disabled={sending || emailCaptureSuccess}
          onBlur={handleBlur}
          type="email"
        />
        {!isDesktop && emailCollectionOptInShow && (
          <OptInContainer isDesktop={isDesktop}>
            <OptInCheckBtn
              name="optIn"
              type="button"
              onClick={handleOptInCheckClick}
              isDesktop={isDesktop}
            >
              {optInChecked && <StyledOptInCheck />}
            </OptInCheckBtn>
            <OptInTitle isDesktop={isDesktop}>
              {emailCollectionOptInTitle}
            </OptInTitle>
          </OptInContainer>
        )}
        <SubmitButton
          status={emailCaptureSuccess}
          onClick={handleSubmitButtonClick}
          text={buttonText}
          disabled={sending || (!isValid && touched.length > 0)}
        />
        {emailCollectionOptInShow &&
          emailCollectionOptInLegalLink1Show &&
          !isDesktop && (
            <OptInLink
              href={emailCollectionOptInLegalLink1Link || '#'}
              target="_blank"
              rel="noreferrer"
            >
              {emailCollectionOptInLegalLink1Copy || ''}
            </OptInLink>
          )}
        {emailCollectionOptInShow &&
          emailCollectionOptInLegalLink2Show &&
          !isDesktop && (
            <OptInLink
              href={emailCollectionOptInLegalLink2Link || '#'}
              target="_blank"
              rel="noreferrer"
            >
              {emailCollectionOptInLegalLink2Copy || ''}
            </OptInLink>
          )}
      </SendEmailFormInner>

      {isDesktop && emailCollectionOptInShow && (
        <OptInContainer isDesktop={isDesktop}>
          <OptInCheckBtn
            type="button"
            onClick={handleOptInCheckClick}
            isDesktop={isDesktop}
          >
            {optInChecked && <StyledOptInCheck />}
          </OptInCheckBtn>
          <OptInTitle isDesktop={isDesktop}>
            {emailCollectionOptInTitle}
          </OptInTitle>
          <OptInLinksContainer>
            {emailCollectionOptInShow && emailCollectionOptInLegalLink1Show && (
              <OptInLink
                href={emailCollectionOptInLegalLink1Link || '#'}
                target="_blank"
                rel="noreferrer"
              >
                {emailCollectionOptInLegalLink1Copy || ''}
              </OptInLink>
            )}
            {emailCollectionOptInShow && emailCollectionOptInLegalLink2Show && (
              <OptInLink
                href={emailCollectionOptInLegalLink2Link || '#'}
                target="_blank"
                rel="noreferrer"
              >
                {emailCollectionOptInLegalLink2Copy || ''}
              </OptInLink>
            )}
          </OptInLinksContainer>
        </OptInContainer>
      )}
    </SendEmailFormContainer>
  );
};

SendEmailForm.propTypes = {
  onFormSent: PropTypes.func,
  inputPlaceholder: PropTypes.string,
  errorMessages: PropTypes.shape(),
  button: PropTypes.shape({
    submit: PropTypes.string,
    sending: PropTypes.string,
  }),
  sending: PropTypes.bool,
  emailCaptureSuccess: PropTypes.bool,
  handleEmailCaptureSubmit: PropTypes.func,
  errorCode: PropTypes.string,
  resetFormFlag: PropTypes.bool,
};

SendEmailForm.defaultProps = {
  onFormSent: () => {},
  inputPlaceholder: '',
  errorMessages: {},
  button: {},
  sending: false,
  emailCaptureSuccess: false,
  handleEmailCaptureSubmit: () => {},
  errorCode: '',
  resetFormFlag: false,
};

const OptInLinksContainer = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    min-width: max-content;
  }
`;

const StyledOptInCheck = styled(OptInCheck)`
  &&& {
    display: block;
    width: 80%;
    height: auto;
    margin: 0 auto;
  }
`;

const SendEmailFormContainer = withResponsiveUtils(styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: ${({ isDesktop, webResponsiveUtil }) =>
      isDesktop ? `${webResponsiveUtil.getWidthValue(780)}px` : '100%'};
    padding-left: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(25)
        : mobileResponsiveUtil.getWidthValue(0)}px;
  }
`);

const SendEmailFormInner = styled.div`
  &&& {
    display: flex;
    flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
  }
`;

const OptInContainer = withResponsiveUtils(styled.div`
  &&& {
    display: flex;
    margin-top: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(14)
        : mobileResponsiveUtil.getWidthValue(2)}px;
    margin-bottom: ${({ isDesktop, mobileResponsiveUtil }) =>
      isDesktop ? '0' : mobileResponsiveUtil.getWidthValue(18)}px;
    align-items: center;
  }
`);

const OptInCheckBtn = withResponsiveUtils(styled.button`
  &&& {
    flex: 0 0 auto;
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    box-shadow: none;
    min-height: auto;
    width: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(25)
        : mobileResponsiveUtil.getWidthValue(22)}px;
    height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(25)
        : mobileResponsiveUtil.getWidthValue(22)}px;
    border-radius: 5px;
    background-color: #f2f4f6;
    &:hover {
      cursor: pointer;
    }
  }
`);

const OptInTitle = withResponsiveUtils(styled.h3`
  &&& {
    flex-shrink: 0;
    margin: 0;
    margin-left: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(10)
        : mobileResponsiveUtil.getWidthValue(12)}px;
    margin-right: ${({ isDesktop, webResponsiveUtil }) =>
      isDesktop ? webResponsiveUtil.getWidthValue(10) : '0'}px;
    font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(14)
        : mobileResponsiveUtil.getWidthValue(12)}px;
    text-align: left;
    font-family: ${fontVariables.families.primaryFont};
    font-weight: ${fontVariables.weights.semibold};
    color: #ffffff;
  }
`);

const OptInLink = withResponsiveUtils(styled.a`
  &&& {
    flex-shrink: 0;
    border: 0;
    background: none;
    margin: 0;
    margin-right: ${({ isDesktop, webResponsiveUtil }) =>
      isDesktop ? webResponsiveUtil.getWidthValue(10) : '0'}px;
    font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(14)
        : mobileResponsiveUtil.getWidthValue(12)}px;
    line-height: ${({ isDesktop }) => (isDesktop ? 'normal' : '1.5')};
    text-align: ${({ isDesktop }) => (isDesktop ? 'left' : 'center')};
    font-family: ${fontVariables.families.primaryFont};
    font-weight: ${fontVariables.weights.semibold};
    color: #ffffff;
    text-decoration: underline;
    margin-top: ${({ isDesktop, mobileResponsiveUtil }) =>
      isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(13)}px;
    &:hover {
      cursor: pointer;
    }
  }
`);

export default SendEmailForm;
