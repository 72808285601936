import { RECOMMENDED_PRODUCTS_NUM } from './constants';
import TEMPLATE_TYPES from './enums/templateTypes';

function getRecommendedProducts({ answers = {}, productsPool = [] }) {
  const PRODUCT_MAP = {};
  let SCORE_BY_TEMPLATE = {};
  let yesOrNoTemplateCount = 0;
  const templatesForWeightedBars = {};
  const disqualifiedProds = [];

  // define all the products in a map
  productsPool.forEach((productInfo) => {
    PRODUCT_MAP[productInfo.productUuid] = {
      ...productInfo,
      score: 0,
    };
  });

  // iterate over the answers adding points to each product map
  Object.keys(answers).forEach((key) => {
    const answerData = answers[key];

    if (answerData.templateType === TEMPLATE_TYPES.YES_OR_NO) {
      yesOrNoTemplateCount += 1;
    }

    if (answerData.appearInResults) {
      templatesForWeightedBars[answerData.templateType] = {
        iconType: answerData.resultIcon,
        affinityValue: 0,
      };
    }

    if (!SCORE_BY_TEMPLATE[answerData.templateType]) {
      SCORE_BY_TEMPLATE = {
        ...SCORE_BY_TEMPLATE,
        [answerData.templateType]: {},
      };
    }

    const answerDataWeights = answerData.weights || [];
    // filter the weights by the current product pool
    const filteredWeights = answerDataWeights.filter((weight) =>
      Object.values(PRODUCT_MAP).find(
        (product) => product.productUuid === weight.uuid,
      ),
    );

    for (let idx = 0; idx < filteredWeights.length; idx += 1) {
      const weight = filteredWeights[idx];

      // we deal with YesOrNoTemplate weights with SCORE_BY_TEMPLATE map
      if (answerData.templateType !== TEMPLATE_TYPES.YES_OR_NO) {
        PRODUCT_MAP[weight.uuid] = {
          ...PRODUCT_MAP[weight.uuid],
          score: PRODUCT_MAP[weight.uuid].score + weight.value,
        };
      }

      if (SCORE_BY_TEMPLATE[answerData.templateType][weight.uuid]) {
        SCORE_BY_TEMPLATE[answerData.templateType] = {
          ...SCORE_BY_TEMPLATE[answerData.templateType],
          [weight.uuid]:
            SCORE_BY_TEMPLATE[answerData.templateType][weight.uuid] +
            weight.value,
        };
      } else {
        SCORE_BY_TEMPLATE[answerData.templateType] = {
          ...SCORE_BY_TEMPLATE[answerData.templateType],
          [weight.uuid]: weight.value,
        };
      }

      // add to the disqualify prod list if selected answer has that flag on
      if (weight.disqualify) disqualifiedProds.push(weight.uuid);
    }
  });

  // make the map an array and add the yesOrNoTemplate average
  const unsortedList = Object.values(PRODUCT_MAP).map((prod) => {
    let yesOrNoAverage = 0;
    // safety belt for skipped questions
    if (
      SCORE_BY_TEMPLATE?.YesOrNoTemplate?.length &&
      SCORE_BY_TEMPLATE.YesOrNoTemplate[prod.productUuid]
    ) {
      yesOrNoAverage =
        SCORE_BY_TEMPLATE.YesOrNoTemplate[prod.productUuid] /
        yesOrNoTemplateCount;
    } else {
      SCORE_BY_TEMPLATE.YesOrNoTemplate = [];
    }

    const newScore = prod.score + yesOrNoAverage;
    SCORE_BY_TEMPLATE.YesOrNoTemplate[prod.productUuid] = yesOrNoAverage;

    return {
      ...prod,
      score: newScore,
      disqualify: disqualifiedProds.includes(prod.productUuid), // needs to be skipped on the next step
    };
  });

  // and grab the top ones
  const rankingProducts = unsortedList
    .filter((prod) => !prod.disqualify) // leave out the disqualified prods
    .sort(
      (prodA, prodB) => (prodA.score > prodB.score ? -1 : 1), // descending
    )
    .slice(0, RECOMMENDED_PRODUCTS_NUM);

  return rankingProducts;
}

export default getRecommendedProducts;
