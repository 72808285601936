import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { withResponsiveUtils } from '../../utils/responsive/ResponsiveUtils';
import { fontVariables } from '../../styles/variables';
import IconClose from '../IconClose';
import Divisor from './Divisor';
import DropAHintBtn from './DropAHintBtn';
import SendEmailForm from './SendEmailForm';

const SaveYourResultsPopupDesktop = ({
  onClose,
  bgColor,
  title,
  dropAHintLinkText,
  showDropAHintBtn,
  showEmailContactForm,
  emailCapturePlaceholder,
  emailCaptureErrors,
  emailCaptureBtn,
}) =>
  (showDropAHintBtn || showEmailContactForm) && (
    <StyledDesktopPopup className="desktop" {...{ bgColor }}>
      {showEmailContactForm && (
        <>
          <Title>{title}</Title>
          <SendEmailForm
            onFormSent={() => {}}
            inputPlaceholder={emailCapturePlaceholder}
            errorMessages={emailCaptureErrors}
            button={emailCaptureBtn}
          />
        </>
      )}
      {showEmailContactForm && showDropAHintBtn && <Divisor />}
      {showDropAHintBtn && <DropAHintBtn text={dropAHintLinkText} />}
      <IconClose className="icon-close" onClick={onClose} />
    </StyledDesktopPopup>
  );

SaveYourResultsPopupDesktop.propTypes = {
  onClose: PropTypes.func,
  bgColor: PropTypes.string,
  title: PropTypes.string,
  dropAHintLinkText: PropTypes.string,
  showDropAHintBtn: PropTypes.bool,
  showEmailContactForm: PropTypes.bool,
  emailCapturePlaceholder: PropTypes.string,
  emailCaptureErrors: PropTypes.shape(),
  emailCaptureBtn: PropTypes.shape({
    submit: PropTypes.string,
    sending: PropTypes.string,
  }),
};

SaveYourResultsPopupDesktop.defaultProps = {
  onClose: () => {},
  bgColor: '',
  title: '',
  dropAHintLinkText: '',
  showDropAHintBtn: false,
  showEmailContactForm: true,
  emailCapturePlaceholder: '',
  emailCaptureErrors: [],
  emailCaptureBtn: {},
};

const StyledDesktopPopup = withResponsiveUtils(styled.div`
  &&& {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: ${({ webResponsiveUtil }) =>
      webResponsiveUtil.getWidthValue(1327)}px;
    background-color: ${({ bgColor }) => bgColor};
    margin: 0 auto;
    padding: ${({ webResponsiveUtil }) =>
      `${webResponsiveUtil.getWidthValue(45)}px 0px`};

    border-radius: ${({ webResponsiveUtil }) =>
      `${webResponsiveUtil.getWidthValue(
        30,
      )}px ${webResponsiveUtil.getWidthValue(30)}px 0px 0px`};

    box-shadow: rgb(0 0 0 / 5%) 0px -17px 15px 12px;

    .email-capture__input-container {
      max-width: ${({ webResponsiveUtil }) =>
        webResponsiveUtil.getWidthValue(337)}px;

      margin: ${({ webResponsiveUtil }) =>
        `0px ${webResponsiveUtil.getWidthValue(20)}px`};
    }

    .icon-close {
      position: absolute;
      top: ${({ webResponsiveUtil }) => webResponsiveUtil.getWidthValue(27)}px;
      right: ${({ webResponsiveUtil }) =>
        webResponsiveUtil.getWidthValue(25)}px;
    }
  }
`);

const Title = withResponsiveUtils(styled.h2`
  &&& {
    font-size: ${({ webResponsiveUtil }) =>
      webResponsiveUtil.getWidthValue(19)}px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: ${fontVariables.weights.bold};
    max-width: ${({ webResponsiveUtil }) =>
      webResponsiveUtil.getWidthValue(190)}px;
    text-align: left;
    line-height: normal;
  }
`);

export default SaveYourResultsPopupDesktop;
