import React, { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const extractUrlMetadata = (url) => {
  const regex = /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)(.+)/;
  const match = url.match(regex);
  const domain = match[1];
  const path = match[2];
  return {
    domain,
    path,
  };
};

const getCloudflareUrl = ({ domain, path, width, height }) => {
  if (width) {
    return `https://${domain}/cdn-cgi/image/width=${width}${path}`;
  }
  if (height) {
    return `https://${domain}/cdn-cgi/image/height=${height}${path}`;
  }
  return '';
};

const Image = ({ src, ...props }) => {
  const [size, setSize] = useState(0);
  const imgRef = useRef();
  const { domain, path } = extractUrlMetadata(src);

  useLayoutEffect(() => {
    setSize({
      width: imgRef?.current?.clientWidth,
      height: imgRef?.current?.clientHeight,
    });
  }, []);

  return (
    <Img
      ref={(r) => {
        imgRef.current = r;
        return null;
      }}
      src={
        getCloudflareUrl({
          domain,
          path,
          width: size?.width,
          height: size?.height,
        }) || src
      }
      {...props}
    />
  );
};

const Img = styled.img``;

export default Image;

Image.propTypes = {
  src: PropTypes.string.isRequired,
};
