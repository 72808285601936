import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { animated } from '@react-spring/web';
import { fontVariables } from '../styles/variables';

import {
  withResponsiveUtils,
  RESPONSIVE_UTIL_PROPTYPES,
} from '../utils/responsive/ResponsiveUtils';
import OptionCard from '../components/OptionCard';
import NavFooter from '../components/NavFooter';
import { leftToRightConfig, rightToLeftConfig } from '../utils/animations';
import isEven from '../utils/isEven';
import DIRECTIONS from '../utils/enums/directions';
import { WEIGHTS_PROPTYPES } from '../utils/templatesPropTypes';
import { BLOCKING_SCREEN_TIME } from '../utils/constants';
import normalizeLineBreaks from '../utils/normalizeLineBreaks';

const BudgetScreen = ({
  pageData,
  handleNextScreen,
  handleNavigationPrevScreen,
  isTransitioning,
  userPreviousSelection: { selectedOptions },
  handleUnblockNavigation,
  isDesktop,
}) => {
  const {
    title,
    titleColor,
    options,
    position,
    currencyToggle,
    partnerCurrency,
  } = pageData;
  const titleAnimation = isDesktop ? rightToLeftConfig : null;

  const currenciesUrls = {
    EUR: {
      1: 'https://app.irobot.mdsrv.media/public-assets/quizv3/assets/euro1.png',
      2: 'https://app.irobot.mdsrv.media/public-assets/quizv3/assets/euro2.png',
      3: 'https://app.irobot.mdsrv.media/public-assets/quizv3/assets/euro3.png',
    },
    GBP: {
      1: 'https://app.irobot.mdsrv.media/public-assets/quizv3/assets/libra1.png',
      2: 'https://app.irobot.mdsrv.media/public-assets/quizv3/assets/libra2.png',
      3: 'https://app.irobot.mdsrv.media/public-assets/quizv3/assets/libra3.png',
    },
    JPY: {
      1: 'https://app.irobot.mdsrv.media/public-assets/quizv3/assets/yen1.png',
      2: 'https://app.irobot.mdsrv.media/public-assets/quizv3/assets/yen2.png',
      3: 'https://app.irobot.mdsrv.media/public-assets/quizv3/assets/yen3.png',
    },
  };

  const parseOptions = (optionsParam) => {
    if (currencyToggle && currenciesUrls[partnerCurrency]) {
      const tempOptions = optionsParam.map((option) => ({ ...option }));
      tempOptions.forEach((opt, key) => {
        tempOptions[key].src = currenciesUrls[partnerCurrency][key + 1];
      });
      return tempOptions;
    }
    return optionsParam;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleUnblockNavigation();
    }, BLOCKING_SCREEN_TIME);

    return () => clearTimeout(timer);
  }, [handleUnblockNavigation]);

  return (
    <Container
      className={isTransitioning ? 'fade' : ''}
      titleColor={titleColor}
    >
      <animated.h1 className="budget__title" style={titleAnimation}>
        {normalizeLineBreaks(title)}
      </animated.h1>
      <BudgetOptionsContainer className="content">
        {parseOptions(options).map(
          ({ value, weights, text, summaryText, src }, index) => (
            <OptionCard
              key={value}
              active={value === selectedOptions}
              imgPosition={isEven(index) ? DIRECTIONS.RIGHT : DIRECTIONS.LEFT}
              text={normalizeLineBreaks(text)}
              src={src}
              special
              imgStyle={{
                height: isDesktop ? '70%' : '50%',
                marginBottom: isDesktop ? '15px' : '20px',
                marginRight: isEven(index) ? '0px' : '35%',
                marginLeft: isEven(index) ? '35%' : '0px',
              }}
              value={value}
              onClick={() =>
                handleNextScreen({
                  weights,
                  selectedOptions: value,
                  selectedText: text,
                  summaryText,
                  questionPosition: position,
                })
              }
              style={
                !isDesktop && isEven(index)
                  ? leftToRightConfig
                  : rightToLeftConfig
              }
            />
          ),
        )}
      </BudgetOptionsContainer>
      <NavFooter
        prevAction={handleNavigationPrevScreen}
        color={titleColor}
        showPrev
      />
    </Container>
  );
};

BudgetScreen.propTypes = {
  pageData: PropTypes.shape({
    pageID: PropTypes.string,
    templateType: PropTypes.string,
    title: PropTypes.string,
    titleColor: PropTypes.string,
    bgColor: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        svgSrc: PropTypes.string,
        altText: PropTypes.string,
        value: PropTypes.string,
        weights: WEIGHTS_PROPTYPES,
      }),
    ),
    navConfig: PropTypes.shape({}),
  }),
  handleNextScreen: PropTypes.func,
  handleNavigationPrevScreen: PropTypes.func,
  isTransitioning: PropTypes.bool,
  userPreviousSelection: PropTypes.shape({
    selectedOptions: PropTypes.string,
  }),
  handleUnblockNavigation: PropTypes.func,
  ...RESPONSIVE_UTIL_PROPTYPES,
};

BudgetScreen.defaultProps = {
  pageData: {
    options: [],
    navConfig: {},
  },
  handleNextScreen: () => null,
  handleNavigationPrevScreen: () => null,
  isTransitioning: false,
  userPreviousSelection: {
    selectedOptions: '',
  },
  handleUnblockNavigation: () => null,
};

const BudgetTemplate = animated(withResponsiveUtils(BudgetScreen));

const Container = withResponsiveUtils(styled.div`
  &&& {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    max-width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    width: 100%;
    padding-top: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(50)
        : mobileResponsiveUtil.getWidthValue(32)}px;

    padding-left: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(18)
        : mobileResponsiveUtil.getWidthValue(18)}px;

    padding-right: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(18)
        : mobileResponsiveUtil.getWidthValue(18)}px;

    .budget__title {
      white-space: ${({ isDesktop }) => (isDesktop ? 'pre-wrap' : 'normal')};
      font-family: ${fontVariables.families.primaryFont};
      color: ${({ titleColor }) => titleColor || 'black'};
      font-weight: ${fontVariables.weights.bold};
      margin: 0 auto;
      font-size: ${({ mobileResponsiveUtil, webResponsiveUtil, isDesktop }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(58)
          : mobileResponsiveUtil.getWidthValue(30)}px;
      line-height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(60)
          : mobileResponsiveUtil.getWidthValue(35)}px;
      margin-bottom: ${({
        isDesktop,
        webResponsiveUtil,
        mobileResponsiveUtil,
      }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(20)
          : mobileResponsiveUtil.getWidthValue(10)}px;
    }
  }
`);

const BudgetOptionsContainer = withResponsiveUtils(styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    max-width: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(1140)
        : mobileResponsiveUtil.getWidthValue(263)}px;

    width: 100%;
    margin: 0 auto;

    .option-card {
      font-family: ${fontVariables.families.primaryFont};
      width: ${({ webResponsiveUtil, isDesktop }) =>
        isDesktop ? `${webResponsiveUtil.getWidthValue(536)}px` : '100%'};

      max-width: ${({ webResponsiveUtil, isDesktop }) =>
        isDesktop ? `${webResponsiveUtil.getWidthValue(536)}px` : '100%'};

      max-height: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(170)
          : mobileResponsiveUtil.getWidthValue(170)}px;

      margin-bottom: ${({
        mobileResponsiveUtil,
        webResponsiveUtil,
        isDesktop,
      }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(35)
          : mobileResponsiveUtil.getWidthValue(55)}px;

      .text-container {
        line-height: 1.2;
        max-width: 50%;
        box-sizing: border-box;
      }
    }

    .option-card:nth-of-type(1) {
      align-self: flex-start;

      .image {
        left: 0;
      }
    }
    .option-card:nth-child(2) {
      align-self: center;
    }
    .option-card:nth-child(3) {
      align-self: ${({ isDesktop }) => (isDesktop ? 'flex-end' : 'center')};
    }

    &.fade {
      .budget__title,
      .content {
        opacity: 0;
        transition: 0.5s all ease-in;
      }
    }
  }
`);

export default BudgetTemplate;
