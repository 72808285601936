const TEMPLATE_TYPES = {
  QUIZ_START: 'QuizStartTemplate',
  KICKOFF: 'KickoffQuestionTemplate',
  FOUR_ANSWER_ILLUSTRATION: 'FourAnswerIllustrationQuestionTemplate',
  SLIDER: 'SliderQuestionTemplate',
  TWO_ANSWER_ILLUSTRATION: 'TwoAnswerIllustrationTemplate',
  YES_OR_NO: 'YesOrNoTemplate',
  MULTIPLE_CHOICE: 'MultipleChoiceQuestionTemplate',
  RADIO_ICON: 'RadioIconQuestionTemplate',
  SIMPLE_QUESTION: 'SimpleQuestionTemplate',
  BUDGET: 'BudgetTemplate',
  RESULTS: 'ResultsTemplate',
};

export default TEMPLATE_TYPES;
