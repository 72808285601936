const session = null;

const parseProduct = ({ uuid, productUuid, sku, productData }) => {
  try {
    return {
      product_id: uuid || productUuid,
      product_kind: productData?.productType,
      product_sku: sku,
    };
  } catch (e) {
    return {};
  }
};

const parseAnswer = ({ uuid, selectedText }) => ({
  answer_id: uuid,
  answer_title: selectedText,
});

const parseQuestion = ({ uuid, pageID, title }) => ({
  question_id: uuid || pageID,
  question_text: title,
});

export const onSelectAnswer = (
  pixel = () => {},
  currentAnswer = {},
  answers = [],
) => {
  try {
    pixel('event', 'answerClick', {
      session,
      ...parseQuestion(currentAnswer),
      selected_answers: answers && answers.map((answer) => parseAnswer(answer)),
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export const onLoadResults = (
  pixel = () => {},
  resultsProducts = [],
  answers = [],
) => {
  try {
    pixel('event', 'resultsLoad', {
      session,
      products:
        resultsProducts &&
        resultsProducts.map((product) => parseProduct(product)),
      selected_answers: answers && answers.map((answer) => parseAnswer(answer)),
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export const onClickProduct = (pixel = () => {}, trackingProductData = {}) => {
  const { productData: fullProduct } = trackingProductData;

  try {
    pixel('event', 'productClick', {
      session,
      product: parseProduct(fullProduct),
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export const onEmailSubmit = (pixel = () => {}, sourceCta = '') => {
  try {
    pixel('event', 'emailSubmit', {
      session,
      ctaLabel: sourceCta,
    });
  } catch (error) {
    console.error(error);
  }
};
