import styled from 'styled-components';
import { withResponsiveUtils } from '../../utils/responsive/ResponsiveUtils';

const Divisor = withResponsiveUtils(styled.div`
  &&& {
    display: block;
    height: ${({ webResponsiveUtil }) => webResponsiveUtil.getWidthValue(48)}px;

    width: 2px;
    background: rgba(255, 255, 255, 0.5);
    margin: 0
      ${({ webResponsiveUtil }) => webResponsiveUtil.getWidthValue(80)}px;
  }
`);

export default Divisor;
