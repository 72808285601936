import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring } from '@react-spring/web';
import { easeCubicInOut } from 'd3-ease';
import { useSelector } from 'react-redux';
import { fontVariables } from '../styles/variables';
import { withResponsiveUtils } from '../utils/responsive/ResponsiveUtils';
import { fadingInConfig } from '../utils/animations';
import normalizeLineBreaks from '../utils/normalizeLineBreaks';
import { trackQuizLoad } from '../utils/analytics';

import { S9Svg } from '../assets/svgs';

const Page = ({
  isNavigatingBack,
  handleNextScreen,
  pageData,
  roombaAnimation,
  handleUnblockNavigation,
}) => {
  const {
    title,
    subTitle,
    titleColor,
    bgColor,
    buttonCopy,
    buttonColor,
    buttonHoverColor,
    transitionText,
    isQr,
    logoUrl,
    logoAlt,
    deskBgSkin,
    mobBgSkin,
  } = pageData;

  const { metadata } = useSelector((state) => state.view);

  const [isTransitioning, setIsTransitioning] = useState(isNavigatingBack);

  const btnHandleClick = () => {
    setIsTransitioning(true);

    handleNextScreen({
      delayTime: isQr ? 2900 : 2600,
      selectedOptions: 'quizStarted',
      selectedText: buttonCopy,
    });
  };

  const aniStart = isNavigatingBack
    ? roombaAnimation.afterClicked
    : roombaAnimation.initialValues;
  const aniEnd = isNavigatingBack
    ? roombaAnimation.initialValues
    : roombaAnimation.afterClicked;
  const aniRoombaDurationTime = isQr ? 1500 : 1200;

  const aniRoomba = useSpring({
    from: aniStart,
    to: isTransitioning ? aniEnd : aniStart,
    delay: 1400,
    config: { duration: aniRoombaDurationTime, easing: easeCubicInOut },
  });

  const aniFadingIn = useSpring(fadingInConfig);

  useEffect(() => {
    handleUnblockNavigation();
  }, [handleUnblockNavigation]);

  useEffect(() => {
    trackQuizLoad(metadata);
  }, [metadata]);

  const showLetsGetToKnow = isTransitioning && !isNavigatingBack;

  return (
    <Container
      titleColor={titleColor}
      bgColor={bgColor}
      buttonColor={buttonColor}
      buttonHoverColor={buttonHoverColor}
      deskBgSkin={deskBgSkin}
      mobBgSkin={mobBgSkin}
    >
      <animated.div className="screen" style={aniFadingIn}>
        {showLetsGetToKnow ? (
          <LetsGetToKnowEachOther
            aniFadingIn={aniFadingIn}
            transitionText={normalizeLineBreaks(transitionText)}
          />
        ) : (
          <FindYourPerfectMatch
            title={normalizeLineBreaks(title)}
            subTitle={normalizeLineBreaks(subTitle)}
            buttonCopy={buttonCopy}
            btnHandleClick={btnHandleClick}
            logoUrl={logoUrl}
            logoAlt={logoAlt}
          />
        )}
      </animated.div>
      <RoombaContainer style={aniRoomba}>
        <S9Svg className="roomba-container__svg" />
        <animated.div className="bg-circle" />
      </RoombaContainer>
    </Container>
  );
};

const QuizStartTemplate = animated(Page);

Page.propTypes = {
  pageData: PropTypes.shape({
    pageID: PropTypes.string,
    templateType: PropTypes.string,
    title: PropTypes.string,
    titleColor: PropTypes.string,
    subTitle: PropTypes.string,
    subTitleColor: PropTypes.string,
    deskBgSkin: PropTypes.string,
    mobBgSkin: PropTypes.string,
    bgColor: PropTypes.string,
    buttonCopy: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonHoverColor: PropTypes.string,
    transitionText: PropTypes.string,
    isQr: PropTypes.bool,
    logoUrl: PropTypes.string,
    logoAlt: PropTypes.string,
  }),
  isNavigatingBack: PropTypes.bool,
  handleNextScreen: PropTypes.func,
  roombaAnimation: PropTypes.shape({
    initialValues: PropTypes.shape({}),
    afterClicked: PropTypes.shape({}),
  }),
  handleUnblockNavigation: PropTypes.func,
};

Page.defaultProps = {
  pageData: {
    pageID: '',
    templateType: '',
    title: '',
    titleColor: '',
    subTitle: '',
    subTitleColor: '',
    deskBgSkin: '',
    mobBgSkin: '',
    bgColor: '',
    buttonCopy: '',
    buttonColor: '',
    buttonHoverColor: '',
    transitionText: '',
    isQr: false,
    logoUrl: '',
    logoAlt: '',
  },
  isNavigatingBack: false,
  handleNextScreen: () => null,
  roombaAnimation: {
    initialValues: {},
    afterClicked: {},
  },
  handleUnblockNavigation: () => null,
};

const FindYourPerfectMatch = ({
  title,
  subTitle,
  btnHandleClick,
  buttonCopy,
  logoUrl,
  logoAlt,
}) => (
  <>
    <img src={logoUrl} alt={logoAlt} />
    <animated.h1 className="screen__title">{title}</animated.h1>

    {subTitle && <h2 className="screen__sub-title">{subTitle}</h2>}
    <div className="screen-btn-container">
      <button
        type="button"
        name="screen__cta-button"
        className="screen__cta-button"
        onClick={btnHandleClick}
      >
        {buttonCopy}
      </button>
    </div>
  </>
);

FindYourPerfectMatch.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  btnHandleClick: PropTypes.func.isRequired,
  buttonCopy: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoAlt: PropTypes.string.isRequired,
};

const LetsGetToKnowEachOther = ({ aniFadingIn, transitionText }) => (
  <animated.h1 style={aniFadingIn} className="screen__transition-title">
    {transitionText}
  </animated.h1>
);

LetsGetToKnowEachOther.propTypes = {
  aniFadingIn: PropTypes.shape().isRequired,
  transitionText: PropTypes.string.isRequired,
};

const RoombaContainer = withResponsiveUtils(
  animated(styled.div`
    top: 0;
    bottom: ${({ isDesktop, webResponsiveUtil }) =>
      isDesktop ? `${webResponsiveUtil.getWidthValue(-395)}px` : 'unset'};
    position: absolute;
    left: ${({ isDesktop }) => (isDesktop ? 'unset' : '50%')};
    z-index: 3;
    pointer-events: none;
    width: ${({ isDesktop, webResponsiveUtil }) =>
      isDesktop
        ? `${webResponsiveUtil.getWidthValue(915)}px`
        : 'calc(100% + 75px)'};

    .roomba-container__svg {
      width: 100%;
      height: auto;
      z-index: 2;
    }

    .bg-circle {
      position: absolute;
      border-radius: ${({ isDesktop }) => (isDesktop ? '200vw' : '100vw')};
      background: #fff;
      top: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(250)
          : mobileResponsiveUtil.getWidthValue(150)}px;
      left: ${({ isQr, isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        // eslint-disable-next-line no-nested-ternary
        isQr
          ? isDesktop
            ? 'inherit'
            : `${mobileResponsiveUtil.getWidthValue(-20)}px`
          : isDesktop
          ? webResponsiveUtil.getWidthValue(-window.innerWidth * 1.5)
          : mobileResponsiveUtil.getWidthValue(-40)}px;

      @media screen and (min-width: 1024px) {
        left: ${({ isQr, webResponsiveUtil }) =>
          isQr
            ? `${webResponsiveUtil.getWidthValue(-window.innerWidth * 1.6)}px`
            : `${webResponsiveUtil.getWidthValue(-window.innerWidth * 2.2)}px`};
      }

      @media only screen and (min-width: 1200px) {
        top: 29vh;
      }

      @media only screen and (min-width: 1600px) {
        top: 35vh;
      }

      @media screen and (min-width: 1919px) {
        left: ${({ isQr, webResponsiveUtil }) =>
          isQr
            ? `${webResponsiveUtil.getWidthValue(-window.innerWidth * 1.1)}px`
            : `${webResponsiveUtil.getWidthValue(-window.innerWidth * 1.6)}px`};
      }

      @media screen and (min-width: 2560px) {
        left: ${({ isQr, webResponsiveUtil }) =>
          isQr
            ? `${webResponsiveUtil.getWidthValue(-window.innerWidth * 0.8)}px`
            : 'auto'};
      }

      z-index: -1;
      height: 400vw;
      width: 400vw;
    }
  `),
);

const Container = withResponsiveUtils(styled.div`
  &&& {
    color: ${({ titleColor }) => titleColor};
    background-image: ${({ isDesktop, deskBgSkin, mobBgSkin }) =>
      `url(${isDesktop ? deskBgSkin : mobBgSkin})`};
    background-color: ${({ bgColor }) => bgColor};
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .screen {
      z-index: 2;
      position: absolute;
      width: 100%;
      background-color: transparent;
      margin-bottom: ${({ isDesktop, webResponsiveUtil }) =>
        isDesktop ? webResponsiveUtil.getWidthValue(66) : 0}px;

      .logo-container svg {
        height: auto;
        width: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(120)
            : mobileResponsiveUtil.getWidthValue(70)}px;
      }

      .screen__title,
      .screen__transition-title {
        line-height: 1.2;
        color: #fff;
        font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(55)
            : mobileResponsiveUtil.getWidthValue(35)}px;
        font-weight: ${fontVariables.weights.bold};
        padding: 0
          ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(60)
              : mobileResponsiveUtil.getWidthValue(60)}px;
        padding-top: ${({
          isDesktop,
          webResponsiveUtil,
          mobileResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(60)
            : mobileResponsiveUtil.getWidthValue(10)}px;
        padding-bottom: ${({
          isDesktop,
          webResponsiveUtil,
          mobileResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(10)
            : mobileResponsiveUtil.getWidthValue(10)}px;
        margin: 0 auto;
      }

      .screen__sub-title {
        white-space: ${({ isDesktop }) => (isDesktop ? 'pre-wrap' : 'normal')};
        margin: 0 auto;
        font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(32)
            : mobileResponsiveUtil.getWidthValue(18)}px;
        line-height: ${({
          isDesktop,
          webResponsiveUtil,
          mobileResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(35)
            : mobileResponsiveUtil.getWidthValue(20)}px;
        font-weight: ${fontVariables.weights.light};
        padding: 10px
          ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(540)
              : mobileResponsiveUtil.getWidthValue(10)}px;
      }

      .screen__cta-button {
        line-height: 1.3;
        align-self: center;
        background-color: transparent;
        border-radius: 50px;
        border: 1.5px solid ${({ buttonColor }) => buttonColor};
        color: ${({ buttonColor }) => buttonColor};
        cursor: pointer;
        float: none;
        font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(31)
            : mobileResponsiveUtil.getWidthValue(22)}px;
        height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(51)
            : mobileResponsiveUtil.getWidthValue(36)}px;
        padding: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? `${webResponsiveUtil.getWidthValue(7)}px
            ${webResponsiveUtil.getWidthValue(20)}`
            : `${mobileResponsiveUtil.getWidthValue(5)}px
            ${mobileResponsiveUtil.getWidthValue(20)}`}px;
        margin-top: ${({
          isDesktop,
          webResponsiveUtil,
          mobileResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(41)
            : mobileResponsiveUtil.getWidthValue(33)}px;
        transition: color, background-color 0.3s ease-out;
        min-width: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(212)
            : mobileResponsiveUtil.getWidthValue(152)}px;
        z-index: 4;

        :hover,
        :focus {
          color: ${({ bgColor }) => bgColor};
          background-color: ${({ buttonHoverColor }) => buttonHoverColor};
        }
      }
    }

    .screen__title,
    .screen__sub-title,
    .screen__cta-button,
    .screen__transition-title {
      font-family: ${fontVariables.families.primaryFont}, sans-serif;
    }
  }
`);

export default QuizStartTemplate;
