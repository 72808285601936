import React, { useRef, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes, { shape } from 'prop-types';
import { animated, useSpring } from '@react-spring/web';
import styled from 'styled-components';
import _ from 'lodash';

import { fontVariables } from '../../styles/variables';
import ProductCard from '../../components/ProductCard';
import PreviousButton from './PreviousButton';
import useOnVisible from '../../hooks/useOnVisible';
import {
  trackQuizResults,
  trackOtherRecommendationsImpressions,
} from '../../utils/analytics';
import {
  withResponsiveUtils,
  RESPONSIVE_UTIL_PROPTYPES,
} from '../../utils/responsive/ResponsiveUtils';
import SaveYourResults from '../../components/SaveYourResults/SaveYourResults';
import SaveResultsPopUp from '../../components/SaveYourResults/SaveResultsPopUp';
import { PRODUCT_PROPTYPES } from '../../utils/templatesPropTypes';
import getResultsCopy from '../../utils/getResultsCopy';
import getRecommendedProducts from '../../utils/getRecommendedProducts';
import { BLOCKING_SCREEN_TIME } from '../../utils/constants';
// import { mockedResponses } from '../../utils/dummyData'; // to help to debug this template
import normalizeLineBreaks from '../../utils/normalizeLineBreaks';

export const ResultsPage = ({
  pageData,
  handleNavigationPrevScreen,
  style,
  handleUnblockNavigation,
  isDesktop,
}) => {
  const {
    title,
    titleColor,
    bgColor,
    subTitle,
    navConfig,
    compareCta,
    recommendationTitle,
    emailSection,
    dropAHintSection,
    alsoLikeConfig,
    productsPool,
    shopProductBtnText,
    shopProductBtnTextColor,
    shopProductBtnBackgroundColor,
    shopProductBtnBorderColor,
    isQr,
    deskBgSkin,
    mobBgSkin,
    partnerCurrencyCode,
  } = pageData;

  const [resultsCopy, setResultsCopy] = useState('');
  const containerRef = useRef('null');
  const [showSaveResultsPopUp, setShowSaveResultsPopUp] = useState(
    emailSection?.showEmailContactForm ||
      dropAHintSection?.resultsShowShareWithFriends,
  );
  const { answers, isScrollingBlocked } = useSelector((state) => state.view);

  const mayAlsoLikeRef = useRef(null);
  const mayAlsoLikeIsVisible = useOnVisible({
    ref: mayAlsoLikeRef,
    options: { threshold: 0.5 },
  });

  const bgValue = isDesktop ? deskBgSkin : mobBgSkin;
  const containerAni = useSpring(style);
  const showWeightedBars = false; // https://genome.atlassian.net/browse/ROB-3234

  const recommendedProducts = useMemo(
    () =>
      getRecommendedProducts({
        answers,
        // answers: mockedResponses.answers, // to help to debug this template
        productsPool,
      }),
    [answers, productsPool],
  );

  const getCompareLinkBaseUrl = (compLinkData = {}) => {
    let compareLinkUrl = '';
    if (isQr) {
      compareLinkUrl = compLinkData?.show && compLinkData?.inStoreCompareLink;
    } else {
      compareLinkUrl = compLinkData?.show && compLinkData?.webCompareLink;
    }
    return compareLinkUrl;
  };

  const getCompareLink = ({ baseUrl = '', SKUs = [] }) => {
    if (baseUrl && SKUs.length > 0)
      return `${baseUrl}?recommendedProducts=${SKUs.join(',')}`;
    return baseUrl;
  };

  const compareCtaLink = getCompareLinkBaseUrl(compareCta);

  useEffect(() => {
    trackQuizResults(recommendedProducts);
  }, [recommendedProducts]);

  useEffect(() => {
    const fetchResultsCopy = async () => {
      const copy = await getResultsCopy(answers, subTitle);
      setResultsCopy(copy);
    };
    if (!_.isEmpty(answers)) {
      fetchResultsCopy();
    }
    return () => {};
  }, [subTitle, answers]);

  useEffect(() => {
    if (mayAlsoLikeIsVisible && alsoLikeConfig.show) {
      trackOtherRecommendationsImpressions(
        alsoLikeConfig.recommendableProducts,
      );
    }
  }, [mayAlsoLikeIsVisible, alsoLikeConfig]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleUnblockNavigation();
    }, BLOCKING_SCREEN_TIME);

    return () => clearTimeout(timer);
  }, [handleUnblockNavigation]);

  return (
    <>
      <Container
        ref={containerRef}
        style={containerAni}
        stopScrolling={isScrollingBlocked}
        bgValue={bgValue}
        titleColor={titleColor}
      >
        <h1 className="results__title">{normalizeLineBreaks(title)}</h1>
        <ResultsText className="results__text">
          {normalizeLineBreaks(resultsCopy || subTitle)}
        </ResultsText>
        <PreviousButton
          titleColor={titleColor}
          navConfig={navConfig}
          handleNavigationPrevScreen={handleNavigationPrevScreen}
        />
        <Divider />
        <RecommendationContainer
          className="recommendation-container"
          showWeightedBars={showWeightedBars}
        >
          <h2 className="recommendation__title">
            {normalizeLineBreaks(recommendationTitle)}
          </h2>
          <div className="products-card-container">
            {recommendedProducts.map((product) => {
              const { productUuid, productData } = product;
              const fullProduct = {
                ...product,
                productData: { ...productData, showWeightedBars },
              };
              return (
                <ProductCard
                  key={productUuid}
                  productUuid={productUuid}
                  className="product-card"
                  trackingActionName="Quiz: Results"
                  shopProductBtnText={shopProductBtnText}
                  shopProductBtnTextColor={shopProductBtnTextColor}
                  shopProductBtnBackgroundColor={shopProductBtnBackgroundColor}
                  shopProductBtnBorderColor={shopProductBtnBorderColor}
                  titleColor={titleColor}
                  bgColor={bgColor}
                  currencyCode={partnerCurrencyCode}
                  {...fullProduct}
                />
              );
            })}
          </div>
        </RecommendationContainer>
        {compareCtaLink && (
          <CompareCtaContainer
            textColor={compareCta.textColor}
            hoverConfig={compareCta.hoverConfig}
          >
            <a
              href={getCompareLink({
                baseUrl: compareCtaLink,
                SKUs: recommendedProducts.map(
                  ({ sku, productUuid }) => sku || productUuid,
                ),
              })}
              rel="noopener noreferrer"
              target="_blank"
            >
              {compareCta.text}
            </a>
          </CompareCtaContainer>
        )}

        <SaveYourResults
          dropAHintSection={dropAHintSection}
          showEmailContactForm={emailSection?.showEmailContactForm}
          emailCaptureTitle={emailSection?.title}
          bgColor={emailSection?.bgColor}
          emailCaptureBtnLabel={emailSection?.saveYourResultsLinkText}
          dropAHintBtnLabel={emailSection?.dropAHintLinkText}
          emailCaptureBtn={emailSection?.button}
          emailCaptureErrors={emailSection?.errors}
          emailCapturePlaceholder={emailSection?.inputPlaceholder}
        />

        {alsoLikeConfig?.show && (
          <OtherRecommendationsSection
            alsoLikeConfig={alsoLikeConfig}
            mayAlsoLikeRef={mayAlsoLikeRef}
            shopProductBtnText={shopProductBtnText}
            shopProductBtnTextColor={shopProductBtnTextColor}
            shopProductBtnBackgroundColor={shopProductBtnBackgroundColor}
            shopProductBtnBorderColor={shopProductBtnBorderColor}
            desktopBottomSpace={showSaveResultsPopUp ? 30 : 0}
            mobileBottomSpace={showSaveResultsPopUp ? 300 : 0}
          />
        )}
      </Container>
      {showSaveResultsPopUp && (
        <SaveResultsPopUp
          showDropAHintBtn={dropAHintSection?.resultsShowShareWithFriends}
          showEmailContactForm={emailSection?.showEmailContactForm}
          emailCaptureTitle={emailSection?.title}
          bgColor={emailSection?.bgColor}
          dropAHintBtnLabel={emailSection?.dropAHintLinkText}
          onClose={() => setShowSaveResultsPopUp(false)}
          emailCaptureBtn={emailSection?.button}
          emailCaptureErrors={emailSection?.errors}
          emailCapturePlaceholder={emailSection?.inputPlaceholder}
        />
      )}
    </>
  );
};

ResultsPage.propTypes = {
  pageData: shape({
    pageID: PropTypes.string,
    templateType: PropTypes.string,
    title: PropTypes.string,
    titleColor: PropTypes.string,
    bgColor: PropTypes.string,
    subTitle: PropTypes.string,
    compareCta: shape({
      show: PropTypes.bool,
      text: PropTypes.string,
      textColor: PropTypes.string,
      webCompareLink: PropTypes.string,
      inStoreCompareLink: PropTypes.string,
      hoverConfig: shape({
        textColor: PropTypes.string,
        borderColor: PropTypes.string,
        bgColor: PropTypes.string,
      }),
    }),
    recommendationTitle: PropTypes.string,
    productsPool: PropTypes.arrayOf(shape(PRODUCT_PROPTYPES)),
    shopProductBtnText: PropTypes.string,
    isQr: PropTypes.bool,
    deskBgSkin: PropTypes.string,
    mobBgSkin: PropTypes.string,
    partnerCurrencyCode: PropTypes.string,
    emailSection: shape({
      bgColor: PropTypes.string,
      title: PropTypes.string,
      inputPlaceholder: PropTypes.string,
      saveYourResultsLinkText: PropTypes.string,
      dropAHintLinkText: PropTypes.string,
      resultsShowShareWithFriends: PropTypes.string,
      showEmailContactForm: PropTypes.bool,
      button: shape({
        submit: PropTypes.string,
        sending: PropTypes.string,
      }),
    }),
    errors: shape({
      empty: PropTypes.string,
      invalidEmail: PropTypes.string,
      generic: PropTypes.string,
      no_opt_in: PropTypes.string,
    }),
    alsoLikeConfig: shape({
      title: PropTypes.string,
      titleColor: PropTypes.string,
      bgColor: PropTypes.string,
      show: PropTypes.bool,
    }),
    recommendableProducts: PropTypes.arrayOf(shape(PRODUCT_PROPTYPES)),
    dropAHintSection: shape({}),
    navConfig: shape({
      showPrev: PropTypes.bool,
      prevText: PropTypes.string,
      showProgress: PropTypes.bool,
    }),
  }),
  handleNavigationPrevScreen: PropTypes.func,
  handleUnblockNavigation: PropTypes.func,
  style: shape({}),
  ...RESPONSIVE_UTIL_PROPTYPES,
};

ResultsPage.defaultProps = {
  pageData: {
    title: '',
    titleColor: '',
    bgColor: '',
    subTitle: '',
    compareCta: {
      show: false,
      text: '',
      textColor: '',
      webCompareLink: '',
      inStoreCompareLink: '',
      hoverConfig: {
        textColor: '',
        borderColor: '',
        bgColor: '',
      },
    },
    recommendationTitle: '',
    productsPool: [],
    shopProductBtnText: '',
    isQr: false,
    deskBgSkin: '',
    mobBgSkin: '',
    partnerCurrencyCode: '',
    emailSection: {
      bgColor: '',
      title: '',
      inputPlaceholder: '',
      saveYourResultsLinkText: '',
      dropAHintLinkText: '',
      resultsShowShareWithFriends: '',
      showEmailContactForm: '',
      button: {
        submit: '',
        sending: '',
      },
    },
    errors: {
      empty: '',
      invalidEmail: '',
      generic: '',
      no_opt_in: '',
    },
    alsoLikeConfig: {
      title: '',
      titleColor: '',
      bgColor: '',
      show: false,
    },
    recommendableProducts: {
      productData: [],
    },
    dropAHintSection: {},
    navConfig: {},
  },
  handleNavigationPrevScreen: () => null,
  handleUnblockNavigation: () => null,
  style: {},
};

const ResultsTemplate = withResponsiveUtils(ResultsPage);

const OtherRecommendationsSection = withResponsiveUtils(
  ({
    alsoLikeConfig,
    mayAlsoLikeRef,
    shopProductBtnText,
    shopProductBtnBorderColor,
    shopProductBtnBackgroundColor,
    shopProductBtnTextColor,
    isDesktop,
    mobileResponsiveUtil,
    webResponsiveUtil,
    desktopBottomSpace,
    mobileBottomSpace,
  }) => {
    const { title, show, recommendableProducts = [] } = alsoLikeConfig;

    if (!show) {
      return null;
    }

    return (
      <OtherRecommendationsContainer
        ref={mayAlsoLikeRef}
        isDesktop={isDesktop}
        mobileResponsiveUtil={mobileResponsiveUtil}
        webResponsiveUtil={webResponsiveUtil}
        extraBottomSpace={isDesktop ? desktopBottomSpace : mobileBottomSpace}
        {...alsoLikeConfig}
      >
        <h2 className="title">{title}</h2>
        <div className="other-products-container">
          {recommendableProducts.map((product) => {
            const { productUuid, productData } = product;
            const fullProduct = {
              ...product,
              productData: { ...productData, showWeightedBars: false },
            };
            return (
              <ProductCard
                productUuid={productUuid}
                key={`other-recommendations-${productUuid}`}
                className="other-products-product-card"
                titleColor="white"
                bgColor="#447355"
                trackingActionName="Quiz: You May Also Like"
                shopProductBtnText={shopProductBtnText}
                shopProductBtnTextColor={shopProductBtnTextColor}
                shopProductBtnBackgroundColor={shopProductBtnBackgroundColor}
                shopProductBtnBorderColor={shopProductBtnBorderColor}
                {...fullProduct}
              />
            );
          })}
        </div>
      </OtherRecommendationsContainer>
    );
  },
);

OtherRecommendationsSection.propTypes = {
  alsoLikeConfig: shape({
    title: PropTypes.string,
    show: PropTypes.bool,
    recommendableProducts: PropTypes.arrayOf(Object),
  }).isRequired,
  mayAlsoLikeRef: shape({}).isRequired,
  shopProductBtnText: PropTypes.string.isRequired,
  extraBottomSpace: PropTypes.number,
  mobileBottomSpace: PropTypes.number,
};

OtherRecommendationsSection.defaultProps = {
  extraBottomSpace: 0,
  mobileBottomSpace: 0,
};

const Container = withResponsiveUtils(
  animated(styled.div`
    &&& {
      background-image: ${({ bgValue }) => `url(${bgValue})`};
      background-color: ${({ bgColor }) => bgColor || '#518A70'};
      background-size: 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      overflow-y: ${({ stopScrolling }) => (stopScrolling ? 'hidden' : 'auto')};
      height: ${({ stopScrolling }) => (stopScrolling ? '600px' : 'auto')};
      width: 100%;
      z-index: 1;

      .artifacts-container {
        display: ${({ isDesktop }) => (isDesktop ? 'none' : 'initial')};
        contain: content;
      }

      .results__title,
      .results__text,
      .recommendation-container {
        color: ${({ titleColor }) => titleColor || 'white'};
        font-family: ${fontVariables.families.primaryFont};
      }

      .results__title,
      .recommendation__title {
        white-space: per-wrap;
        width: 100%;

        font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(58)
            : mobileResponsiveUtil.getWidthValue(26)}px;
        font-weight: ${fontVariables.weights.bold};
        margin-top: ${({
          isDesktop,
          webResponsiveUtil,
          mobileResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(140)
            : mobileResponsiveUtil.getWidthValue(40)}px;
        margin-bottom: ${({
          isDesktop,
          webResponsiveUtil,
          mobileResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(30)
            : mobileResponsiveUtil.getWidthValue(10)}px;
      }

      .results__title {
        white-space: ${({ isDesktop }) => (isDesktop ? 'pre-wrap' : 'normal')};
      }
    }
  `),
);

const ResultsText = withResponsiveUtils(styled.p`
  &&& {
    font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(32)
        : mobileResponsiveUtil.getWidthValue(18)}px;
    margin: 0 auto;
    width: ${({ isDesktop }) => (isDesktop ? 52 : 30)}ch;
    line-height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(38)
        : mobileResponsiveUtil.getWidthValue(20)}px;
    font-weight: ${fontVariables.weights.normal};
    white-space: ${({ isDesktop }) => (isDesktop ? 'normal' : 'pre-wrap')};

    ::first-line {
      font-weight: ${({ isDesktop }) =>
        isDesktop ? fontVariables.weights.normal : fontVariables.weights.bold};
    }
  }
`);

const Divider = withResponsiveUtils(styled.hr`
  &&& {
    color: white;
    background-color: white;
    border-style: none;
    margin: ${({ isDesktop, webResponsiveUtil }) =>
        isDesktop ? webResponsiveUtil.getWidthValue(70) : 0}px
      auto;
    margin-bottom: 0;
    width: 240px; // same for desk and mob
    min-height: 1px;
  }
`);

const RecommendationContainer = withResponsiveUtils(styled.div`
  &&& {
    width: ${({ isDesktop }) => (isDesktop ? 'auto' : '100%')};
    margin-bottom: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(90)
        : mobileResponsiveUtil.getWidthValue(45)}px;
    .recommendation__title {
      margin-top: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(80)
          : mobileResponsiveUtil.getWidthValue(35)}px;
      margin-bottom: ${({
        isDesktop,
        webResponsiveUtil,
        mobileResponsiveUtil,
      }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(65)
          : mobileResponsiveUtil.getWidthValue(25)}px;
    }

    .products-card-container {
      min-height: ${({
        isDesktop,
        webResponsiveUtil,
        mobileResponsiveUtil,
        showWeightedBars,
      }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(showWeightedBars ? 826 : 650)
          : mobileResponsiveUtil.getWidthValue(showWeightedBars ? 540 : 400)}px;

      display: flex;
      align-items: stretch;
      justify-content: ${({ isDesktop }) =>
        isDesktop ? 'center' : 'space-between'};
      overflow-x: ${({ isDesktop }) => (isDesktop ? 'unset' : 'scroll')};

      .product-card {
        height: unset;
        margin: 0
          ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(25)
              : mobileResponsiveUtil.getWidthValue(7)}px;
        width: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(351)
            : mobileResponsiveUtil.getWidthValue(143)}px;
        padding-top: ${({ isDesktop, webResponsiveUtil }) =>
          isDesktop ? webResponsiveUtil.getWidthValue(29) : 2}px;

        :first-child {
          margin-left: ${({ isDesktop, mobileResponsiveUtil }) =>
            isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(21)}px;
        }

        :last-child {
          margin-right: ${({ isDesktop, mobileResponsiveUtil }) =>
            isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(21)}px;
        }

        .product-image-container {
          .product__image {
            width: ${({ isDesktop }) => (isDesktop ? 'auto' : '100%')};
            height: ${({
              isDesktop,
              webResponsiveUtil,
              mobileResponsiveUtil,
            }) =>
              isDesktop
                ? webResponsiveUtil.getWidthValue(200)
                : mobileResponsiveUtil.getWidthValue(135)}px;
          }
        }

        .product__name {
          padding: 0 3px;
        }
      }
    }
  }
`);

const CompareCtaContainer = withResponsiveUtils(styled.div`
  &&& {
    font-family: ${fontVariables.families.primaryFont};
    margin: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(90)
          : mobileResponsiveUtil.getWidthValue(45)}px
      auto;
    margin-top: 0;

    background-color: transparent;
    border: 1px solid ${({ textColor }) => textColor || 'white'};
    border-radius: 50px;
    width: fit-content;
    display: flex;
    min-height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(62)
        : mobileResponsiveUtil.getWidthValue(35)}px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0
      ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(50)
          : mobileResponsiveUtil.getWidthValue(25)}px;
    padding-top: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(3)
        : mobileResponsiveUtil.getWidthValue(3)}px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;

    a,
    a:visited,
    a:active {
      font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(26)
          : mobileResponsiveUtil.getWidthValue(17)}px;
      color: ${({ color }) => color || 'white'};
      font-weight: ${fontVariables.weights.semibold}; // semi bold
      text-decoration: none;
    }

    :hover {
      color: ${({ hoverConfig }) => hoverConfig?.textColor || 'white'};
      border-color: ${({ hoverConfig }) =>
        hoverConfig?.borderColor || 'transparent'};
      background-color: ${({ hoverConfig }) =>
        hoverConfig?.bgColor || 'transparent'};
    }
  }
`);

const OtherRecommendationsContainer = styled.div`
  &&& {
    height: 100%;
    width: 100%;
    background: ${({ bgColor }) => bgColor || '#dce9d5'};
    padding-bottom: ${({
      extraBottomSpace,
      isDesktop,
      webResponsiveUtil,
      mobileResponsiveUtil,
    }) =>
      isDesktop
        ? `${webResponsiveUtil.getWidthValue(extraBottomSpace)}px`
        : `${mobileResponsiveUtil.getWidthValue(extraBottomSpace)}px`};

    .title {
      color: ${({ titleColor }) => titleColor || '#447355'};
      margin-top: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(98)
          : mobileResponsiveUtil.getWidthValue(60)}px;
      margin-bottom: ${({
        isDesktop,
        mobileResponsiveUtil,
        webResponsiveUtil,
      }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(60)
          : mobileResponsiveUtil.getWidthValue(25)}px;
      font-weight: ${fontVariables.weights.bold};
      font-size: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(48)
          : mobileResponsiveUtil.getWidthValue(26)}px;
    }

    .other-products-container {
      display: flex;
      justify-content: ${({ isDesktop }) =>
        isDesktop ? 'center' : 'flex-start'};
      overflow-x: ${({ isDesktop }) => (isDesktop ? 'unset' : 'scroll')};
      min-height: ${({ isDesktop, webResponsiveUtil }) =>
        isDesktop ? `${webResponsiveUtil.getWidthValue(550)}px` : 'unset'};

      .other-products-product-card {
        height: auto;
        margin: 0
          ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(23)
              : mobileResponsiveUtil.getWidthValue(7)}px;
        width: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(300)
            : mobileResponsiveUtil.getWidthValue(143)}px;
        padding-bottom: ${({
          isDesktop,
          mobileResponsiveUtil,
          webResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(20)
            : mobileResponsiveUtil.getWidthValue(15)}px;

        margin-bottom: ${({
          isDesktop,
          mobileResponsiveUtil,
          webResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(205)
            : mobileResponsiveUtil.getWidthValue(80)}px;

        font-size: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(23)
            : mobileResponsiveUtil.getWidthValue(16)}px;

        :first-child {
          margin-left: ${({ isDesktop, mobileResponsiveUtil }) =>
            isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(21)}px;
        }

        :last-child {
          margin-right: ${({ isDesktop, mobileResponsiveUtil }) =>
            isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(21)}px;
        }

        .product-image-container {
          height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(250)
              : mobileResponsiveUtil.getWidthValue(143)}px;
          width: ${({ isDesktop, webResponsiveUtil }) =>
            isDesktop ? `${webResponsiveUtil.getWidthValue(250)}px` : '100%'};
          svg,
          img {
            height: auto;
            max-width: 100%;
            min-width: ${({
              isDesktop,
              webResponsiveUtil,
              mobileResponsiveUtil,
            }) =>
              isDesktop
                ? webResponsiveUtil.getWidthValue(250)
                : mobileResponsiveUtil.getWidthValue(143)}px;
          }
        }

        .product__name {
          width: ${({ isDesktop }) => (isDesktop ? '16ch' : 'auto')};
          margin-bottom: ${({
            isDesktop,
            webResponsiveUtil,
            mobileResponsiveUtil,
          }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(21)
              : mobileResponsiveUtil.getWidthValue(20)}px;
        }

        .product__desc {
          width: ${({ isDesktop }) => (isDesktop ? '23ch' : 'auto')};
        }

        .weightedbars-container {
          margin-bottom: ${({ isDesktop, webResponsiveUtil }) =>
            isDesktop ? webResponsiveUtil.getWidthValue(30) : 0}px;
        }
      }
    }
  }
`;

export default ResultsTemplate;
