import React from 'react';
import PropTypes from 'prop-types';

const Input = ({ type = 'text', name, label }) => (
  <div className="input-component">
    <div className="input-container">
      <input id={name} type={type} name={name} />
    </div>
    {label && (
      <div className="label-container">
        <label htmlFor={name}>{label}</label>
      </div>
    )}
  </div>
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
  label: '',
};

export default Input;
