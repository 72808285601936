import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import { fetchPageData, fetchLocalPageData } from '../api';
import { QUIZ_EXPERIENCE_ID } from '../utils/constants';

const fetchQuizData = createAsyncThunk('quiz/fetchTemplates', async () => {
  const { apiUrl, quizId } =
    document.getElementById(QUIZ_EXPERIENCE_ID).dataset;

  const { data } = await fetchPageData(apiUrl, quizId);
  // just to use local mockedResponse.json data
  // const { data } = await fetchLocalPageData();

  // the data comes in Map of key(templateID), value(templateData)
  // but we need an array of templatesData
  return data;
});

export default fetchQuizData;
