import { fetchResultsCopy } from '../api';
import TEMPLATE_TYPES from './enums/templateTypes';
import normalizeLineBreaks from './normalizeLineBreaks';

/* eslint-disable no-unused-vars */
const DAY_TO_DAY_ANSWERS = {
  'always-on-the-go': 'busy',
  'juggling-a-full-house': 'bustling',
  'cleaning-up-after-pets': 'devoted',
  'looking-for-an-extra-hand': 'laidback',
};

const PREFERS_ANSWERS = {
  robotDoitAll:
    'your robot handles the cleaning so you can focus on more important things',
  myself: 'your robot pitches in to help you clean',
};

const FLOORS_ANSWERS = {
  bigOnCarpeting: 'carpets',
  bestBothWorlds: 'carpets and hard floors',
  hardFloors: 'hardwood floors',
};

export const WEIGHT_CONFIG_NAMES = {
  minWeights: 'minWeights',
  midWeights: 'midWeights',
  maxWeights: 'maxWeights',
};

const CYCLE_ANSWERS = {
  [WEIGHT_CONFIG_NAMES.minWeights]: 'cleaned every so often',
  [WEIGHT_CONFIG_NAMES.midWeights]: 'cleaned daily',
  [WEIGHT_CONFIG_NAMES.maxWeights]: 'cleaned thoroughly',
};

function getSelectedOptionsByTemplate({
  answers,
  templateType,
  multipleAns = false,
}) {
  let answer = [];

  Object.keys(answers).forEach((key) => {
    const opt = answers[key];

    if (opt.templateType === templateType) {
      if (multipleAns) {
        answer.push(opt.selectedOptions);
      } else {
        answer = opt.selectedOptions;
      }
    }
  });

  return answer;
}

function getCleaningCycleWords(answers) {
  const [cycleValue, seasonValue] = getSelectedOptionsByTemplate({
    answers,
    templateType: TEMPLATE_TYPES.SLIDER,
    multipleAns: true,
  });

  const cycleWords = CYCLE_ANSWERS[cycleValue];

  const seasonWords =
    seasonValue === WEIGHT_CONFIG_NAMES.minWeights
      ? 'don’t want seasonal cleaning'
      : 'want your cleaning to match the seasons';

  return [cycleWords, seasonWords];
}

function getFloorWord(answers) {
  const [, floorSelection] = getSelectedOptionsByTemplate({
    answers,
    templateType: TEMPLATE_TYPES.RADIO_ICON,
    multipleAns: true,
  });

  if (!FLOORS_ANSWERS[floorSelection]) {
    // eslint-disable-next-line no-console
    console.error('Error trying to get the result text for floorSelection');
    return '';
  }

  return FLOORS_ANSWERS[floorSelection];
}

function getPrefersCopy(answers) {
  const userSelection = getSelectedOptionsByTemplate({
    answers,
    templateType: TEMPLATE_TYPES.TWO_ANSWER_ILLUSTRATION,
  });

  if (!PREFERS_ANSWERS[userSelection]) {
    // eslint-disable-next-line no-console
    console.error('Error trying to get the result text for prefersSelection');
    return '';
  }

  return PREFERS_ANSWERS[userSelection];
}

function getPersonaWord(answers) {
  const userSelection = getSelectedOptionsByTemplate({
    answers,
    templateType: TEMPLATE_TYPES.MULTIPLE_CHOICE,
  });

  let personaWord = 'person'; // person by default || if the Q is skipped
  const optsSelected = userSelection.length;

  const petsSelected =
    userSelection.includes('pets') || userSelection.includes('shedding-pets');
  const adultsSelected = userSelection.includes('adults');
  const kidsSelected = userSelection.includes('kids');

  if (
    (petsSelected && optsSelected === 1) ||
    (adultsSelected && petsSelected)
  ) {
    personaWord = 'pet parent';
  } else if (adultsSelected && optsSelected === 1) {
    personaWord = 'person';
  } else if (
    (kidsSelected && optsSelected === 1) ||
    (kidsSelected && petsSelected) ||
    optsSelected >= 3
  ) {
    personaWord = 'parent';
  } else if (kidsSelected && adultsSelected) {
    personaWord = 'family member';
  }

  return personaWord;
}

function getDayToDayWord(answers) {
  const userSelection = getSelectedOptionsByTemplate({
    answers,
    templateType: TEMPLATE_TYPES.FOUR_ANSWER_ILLUSTRATION,
  });

  if (!DAY_TO_DAY_ANSWERS[userSelection]) {
    // eslint-disable-next-line no-console
    console.error('Error trying to get the result text for dayToDaySelection');
    return '';
  }

  return DAY_TO_DAY_ANSWERS[userSelection];
}

/**
 *
 * @param {object} answers
 * @returns the results copy texts
 */
async function getResultsCopy(answers, text) {
  let textCopy = text;
  Object.values(answers).forEach((answer) => {
    const textToChange = `[[${answer.questionPosition + 1}]]`;
    textCopy = textCopy.replace(textToChange, answer.summaryText);
  });

  let resultsCopy = '';
  resultsCopy = normalizeLineBreaks(textCopy);

  try {
    const response = await fetchResultsCopy(resultsCopy);
    if (response?.data) {
      resultsCopy = response.data?.translated_response;
    }
  } catch (error) {
    console.error('Error fetching translated response copy');
    return resultsCopy;
  }
  return resultsCopy;
}

export default getResultsCopy;
