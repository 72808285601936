import DIRECTIONS from './directions';

export const BACKGROUND_TYPES = {
  FADE: 'FADE',
  CIRCLE: 'CIRCLE',
  NONE: 'NONE',
  SKIN: 'SKIN',
};

export const SCREENS_BG_ANI = {
  QuizStartTemplate: {
    mobile: {
      type: BACKGROUND_TYPES.NONE,
      movingFrom: DIRECTIONS.TOP,
    },
    desktop: {
      type: BACKGROUND_TYPES.NONE,
      movingFrom: DIRECTIONS.TOP,
    },
  },
  KickoffQuestionTemplate: {
    mobile: {
      type: BACKGROUND_TYPES.NONE,
      movingFrom: DIRECTIONS.BOTTOM,
    },
    desktop: {
      type: BACKGROUND_TYPES.NONE,
      movingFrom: DIRECTIONS.BOTTOM_RIGHT,
    },
  },
  // whatDoYouOwn: {
  //   mobile: {
  //     type: BACKGROUND_TYPES.NONE,
  //     movingFrom: '',
  //   },
  //   desktop: {
  //     type: BACKGROUND_TYPES.NONE,
  //     movingFrom: '',
  //   },
  // },
  // whyBack: {
  //   mobile: {
  //     type: BACKGROUND_TYPES.NONE,
  //     movingFrom: '',
  //   },
  //   desktop: {
  //     type: BACKGROUND_TYPES.NONE,
  //     movingFrom: '',
  //   },
  // },
  FourAnswerIllustrationQuestionTemplate: {
    mobile: {
      type: BACKGROUND_TYPES.CIRCLE,
      movingFrom: DIRECTIONS.TOP,
    },
    desktop: {
      type: BACKGROUND_TYPES.CIRCLE,
      movingFrom: DIRECTIONS.TOP_LEFT,
    },
  },
  SliderQuestionTemplate: {
    mobile: {
      type: BACKGROUND_TYPES.CIRCLE,
      movingFrom: DIRECTIONS.LEFT,
    },
    desktop: {
      type: BACKGROUND_TYPES.CIRCLE,
      movingFrom: DIRECTIONS.TOP_LEFT,
    },
  },
  TwoAnswerIllustrationTemplate: {
    mobile: {
      type: BACKGROUND_TYPES.CIRCLE,
      movingFrom: DIRECTIONS.BOTTOM,
    },
    desktop: {
      type: BACKGROUND_TYPES.CIRCLE,
      movingFrom: DIRECTIONS.TOP_LEFT,
    },
  },
  YesOrNoTemplate: {
    mobile: {
      type: BACKGROUND_TYPES.CIRCLE,
      movingFrom: DIRECTIONS.BOTTOM_RIGHT,
    },
    desktop: {
      type: BACKGROUND_TYPES.NONE,
      movingFrom: '',
    },
  },
  // yesOrNo2: {
  //   mobile: {
  //     type: BACKGROUND_TYPES.NONE,
  //     movingFrom: '',
  //   },
  //   desktop: {
  //     type: BACKGROUND_TYPES.CIRCLE,
  //     movingFrom: DIRECTIONS.TOP_LEFT,
  //   },
  // },
  // yesOrNo3: {
  //   mobile: {
  //     type: BACKGROUND_TYPES.NONE,
  //     movingFrom: '',
  //   },
  //   desktop: {
  //     type: BACKGROUND_TYPES.CIRCLE,
  //     movingFrom: DIRECTIONS.TOP_LEFT,
  //   },
  // },
  // yesOrNo4: {
  //   mobile: {
  //     type: BACKGROUND_TYPES.NONE,
  //     movingFrom: '',
  //   },
  //   desktop: {
  //     type: BACKGROUND_TYPES.CIRCLE,
  //     movingFrom: DIRECTIONS.TOP_LEFT,
  //   },
  // },
  // yesOrNo5: {
  //   mobile: {
  //     type: BACKGROUND_TYPES.NONE,
  //     movingFrom: '',
  //   },
  //   desktop: {
  //     type: BACKGROUND_TYPES.CIRCLE,
  //     movingFrom: DIRECTIONS.TOP_LEFT,
  //   },
  // },
  // yesOrNo6: {
  //   mobile: {
  //     type: BACKGROUND_TYPES.NONE,
  //     movingFrom: '',
  //   },
  //   desktop: {
  //     type: BACKGROUND_TYPES.CIRCLE,
  //     movingFrom: DIRECTIONS.TOP_LEFT,
  //   },
  // },
  MultipleChoiceQuestionTemplate: {
    mobile: {
      type: BACKGROUND_TYPES.NONE,
      movingFrom: '',
    },
    desktop: {
      type: BACKGROUND_TYPES.CIRCLE,
      movingFrom: DIRECTIONS.TOP_LEFT,
    },
  },
  // allergens: {
  //   mobile: {
  //     type: BACKGROUND_TYPES.CIRCLE,
  //     movingFrom: DIRECTIONS.BOTTOM_LEFT,
  //   },
  //   desktop: {
  //     type: BACKGROUND_TYPES.CIRCLE,
  //     movingFrom: DIRECTIONS.TOP_LEFT,
  //   },
  // },
  RadioIconQuestionTemplate: {
    mobile: {
      type: BACKGROUND_TYPES.CIRCLE,
      movingFrom: DIRECTIONS.TOP_RIGHT,
    },
    desktop: {
      type: BACKGROUND_TYPES.CIRCLE,
      movingFrom: DIRECTIONS.TOP_LEFT,
    },
  },
  // cleaningFloors: {
  //   mobile: {
  //     type: BACKGROUND_TYPES.CIRCLE,
  //     movingFrom: DIRECTIONS.BOTTOM_RIGHT,
  //   },
  //   desktop: {
  //     type: BACKGROUND_TYPES.CIRCLE,
  //     movingFrom: DIRECTIONS.TOP_LEFT,
  //   },
  // },
  SimpleQuestionTemplate: {
    mobile: {
      type: BACKGROUND_TYPES.CIRCLE,
      movingFrom: DIRECTIONS.TOP_RIGHT,
    },
    desktop: {
      type: BACKGROUND_TYPES.CIRCLE,
      movingFrom: DIRECTIONS.TOP_LEFT,
    },
  },
  BudgetTemplate: {
    mobile: {
      type: BACKGROUND_TYPES.CIRCLE,
      movingFrom: DIRECTIONS.BOTTOM_RIGHT,
    },
    desktop: {
      type: BACKGROUND_TYPES.CIRCLE,
      movingFrom: DIRECTIONS.TOP_LEFT,
    },
  },
  // since result template its different from other templates, the bgComponent
  // doesn't work as expected. The bgSkin/color is handled in his template.
  ResultsTemplate: {
    mobile: {
      type: BACKGROUND_TYPES.NONE,
      movingFrom: '',
    },
    desktop: {
      type: BACKGROUND_TYPES.NONE,
      movingFrom: '',
    },
  },
};
