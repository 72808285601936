import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontVariables } from '../styles/variables';

import {
  withResponsiveUtils,
  isMobile,
} from '../utils/responsive/ResponsiveUtils';
import { NoImageSVG } from '../assets/svgs';
import ProgressBar from './ProgressBar';
import Image from './Image';
import { WEIGHTS_BASE_NUMBER } from '../utils/constants';
import normalizeLineBreaks from '../utils/normalizeLineBreaks';
import { trackShopLinkClick } from '../utils/analytics';

const ProductCard = ({
  productData,
  weightedBars,
  titleColor,
  bgColor,
  className,
  productUuid,
  shopProductBtnText,
  shopProductBtnTextColor,
  shopProductBtnBackgroundColor,
  shopProductBtnBorderColor,
  currencyCode,
  trackingActionName,
  sku,
}) => {
  const {
    name,
    imgSrc,
    description,
    showPrice = true,
    price,
    showWeightedBars,
    features,
  } = productData;

  const productDescription =
    features && features?.length ? features.join('. ') : description;

  const isDesktop = !isMobile();
  return (
    <ProductContainer
      {...{
        isDesktop,
        titleColor,
        bgColor,
        className,
      }}
    >
      <div className="product-details">
        <ProductImage src={imgSrc} altText={name} />
        <h3 className="product__name">{name}</h3>

        <p className="product__desc">
          {normalizeLineBreaks(productDescription)}
        </p>
        {showPrice ? <p className="product__price">${price}</p> : null}
      </div>
      {/* Weighted bars: Shown weights are determined by the values on the weightedBars
      value that every product has on the product pool and here will only appear those
      questions that has the “Display in results” flag turned on. */}

      <WeightedBarsContainer className="weightedbars-container">
        <ShopCta
          productUuid={productUuid}
          currencyCode={currencyCode}
          trackingActionName={trackingActionName}
          shopProductBtnText={shopProductBtnText}
          shopProductBtnTextColor={shopProductBtnTextColor}
          shopProductBtnBackgroundColor={shopProductBtnBackgroundColor}
          shopProductBtnBorderColor={shopProductBtnBorderColor}
          fullProduct={{
            productData,
            productUuid,
            sku,
          }}
        />
        {showWeightedBars && weightedBars.length
          ? weightedBars.map(({ iconType, iconAlt, affinityValue }) => {
              const iconKey = `${name}-${iconType}`;
              return (
                <div key={iconKey} className="stats-container">
                  <div className="icon-container">
                    <img src={iconType} alt={iconAlt} />
                  </div>
                  <ProgressBar
                    color={bgColor}
                    value={affinityValue * WEIGHTS_BASE_NUMBER}
                  />
                </div>
              );
            })
          : null}
      </WeightedBarsContainer>
    </ProductContainer>
  );
};

ProductCard.propTypes = {
  productUuid: PropTypes.string.isRequired,
  productData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    imgSrc: PropTypes.string,
    description: PropTypes.string.isRequired,
    shopLink: PropTypes.string,
    showPrice: PropTypes.bool.isRequired,
    price: PropTypes.string,
    showWeightedBars: PropTypes.bool,
    features: PropTypes.arrayOf(PropTypes.string),
  }),
  weightedBars: PropTypes.arrayOf(
    PropTypes.shape({
      iconType: PropTypes.string.isRequired,
      iconAlt: PropTypes.string.isRequired,
      affinityValue: PropTypes.number.isRequired,
    }),
  ),
  titleColor: PropTypes.string,
  bgColor: PropTypes.string,
  className: PropTypes.string,
  shopProductBtnText: PropTypes.string,
  currencyCode: PropTypes.string,
  trackingActionName: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  shopProductBtnTextColor: PropTypes.string,
  shopProductBtnBackgroundColor: PropTypes.string,
  shopProductBtnBorderColor: PropTypes.string,
};

ProductCard.defaultProps = {
  productData: {},
  weightedBars: [],
  titleColor: '#000000',
  bgColor: '#FFFFFF',
  className: 'product-card',
  shopProductBtnText: '',
  currencyCode: '',
  shopProductBtnTextColor: '',
  shopProductBtnBackgroundColor: '',
  shopProductBtnBorderColor: '',
};

const ProductImage = ({ src, altText }) => (
  <div className="product-image-container">
    {src ? (
      <Image
        className="product__image"
        src={src}
        alt={altText || 'iRobot product image'}
      />
    ) : (
      <NoImageSVG className="product__no-image" alt="no image available" />
    )}
  </div>
);

ProductImage.propTypes = {
  src: PropTypes.string,
  altText: PropTypes.string,
};

ProductImage.defaultProps = {
  src: '',
  altText: '',
};

const ShopCta = ({
  shopProductBtnText,
  shopProductBtnTextColor,
  shopProductBtnBackgroundColor,
  shopProductBtnBorderColor,
  currencyCode,
  trackingActionName,
  fullProduct,
}) => {
  const { productData } = fullProduct;

  if (!productData.shopLink) {
    return null;
  }

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    trackShopLinkClick({
      productData: fullProduct,
      currencyCode,
      trackingActionName,
    });

    if (productData.shopLink) {
      window.open(productData.shopLink, '_black');
    }
  };

  return (
    <ShopCtaContainer
      className="shop-cta-container"
      type="button"
      onClick={handleClick}
      shopProductBtnTextColor={shopProductBtnTextColor}
      shopProductBtnBackgroundColor={shopProductBtnBackgroundColor}
      shopProductBtnBorderColor={shopProductBtnBorderColor}
    >
      {shopProductBtnText || 'Shop'}
    </ShopCtaContainer>
  );
};

ShopCta.propTypes = {
  shopProductBtnText: PropTypes.string,
  currencyCode: PropTypes.string,
  trackingActionName: PropTypes.string.isRequired,
  shopProductBtnTextColor: PropTypes.string,
  shopProductBtnBackgroundColor: PropTypes.string,
  shopProductBtnBorderColor: PropTypes.string,
  fullProduct: PropTypes.shape({
    productUuid: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
    productData: ProductCard.propTypes.productData,
  }).isRequired,
};

ShopCta.defaultProps = {
  shopProductBtnText: '',
  currencyCode: '',
  shopProductBtnTextColor: '',
  shopProductBtnBackgroundColor: '',
  shopProductBtnBorderColor: '',
};

const ProductContainer = withResponsiveUtils(styled.div`
  &&& {
    align-items: center;
    background-color: white;
    color: #6a768c;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    box-sizing: content-box;
    padding: ${({ isDesktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      isDesktop
        ? `${webResponsiveUtil.getWidthValue(25)}px`
        : `${mobileResponsiveUtil.getWidthValue(10)}px`};

    .product-details {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .product-image-container {
      display: flex;
      align-items: center;

      svg {
        height: auto;
        width: ${({ isDesktop, webResponsiveUtil }) =>
          isDesktop ? `${webResponsiveUtil.getWidthValue(197)}px` : '100%'};
      }
    }

    .product__name {
      font-family: ${fontVariables.families.secondaryFont};
      font-weight: ${fontVariables.weights.bold};
      font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(25)
          : mobileResponsiveUtil.getWidthValue(16)}px;
      margin-top: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(21)
          : mobileResponsiveUtil.getWidthValue(20)}px;
      margin-bottom: ${({
        isDesktop,
        webResponsiveUtil,
        mobileResponsiveUtil,
      }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(8)
          : mobileResponsiveUtil.getWidthValue(8)}px;
      height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(42)
          : mobileResponsiveUtil.getWidthValue(42)}px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .product__desc {
      white-space: pre-wrap;
      font-family: ${fontVariables.families.primaryFont};
      font-weight: ${fontVariables.weights.normal};
      width: ${({ isDesktop }) => (isDesktop ? 20 : 15)}ch;
      font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(25)
          : mobileResponsiveUtil.getWidthValue(16)}px;
    }

    .shop-cta-container {
      width: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(160)
          : mobileResponsiveUtil.getWidthValue(100)}px;
      height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(70)
          : mobileResponsiveUtil.getWidthValue(45)}px;
      padding-top: 2px;
      margin: 0 auto
        ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(10)
            : mobileResponsiveUtil.getWidthValue(15)}px;

      font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(26)
          : mobileResponsiveUtil.getWidthValue(17)}px;
      font-weight: ${fontVariables.weights.semibold}; // semi bold
      line-height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(35)
          : mobileResponsiveUtil.getWidthValue(20)}px;
    }

    .product__price {
      font-family: ${fontVariables.families.secondaryFont};
      font-weight: ${fontVariables.weights.bold};
      font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(20)
          : mobileResponsiveUtil.getWidthValue(16)}px;
    }

    .weightedbars-container {
      margin-top: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(26)
          : mobileResponsiveUtil.getWidthValue(25)}px;
      margin-bottom: ${({
        isDesktop,
        webResponsiveUtil,
        mobileResponsiveUtil,
      }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(52)
          : mobileResponsiveUtil.getWidthValue(20)}px;
    }

    .stats-container {
      .icon-container {
        min-width: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(85)
            : mobileResponsiveUtil.getWidthValue(56)}px;

        img {
          height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(58)
              : mobileResponsiveUtil.getWidthValue(37)}px;
          max-width: ${({
            isDesktop,
            webResponsiveUtil,
            mobileResponsiveUtil,
          }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(58)
              : mobileResponsiveUtil.getWidthValue(37)}px;
        }
      }

      .progress-bar {
        background-color: #dce9d5;
        border: 0;
        margin: 0;
        margin-right: ${({
          isDesktop,
          webResponsiveUtil,
          mobileResponsiveUtil,
        }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(30)
            : mobileResponsiveUtil.getWidthValue(10)}px;
        height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(10)
            : mobileResponsiveUtil.getWidthValue(8)}px;

        .value {
          height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(10)
              : mobileResponsiveUtil.getWidthValue(8)}px;
          top: 0;
        }
      }
    }
  }
`);

const ShopCtaContainer = styled.button`
  &&& {
    align-items: center;
    background-color: ${({ shopProductBtnBackgroundColor }) =>
      shopProductBtnBackgroundColor};
    border: ${({ shopProductBtnBorderColor }) =>
      `1px solid ${shopProductBtnBorderColor}`};
    border-radius: 50px;
    color: ${({ shopProductBtnTextColor }) => shopProductBtnTextColor};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    float: none;
    justify-content: center;
  }
`;

const WeightedBarsContainer = styled.div`
  &&& {
    width: 100%;

    .stats-container {
      display: flex;
      align-items: center;
    }
  }
`;

export default ProductCard;
