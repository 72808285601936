import { createSlice } from '@reduxjs/toolkit';

import fetchQuizData from './viewThunks';
import getNextPageIDX from '../utils/getNextIDX';
import { trackBacktracking, trackSelectedAnswer } from '../utils/analytics';

const STARTING_IDX = 0; // for dev screen fast-forward

function parseAnswer({ payload, currentScreenConfig }) {
  const {
    weights,
    selectedOptions,
    selectedText,
    selectedValue,
    questionPosition,
    summaryText,
  } = payload;
  const { pageID, templateType, title, deskTitle } = currentScreenConfig;
  return {
    pageID,
    weights,
    templateType,
    selectedOptions,
    title: (title || deskTitle)?.replaceAll('\\n', ' '),
    selectedText: selectedText?.replaceAll('\\n', ' '),
    selectedValue, // for slider template
    questionPosition,
    summaryText,
  };
}

const getAnswerWeight = (answerUuid, answersWithWeights) => {
  const foundAnswer = answersWithWeights.find(
    (ans) => ans.answer.uuid === answerUuid,
  );
  const answerWeights = foundAnswer.weights.map((weight) => {
    const parsedWeights = {
      uuid: weight.product_uuid,
      value: weight.weight,
      disqualify: weight.disqualify,
    };
    return parsedWeights;
  });
  return answerWeights;
};

const getAnswerSummary = (answerUuid, answersWithWeights) => {
  const foundAnswer = answersWithWeights.find(
    (ans) => ans.answer.uuid === answerUuid,
  );
  return foundAnswer.answer.summary;
};

const getSkipWeights = (answers, answersWithWeights) => {
  const skipAnswer = answers.find((answer) => answer.position === 3);
  return getAnswerWeight(skipAnswer.uuid, answersWithWeights);
};

const getBranchingQuestion = (answerUuid, branchingAnswers) => {
  const foundAnswer = branchingAnswers.find(
    (ans) => ans.quiz_answer.uuid === answerUuid,
  );
  if (foundAnswer?.branching_question) {
    return foundAnswer.branching_question.quiz_question.uuid;
  }
  return null;
};

const getAditionalLink = (aditionalUuid, aditionalLink) => {
  const foundAnswer = aditionalLink.find(
    (ad) => ad.quiz_additional_product.uuid === aditionalUuid,
  );
  return foundAnswer?.url;
};

const lottieAnimations = {
  ROOM: 'https://assets1.lottiefiles.com/packages/lf20_irwdqyqi.json',
  DUSTY_BUNNY: 'https://assets1.lottiefiles.com/packages/lf20_x3xaf2x1.json',
};

const parseQuestion = (quizQuestion, partnerCurrency) => {
  const {
    quiz_question: question,
    answers_with_weights: answersWithWeights,
    answers: branchingAnswers,
  } = quizQuestion;
  let questionObject = {};
  switch (question.type) {
    case 'kickoff_question':
      questionObject = {
        pageID: question.uuid,
        templateType: 'KickoffQuestionTemplate',
        mobTitle: question.title,
        deskTitle: question.title,
        titleColor: question.title_text_color,
        bgColor: question.background_color,
        subTitle: question.multiple_options ? question.subtitle : '',
        optionsType: question.multiple_options ? 'multi' : 'cta',
        position: quizQuestion.position,
        options: question.answers.map((answer) => {
          const parsedAnswer = {
            text: answer.answer,
            value: answer.uuid,
            weights: getAnswerWeight(answer.uuid, answersWithWeights),
            summaryText: getAnswerSummary(answer.uuid, answersWithWeights),
            nextScreenID: getBranchingQuestion(answer.uuid, branchingAnswers),
          };
          return parsedAnswer;
        }),
        navConfig: question.multiple_options
          ? {
              nextText: question.next_button_copy,
            }
          : {},
      };
      break;
    case 'four_illustrations_question':
      questionObject = {
        pageID: question.uuid,
        templateType: 'FourAnswerIllustrationQuestionTemplate',
        mobTitle: question.title,
        deskTitle: question.title,
        titleColor: question.title_text_color,
        bgColor: question.background_color,
        position: quizQuestion.position,
        options: question.answers.map((answer) => {
          const parsedAnswer = {
            text: answer.answer,
            svgSrc: answer.image_url,
            altText: answer.alt_tag,
            value: answer.uuid,
            weights: getAnswerWeight(answer.uuid, answersWithWeights),
            summaryText: getAnswerSummary(answer.uuid, answersWithWeights),
            nextScreenID: getBranchingQuestion(answer.uuid, branchingAnswers),
          };
          return parsedAnswer;
        }),
      };
      break;
    case 'slider_question':
      questionObject = {
        pageID: question.uuid,
        templateType: 'SliderQuestionTemplate',
        title: question.title,
        titleColor: question.title_text_color,
        lottieAnimation: lottieAnimations[question.slider_image],
        bgColor: question.background_color,
        position: quizQuestion.position,
        options: question.answers.map((answer) => {
          const parsedAnswer = {
            text: answer.answer,
            weights: getAnswerWeight(answer.uuid, answersWithWeights),
            summaryText: getAnswerSummary(answer.uuid, answersWithWeights),
            nextScreenID: getBranchingQuestion(answer.uuid, branchingAnswers),
          };
          return parsedAnswer;
        }),
        navConfig: {
          nextText: question.next_button_copy || 'next',
        },
      };
      break;
    case 'two_illustrations_question':
      questionObject = {
        pageID: question.uuid,
        templateType: 'TwoAnswerIllustrationTemplate',
        title: question.title,
        titleColor: question.title_text_color,
        bgColor: question.background_color,
        position: quizQuestion.position,
        options: question.answers.map((answer) => {
          const parsedAnswer = {
            text: answer.answer,
            svgSrc: answer.image_url,
            altText: answer.alt_tag,
            value: answer.uuid,
            weights: getAnswerWeight(answer.uuid, answersWithWeights),
            summaryText: getAnswerSummary(answer.uuid, answersWithWeights),
            nextScreenID: getBranchingQuestion(answer.uuid, branchingAnswers),
          };
          return parsedAnswer;
        }),
      };
      break;
    case 'boolean_question':
      questionObject = {
        pageID: question.uuid,
        templateType: 'YesOrNoTemplate',
        deskTitle: question.title,
        subTitle: question.card_title,
        titleColor: question.title_text_color,
        bgColor: question.background_color,
        imgUrl: question.image_url,
        imgAlt: question.image_alt_tag,
        position: quizQuestion.position,
        options: question.answers
          .filter((answer) => {
            if (answer.position === 3) {
              return false;
            }
            return true;
          })
          .map((answer) => {
            const parsedAnswer = {
              value: answer.answer,
              weights: getAnswerWeight(answer.uuid, answersWithWeights),
              summaryText: getAnswerSummary(answer.uuid, answersWithWeights),
              nextScreenID: getBranchingQuestion(answer.uuid, branchingAnswers),
            };
            if (answer.position === 1) {
              parsedAnswer.icon = 'thumbsUp';
              parsedAnswer.altIcon = 'thumbs up';
            }
            if (answer.position === 2) {
              parsedAnswer.icon = 'thumbsDown';
              parsedAnswer.altIcon = 'thumbs down';
            }
            return parsedAnswer;
          }),
        skipWights: getSkipWeights(question.answers, answersWithWeights),
        navConfig: {
          nextText: question.answers[2].answer,
        },
      };
      break;
    case 'multiple_choice_question':
      questionObject = {
        pageID: question.uuid,
        templateType: 'MultipleChoiceQuestionTemplate',
        title: question.title,
        titleColor: question.title_text_color,
        subTitle: question.subtitle,
        bgColor: question.background_color,
        imgUrl: question.image_url,
        imgAlt: question.image_alt_tag,
        position: quizQuestion.position,
        answers: question.answers.map((answer) => {
          const parsedAnswer = {
            text: answer.answer,
            position: answer.position,
            value: answer.uuid,
            weights: getAnswerWeight(answer.uuid, answersWithWeights),
            summaryText: getAnswerSummary(answer.uuid, answersWithWeights),
            nextScreenID: getBranchingQuestion(answer.uuid, branchingAnswers),
          };
          return parsedAnswer;
        }),
        answersConfig: {
          textColor: question.title_text_color,
          outlineColor: question.title_text_color,
          hoverFillColor: question.title_text_color,
        },
        navConfig: {
          nextText: question.next_button_copy || 'next',
        },
      };
      break;
    case 'radio_icon_question':
      questionObject = {
        pageID: question.uuid,
        templateType: 'RadioIconQuestionTemplate',
        deskTitle: question.title,
        titleColor: question.title_text_color,
        bgColor: question.background_color,
        titleAniConfig: 'leftToRightConfig',
        position: quizQuestion.position,
        options: question.answers.map((answer, index) => {
          const parsedAnswer = {
            text: answer.answer,
            imgSrc: answer.image_url,
            altText: answer.image_alt_tag,
            value: answer.uuid,
            imgPosition: index % 2 === 0 ? 'RIGHT' : 'LEFT',
            weights: getAnswerWeight(answer.uuid, answersWithWeights),
            summaryText: getAnswerSummary(answer.uuid, answersWithWeights),
            nextScreenID: getBranchingQuestion(answer.uuid, branchingAnswers),
          };
          return parsedAnswer;
        }),
      };
      break;
    case 'simple_question':
      questionObject = {
        pageID: question.uuid,
        templateType: 'SimpleQuestionTemplate',
        title: question.title,
        bgColor: question.background_color,
        titleColor: question.title_text_color,
        position: quizQuestion.position,
        options: question.answers.map((answer) => {
          const parsedAnswer = {
            text: answer.answer,
            value: answer.uuid,
            imgSrc: answer.static_image_url,
            altText: answer.static_image_alt_tag,
            hoverImgSrc: answer.hover_image_url,
            hoverAltText: answer.hover_image_alt_tag,
            weights: getAnswerWeight(answer.uuid, answersWithWeights),
            summaryText: getAnswerSummary(answer.uuid, answersWithWeights),
            nextScreenID: getBranchingQuestion(answer.uuid, branchingAnswers),
          };
          return parsedAnswer;
        }),
        navConfig: {},
      };
      break;
    case 'three_illustrations_question':
      questionObject = {
        pageID: question.uuid,
        templateType: 'BudgetTemplate',
        title: question.title,
        titleColor: question.title_text_color,
        bgColor: question.background_color,
        position: quizQuestion.position,
        currencyToggle: question.currency_toggle,
        partnerCurrency,
        options: question.answers.map((answer) => {
          const parsedAnswer = {
            text: answer.answer,
            value: answer.uuid,
            src: answer.image_url,
            altText: answer.image_alt_tag,
            weights: getAnswerWeight(answer.uuid, answersWithWeights),
            summaryText: getAnswerSummary(answer.uuid, answersWithWeights),
            nextScreenID: getBranchingQuestion(answer.uuid, branchingAnswers),
          };
          return parsedAnswer;
        }),
      };
      break;
    default:
      questionObject = {};
  }
  return questionObject;
};

const parseData = (data) => {
  const { quiz } = data;
  const { questions, products } = quiz.quiz_entities[0];
  const parsedQuestions = questions.map((question) =>
    parseQuestion(question, data.partner.currency),
  );
  const initialData = [
    {
      pageID: 'quizStart',
      templateType: 'QuizStartTemplate',
      title: quiz.start_screen_title,
      titleColor: quiz.start_screen_title_text_color,
      subTitle: quiz.start_screen_subtitle,
      subTitleColor: quiz.start_screen_subtitle_text_color,
      deskBgSkin: quiz.start_screen_desktop_background_image_url,
      mobBgSkin: quiz.start_screen_mobile_background_image_url,
      bgColor: quiz.start_screen_background_color,
      buttonCopy: quiz.start_screen_button_copy,
      buttonColor: quiz.start_screen_button_text_color,
      buttonHoverColor: quiz.start_screen_button_text_hover_color,
      transitionText: quiz.start_screen_response_message,
      logoUrl: quiz.start_screen_logo_url,
      logoAlt: quiz.start_screen_alt_tag,
    },
  ];
  const resultsData = [
    {
      pageID: 'results',
      templateType: 'ResultsTemplate',
      title: quiz.results_screen_title,
      subTitle: quiz.results_screen_summary,
      titleColor: quiz.results_screen_title_color,
      bgColor: quiz.results_screen_background_color,
      deskBgSkin: quiz.results_screen_desktop_background_image_url,
      mobBgSkin: quiz.results_screen_mobile_background_image_url,
      recommendationTitle: quiz.results_screen_header,
      shopProductBtnText: quiz.results_screen_shop_link_button_copy,
      shopProductBtnTextColor: quiz.results_screen_shop_link_text_color,
      shopProductBtnBackgroundColor:
        quiz.results_screen_shop_link_background_color,
      shopProductBtnBorderColor: quiz.results_screen_shop_link_border_color,
      partnerCurrencyCode: '',
      compareCta: {
        show: quiz.results_screen_show_compare_button,
        text: quiz.results_screen_compare_button_copy,
        textColor: quiz.results_screen_compare_button_text_color,
        webCompareLink: data.compare_all_link,
        inStoreCompareLink: data.compare_all_link,
        hoverConfig: {
          textColor: quiz.results_screen_compare_button_hover_color,
          borderColor: quiz.results_screen_compare_button_hover_outline_color,
          bgColor: quiz.results_screen_compare_button_hover_color,
        },
      },
      productsPool: products.map((product) => {
        const productName = product?.product?.name;

        /* if (
          product?.product?.product_kind?.product_name === 'Braava' ||
          product?.product?.product_kind?.product_name === 'Roomba®'
        ) {
          // TODO: review later if we need to .replace(/®/i, '<sup>®</sup>'
          productName = `${product?.product?.product_kind?.product_name} ${product?.product?.name}`;
        }

        if (product?.product?.combo) {
          productName = `Roomba Combo® ${product?.product?.name}`;
        } */

        const parsedProduct = {
          productUuid: product.product.uuid,
          sku: product.product.sku,
          productData: {
            name: productName,
            imgSrc: product.product.image_url,
            description: product.product.description,
            shopLink: product.destination_link?.url,
            price: product.product.price,
            showPrice: product.product.fetch_price,
            productType: product.product.kind,
          },
        };
        return parsedProduct;
      }),
      emailSection: {
        bgColor: quiz.email_col_form_bar_background_color,
        title: quiz.email_col_form_sign_for_updt_title,
        inputPlaceholder: quiz.email_col_form_sign_for_updt_email_field_copy,
        saveYourResultsLinkText: quiz.email_col_form_sign_for_updt_title,
        showEmailContactForm:
          quiz.email_col_form_sign_for_updt_show_email_updates,
        emailCollectionOptInShow:
          quiz.email_col_form_sign_for_updt_show_optin_check_box,
        emailCollectionOptInPreselect: data.check_box_on_page_load,
        emailCollectionOptInLegalLink1Show:
          quiz.email_col_form_sign_for_updt_show_privacy_policy_link,
        emailCollectionOptInLegalLink1Link: data.privacy_policy_link,
        emailCollectionOptInLegalLink2Show:
          quiz.email_col_form_sign_for_updt_show_terms_conditions_link,
        emailCollectionOptInLegalLink2Link: data.terms_and_conditions_link,
        emailCollectionOptInTitle: quiz.eml_cl_frm_updt_shw_optin_chk_bx_cpy,
        emailCollectionOptInLegalLink1Copy:
          quiz.eml_cl_form_for_updt_show_privacy_policy_lnk_cpy,
        emailCollectionOptInLegalLink2Copy:
          quiz.eml_cl_form_sv_rslts_shw_terms_condns_lnk_cpy,
        button: {
          submit: quiz.eml_cl_frm_updt_sbmt_btn_frm_cpy,
          sending: 'Sending...',
        },
        errors: {
          empty: 'Required:',
          invalidEmail: 'Please enter a valid email',
          generic: 'An error occurred, please try again',
          no_opt_in: 'Please confirm opt in',
        },
      },
      alsoLikeConfig: {
        title: quiz.additional_results_title,
        titleColor: quiz.additional_results_title_text_color,
        bgColor: quiz.additional_results_background_color,
        show: data.show_additional_results,
        recommendableProducts: quiz.quiz_additional_products.map(
          (additionalProduct) => {
            const parsedProduct = {
              productUuid: additionalProduct.product.uuid,
              sku: additionalProduct.product.sku,
              productData: {
                name: additionalProduct.product.name,
                imgSrc: additionalProduct.product.image_url,
                description: additionalProduct.product.description,
                shopLink: getAditionalLink(
                  additionalProduct.uuid,
                  data.quiz_additional_product_links,
                ),
                showPrice: quiz.additional_results_show_product_pricing,
                price: additionalProduct.product.price,
                showWeightedBars: false,
                productType: additionalProduct.product.kind,
              },
            };
            return parsedProduct;
          },
        ),
      },
      navConfig: {
        prevText: quiz.results_screen_back_button_copy || 'back',
      },
    },
  ];
  const tot = [...initialData, ...parsedQuestions, ...resultsData];
  return tot;
};

export const viewSlice = createSlice({
  name: 'view',
  initialState: {
    status: 'idle',
    currentPageIDX: STARTING_IDX,
    answers: {},
    screensConfigs: [],
    currentScreenConfig: {},
    fastForwardStore: {},
    userPreviousSelection: {},
    blockNavigation: false,
    isScrollingBlocked: false,
    metadata: {},
  },
  reducers: {
    moveToScreen: (state, { payload }) => {
      const {
        answers,
        screensConfigs,
        currentPageIDX,
        currentScreenConfig,
        fastForwardStore,
        blockNavigation,
      } = state;

      if (blockNavigation) {
        return state;
      }

      const { nextScreenID } = payload;
      const { pageID } = currentScreenConfig;
      let desiredIDX = screensConfigs.findIndex(
        (item) => item.pageID === nextScreenID,
      );

      let newFastForwardStore = { ...fastForwardStore };

      // if not found, get the next idx
      if (desiredIDX < 0) {
        desiredIDX = getNextPageIDX(currentPageIDX, screensConfigs.length);
      } else {
        newFastForwardStore = {
          ...newFastForwardStore,
          [nextScreenID]: currentPageIDX, // landingPageID: pageFromIDX
        };
      }

      const newAnswer = parseAnswer({ payload, currentScreenConfig });

      const nextScreenConfig = screensConfigs[desiredIDX];
      const userPreviousSelection = answers[nextScreenConfig.pageID];

      // send event to dataLayer for tracking
      trackSelectedAnswer(newAnswer);

      return {
        ...state,
        answers: {
          ...answers,
          [pageID]: newAnswer,
        },
        currentPageIDX: desiredIDX,
        currentScreenConfig: nextScreenConfig,
        fastForwardStore: newFastForwardStore,
        userPreviousSelection: userPreviousSelection || {},
        blockNavigation: true,
      };
    },
    nextScreen: (state, { payload = {} }) => {
      const {
        currentPageIDX,
        currentScreenConfig,
        screensConfigs,
        answers,
        blockNavigation,
      } = state;

      if (blockNavigation) {
        return state;
      }

      const { pageID } = currentScreenConfig;
      const nextIndex = getNextPageIDX(currentPageIDX, screensConfigs.length);
      const newAnswer = parseAnswer({ payload, currentScreenConfig });
      const nextScreenConfig = screensConfigs[nextIndex];
      // grab previous selection
      const userPreviousSelection = answers[nextScreenConfig.pageID];

      // send event to dataLayer for tracking
      trackSelectedAnswer(newAnswer);

      return {
        ...state,
        answers: {
          ...answers,
          [pageID]: newAnswer,
        },
        currentPageIDX: nextIndex,
        currentScreenConfig: nextScreenConfig,
        userPreviousSelection: userPreviousSelection || {},
        blockNavigation: true,
      };
    },
    previousScreen: (state) => {
      const {
        screensConfigs,
        fastForwardStore,
        currentPageIDX,
        currentScreenConfig,
        answers,
        blockNavigation,
      } = state;
      let nextIndex = currentPageIDX - 1;

      if (nextIndex < 0) {
        nextIndex = 0; // restart
      }

      if (blockNavigation) {
        return state;
      }

      const updatedFastForwardStore = { ...fastForwardStore };
      // if there is a previous record of ff from the current screen
      // jump to that first screen instead of the previous idx one.
      if (updatedFastForwardStore[currentScreenConfig.pageID]) {
        nextIndex = updatedFastForwardStore[currentScreenConfig.pageID];
        updatedFastForwardStore[currentScreenConfig.pageID] = undefined;
      }

      const nextScreenConfig = screensConfigs[nextIndex];
      const userPreviousSelection = answers[nextScreenConfig.pageID];
      trackBacktracking();

      return {
        ...state,
        currentPageIDX: nextIndex,
        currentScreenConfig: nextScreenConfig,
        fastForwardStore: { ...updatedFastForwardStore },
        userPreviousSelection,
        blockNavigation: true,
      };
    },
    unblockNavigation: (state) => {
      if (!state.blockNavigation) {
        return state;
      }

      return {
        ...state,
        blockNavigation: false,
      };
    },
    blockScrolling: (state) => ({
      ...state,
      isScrollingBlocked: true,
    }),
    unblockScrolling: (state) => ({
      ...state,
      isScrollingBlocked: false,
    }),
  },
  extraReducers: {
    [fetchQuizData.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [fetchQuizData.fulfilled]: (state, { payload }) => ({
      ...state,
      status: 'succeeded',
      screensConfigs: parseData(payload),
      currentScreenConfig: parseData(payload)[STARTING_IDX],
      metadata: {
        partnerName: payload?.partner?.name,
        localeName: payload?.partner?.locales[0]?.name,
        localeCode: payload?.partner?.locales[0]?.code,
      },
    }),
    [fetchQuizData.rejected]: (state, { error }) => {
      console.error({
        error: error.message,
      });
      return { ...state, status: 'failed' }; // TODO: Handle when the fetch fails
    },
  },
});

export const {
  moveToScreen,
  nextScreen,
  previousScreen,
  unblockNavigation,
  blockScrolling,
  unblockScrolling,
} = viewSlice.actions;
export default viewSlice.reducer;
