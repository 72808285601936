import React, { useState, useRef } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring } from '@react-spring/web';
import {
  withResponsiveUtils,
  isMobile,
  RESPONSIVE_UTIL_PROPTYPES,
} from '../../utils/responsive/ResponsiveUtils';
import SendEmailForm from './SendEmailForm';
// Keeping this commented out since will be used soon
// import { Divisor } from './Divisor';

const FormArea = withResponsiveUtils(
  ({
    showForm,
    className,
    emailCapturePlaceholder,
    emailCaptureErrors,
    emailCaptureBtn,
    webResponsiveUtil,
    mobileResponsiveUtil,
  }) => {
    const [formSent, setFormSent] = useState(false);
    const isDesktop = !isMobile();
    const defaultFormHeight = 0;
    const formContent = useRef(null);

    const expand = isDesktop
      ? useSpring({
          config: { duration: 700 },
          width: showForm ? `${webResponsiveUtil.getWidthValue(780)}px` : '0px',
        })
      : useSpring({
          config: { duration: 700 },
          height: showForm
            ? `${
                formContent?.current?.offsetHeight ||
                mobileResponsiveUtil.getWidthValue(250)
              }px`
            : `${defaultFormHeight}px`,
        });

    return (
      <animated.div style={{ overflowX: 'hidden', ...expand }}>
        <div ref={formContent}>
          <StyledForm
            className={`email-capture-form__wrapper ${className} ${
              formSent ? 'sent' : ''
            }`}
            onSubmit={(e) => e.preventDefault()}
          >
            <SendEmailForm
              onFormSent={setFormSent}
              inputPlaceholder={emailCapturePlaceholder}
              button={emailCaptureBtn}
              errorMessages={emailCaptureErrors}
            />
          </StyledForm>
        </div>
      </animated.div>
    );
  },
);

FormArea.propTypes = {
  onClose: PropTypes.func,
  className: PropTypes.string,
  showForm: PropTypes.bool,
  title: PropTypes.string,
  ...RESPONSIVE_UTIL_PROPTYPES,
};

FormArea.defaultProps = {
  onClose: () => {},
  className: '',
  showForm: false,
  title: '',
};

const StyledForm = withResponsiveUtils(styled.form`
  &&& {
    z-index: 1;
    display: ${() => (isMobile() ? 'block' : 'flex')};
    box-sizing: border-box;
    width: 100%;
    padding: ${({ mobileResponsiveUtil }) =>
      isMobile()
        ? `${mobileResponsiveUtil.getWidthValue(
            10,
          )}px ${mobileResponsiveUtil.getWidthValue(
            50,
          )}px ${mobileResponsiveUtil.getWidthValue(40)}px`
        : '0'};

    .divisor {
      display: none;
    }
  }
`);

export default FormArea;
