import React from 'react';
import PropTypes from 'prop-types';

const IconLetter = (props) => {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.428"
      height="24.001"
      viewBox="0 0 32.428 24.001"
      {...props}
    >
      <g id="Group_2999" data-name="Group 2999" transform="translate(1 1)">
        <g id="Group_2967" data-name="Group 2967" transform="translate(0 0)">
          <rect
            id="Rectangle_223"
            data-name="Rectangle 223"
            width="30.428"
            height="22.001"
            rx="2.906"
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Path_6275"
            data-name="Path 6275"
            d="M-1720.93,1872.968l14.114,10.469a1.853,1.853,0,0,0,2.2,0l14.115-10.469"
            transform="translate(1720.93 -1870.884)"
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

IconLetter.propTypes = {
  color: PropTypes.string,
};

IconLetter.defaultProps = {
  color: '#000000',
};

export default IconLetter;
