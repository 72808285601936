import React, { useEffect, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DIRECTIONS from '../utils/enums/directions';
import {
  BACKGROUND_TYPES,
  SCREENS_BG_ANI,
} from '../utils/enums/screensBgAnimations';
import {
  withResponsiveUtils,
  isMobile,
} from '../utils/responsive/ResponsiveUtils';

const positionTop = isMobile() ? '-200%' : '-180%';
const positionRight = isMobile() ? '-300%' : '-60%';
const positionBottom = isMobile() ? '-200%' : '-180%';
const positionLeft = isMobile() ? '-380%' : '-60%';

const toPositionTop = isMobile() ? '-15%' : '-75%';
const toPositionRight = isMobile() ? '-75%' : '-50%';
const toPositionBottom = isMobile() ? '-15%' : '-100%';
const toPositionLeft = isMobile() ? '-75%' : '-50%';

const BG_ANIMATIONS_DIRECTIONS = {
  [DIRECTIONS.TOP]: {
    from: { top: positionTop },
    to: { top: toPositionTop },
  },
  [DIRECTIONS.TOP_LEFT]: {
    from: {
      top: positionTop,
      left: positionLeft,
    },
    to: {
      top: toPositionTop,
      left: toPositionLeft,
    },
  },
  [DIRECTIONS.TOP_RIGHT]: {
    from: {
      top: positionTop,
      right: positionRight,
    },
    to: {
      top: toPositionTop,
      right: toPositionRight,
    },
  },
  [DIRECTIONS.BOTTOM]: {
    from: { bottom: positionBottom },
    to: { bottom: toPositionBottom },
  },
  [DIRECTIONS.BOTTOM_RIGHT]: {
    from: {
      bottom: positionBottom,
      right: positionRight,
    },
    to: {
      bottom: toPositionBottom,
      right: toPositionRight,
    },
  },
  [DIRECTIONS.BOTTOM_LEFT]: {
    from: {
      bottom: positionBottom,
      left: positionLeft,
    },
    to: {
      bottom: toPositionBottom,
      left: toPositionLeft,
    },
  },
  [DIRECTIONS.LEFT]: {
    from: {
      left: positionLeft,
    },
    to: {
      left: toPositionLeft,
    },
  },
  [DIRECTIONS.RIGHT]: {
    from: {
      right: positionRight,
    },
    to: {
      right: toPositionRight,
    },
  },
};

const BGLayer = ({
  movingFrom,
  type,
  bgColor,
  status,
  isDesktop,
  deskBgSkin,
  mobBgSkin,
}) => {
  const CIRCLE_SIZE = {
    SMALL: isDesktop ? '0vw' : '130vh',
    BIG: isDesktop ? '225vw' : '225vh',
  };

  const CIRCLE_SIZE_ANI = {
    GROW: {
      from: {
        height: CIRCLE_SIZE.SMALL,
        width: CIRCLE_SIZE.SMALL,
      },
      to: {
        height: CIRCLE_SIZE.BIG,
        width: CIRCLE_SIZE.BIG,
      },
    },
  };

  const aniSize = CIRCLE_SIZE_ANI.GROW;
  const aniDirection = movingFrom && BG_ANIMATIONS_DIRECTIONS[movingFrom];

  const getAnimationConfig = (animationStatus) => {
    let from;
    let to;
    switch (animationStatus) {
      case 'past':
        from = { opacity: 1 };
        to = {};
        break;

      case 'grow':
        from = { opacity: 0.5 };
        to = { ...(isDesktop ? aniSize.to : aniDirection.to), opacity: 1 };
        break;

      case 'shrink':
        from = { opacity: 1 };
        to = {
          ...(isDesktop ? aniSize.from : aniDirection.from),
          opacity: 0.5,
        };
        break;

      default:
      case 'small':
        from = { opacity: 1 };
        to = {
          ...(isDesktop ? aniSize.from : aniDirection.from),
          opacity: 0.5,
        };
        break;
    }

    return {
      from: {
        ...aniSize.from,
        ...aniDirection.from,
        ...from,
      },
      to,
      config: {
        duration: 1000,
      },
    };
  };

  const animation = useSpring(getAnimationConfig(status));

  const className = `${status}`;

  const bgSkin = isDesktop ? deskBgSkin : mobBgSkin;

  return bgSkin ? (
    <StyledBGSkin {...{ isDesktop, bgSkin }} />
  ) : (
    <StyledBGLayer
      style={type === BACKGROUND_TYPES.CIRCLE ? animation : {}}
      className={`${className} ${movingFrom?.toLowerCase()} ${type.toLowerCase()}`}
      {...{ bgColor, isDesktop, type }}
    />
  );
};

BGLayer.propTypes = {
  movingFrom: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  deskBgSkin: PropTypes.string,
  mobBgSkin: PropTypes.string,
};

BGLayer.defaultProps = {
  deskBgSkin: '',
  mobBgSkin: '',
};

const StyledBGSkin = styled.div`
  &&& {
    background-image: ${({ bgSkin }) => (bgSkin ? `url(${bgSkin})` : 'none')};
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`;

const StyledBGLayer = animated(styled.div`
  &&& {
    background-color: ${({ bgColor }) => bgColor};
    position: absolute;
    border-radius: 200vh;

    &.past {
      height: 200vw;
      width: 200vw;
    }

    &.bottom,
    &.top {
      transform: ${({ isDesktop }) => (isDesktop ? '' : 'translateX(-50%)')};
      left: ${({ isDesktop }) => (isDesktop ? '' : '50%')};
    }

    &.right,
    &.left {
      transform: ${({ isDesktop }) =>
        isDesktop ? 'none' : 'translateY(-50%)'};
      top: ${({ isDesktop }) => (isDesktop ? '' : '50%')};
    }

    &.past.none,
    &.grow.none {
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      border-radius: unset;
      transform: none;
    }
  }
`);

const getStatus = ({ isNavigatingBack, currentPageIDX, index }) => {
  let status = 'small';

  if (index <= currentPageIDX) {
    status = 'past';
  }

  if (!isNavigatingBack && index === currentPageIDX) {
    status = 'grow';
  }

  if (isNavigatingBack && index === currentPageIDX + 1) {
    status = 'shrink';
  }

  return status;
};

const Background = ({ isNavigatingBack }) => {
  const isDesktop = !isMobile();
  const [backgrounds, setBackgrounds] = useState(null);
  const { currentPageIDX, screensConfigs } = useSelector((state) => state.view);

  useEffect(() => {
    setBackgrounds(
      screensConfigs.map((item) => ({
        ...item,
        ...SCREENS_BG_ANI[item.templateType][isDesktop ? 'desktop' : 'mobile'],
      })),
    );
  }, [screensConfigs, isDesktop]);

  return (
    <StyledBackground className="background">
      {backgrounds?.length
        ? backgrounds.map(
            ({ pageID, movingFrom, type, bgColor, ...props }, index) => (
              <BGLayer
                key={pageID}
                status={getStatus({
                  isNavigatingBack,
                  currentPageIDX,
                  index,
                })}
                {...{
                  pageID,
                  isNavigatingBack,
                  movingFrom,
                  type,
                  bgColor,
                  isDesktop,
                  ...props,
                }}
              />
            ),
          )
        : null}
    </StyledBackground>
  );
};
const StyledBackground = withResponsiveUtils(styled.div`
  &&& {
    overflow: hidden;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
`);

Background.propTypes = {
  isNavigatingBack: PropTypes.bool,
};

Background.defaultProps = {
  isNavigatingBack: false,
};

export default Background;
