import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { isMobile } from '../../utils/responsive/ResponsiveUtils';

import DesktopSaveYourResults from './DesktopSaveYourResults';
import MobileSaveYourResults from './MobileSaveYourResults';
import { blockScrolling, unblockScrolling } from '../../stores/viewSlice';
import DropAHint from '../DropAHint';

const SaveYourResults = ({
  dropAHintSection,
  showEmailContactForm,
  emailCaptureTitle,
  emailCaptureBtnLabel,
  dropAHintBtnLabel,
  bgColor,
  emailCapturePlaceholder,
  emailCaptureErrors,
  emailCaptureBtn,
}) => {
  const isDesktop = !isMobile();
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();
  const [isDropAHintOpen, setIsDropAHintOpen] = useState(false);

  const openDropAHint = () => setIsDropAHintOpen(true);
  const closeDropAHint = () => setIsDropAHintOpen(false);

  useEffect(() => {
    if (isDropAHintOpen) {
      dispatch(blockScrolling());
    } else {
      dispatch(unblockScrolling());
    }
  }, [isDropAHintOpen, dispatch]);

  return (
    <>
      {isDropAHintOpen ? (
        <DropAHint onClose={closeDropAHint} dropAHintData={dropAHintSection} />
      ) : null}
      {isDesktop ? (
        <DesktopSaveYourResults
          showForm={showForm}
          setShowForm={setShowForm}
          showDropAHint={dropAHintSection?.resultsShowShareWithFriends}
          showEmailContactForm={showEmailContactForm}
          emailCaptureTitle={emailCaptureTitle}
          saveYourResultsLinkText={emailCaptureBtnLabel}
          dropAHintLinkText={dropAHintBtnLabel}
          bgColor={bgColor}
          emailCaptureBtn={emailCaptureBtn}
          emailCaptureErrors={emailCaptureErrors}
          emailCapturePlaceholder={emailCapturePlaceholder}
          handleDropAHintClick={openDropAHint}
        />
      ) : (
        <MobileSaveYourResults
          showForm={showForm}
          setShowForm={setShowForm}
          showDropAHint={dropAHintSection?.resultsShowShareWithFriends}
          showEmailContactForm={showEmailContactForm}
          emailCaptureTitle={emailCaptureTitle}
          saveYourResultsLinkText={emailCaptureBtnLabel}
          dropAHintLinkText={dropAHintBtnLabel}
          bgColor={bgColor}
          emailCaptureBtn={emailCaptureBtn}
          emailCaptureErrors={emailCaptureErrors}
          emailCapturePlaceholder={emailCapturePlaceholder}
          handleDropAHintClick={openDropAHint}
        />
      )}
    </>
  );
};

SaveYourResults.propTypes = {
  showEmailContactForm: PropTypes.bool,
  emailCaptureTitle: PropTypes.string,
  emailCaptureBtnLabel: PropTypes.string,
  dropAHintBtnLabel: PropTypes.string,
  bgColor: PropTypes.string,
  emailCapturePlaceholder: PropTypes.string,
  emailCaptureErrors: PropTypes.shape(),
  emailCaptureBtn: PropTypes.shape({
    submit: PropTypes.string,
    sending: PropTypes.string,
  }),
  dropAHintSection: DropAHint.propTypes.dropAHintData,
};

SaveYourResults.defaultProps = {
  showEmailContactForm: false,
  emailCaptureTitle: '',
  emailCaptureBtnLabel: '',
  dropAHintBtnLabel: '',
  bgColor: PropTypes.string,
  emailCapturePlaceholder: '',
  emailCaptureErrors: {},
  emailCaptureBtn: {},
  dropAHintSection: DropAHint.defaultProps.dropAHintData,
};

export default SaveYourResults;
