import React from 'react';
import PropTypes from 'prop-types';
import { animated, useSpring } from '@react-spring/web';
import styled from 'styled-components';
import { withResponsiveUtils } from '../../utils/responsive/ResponsiveUtils';
import { fontVariables } from '../../styles/variables';
import IconClose from '../IconClose';
import IconLetter from '../IconLetter';
import Button from './Button';
import FormArea from './FormArea';
import DropAHintBtn from './DropAHintBtn';

const MobileSaveYourResults = withResponsiveUtils(
  ({
    showForm,
    setShowForm,
    emailCaptureTitle,
    mobileResponsiveUtil,
    bgColor,
    saveYourResultsLinkText,
    dropAHintLinkText,
    handleDropAHintClick,
    emailCapturePlaceholder,
    emailCaptureErrors,
    emailCaptureBtn,
    showEmailContactForm,
    showDropAHint,
  }) => {
    const roundBorders = useSpring({
      borderRadius: showForm
        ? `${mobileResponsiveUtil.getWidthValue(
            30,
          )}px ${mobileResponsiveUtil.getWidthValue(30)}px 0px 0px`
        : '0px 0px 0px 0px',
      paddingTop: `${mobileResponsiveUtil.getWidthValue(showForm ? 60 : 15)}px`,
      boxShadow: showForm
        ? '0px -17px 15px 12px rgba(0, 0, 0, .05)'
        : '0px 0px 0px 0px rgba(0, 0, 0, .05)',
    });

    return (
      <StyledMobileSaveYourResults>
        {(showEmailContactForm || showDropAHint) && (
          <AnimatedButtons
            className="buttons"
            style={roundBorders}
            bgColor={bgColor}
          >
            <ButtonsContainer className={`${!showDropAHint ? 'centered' : ''}`}>
              {showEmailContactForm && (
                <SaveResultsButton
                  className={`${showForm ? 'clicked' : ''}`}
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  <IconLetter color={showForm ? '#DCE9D5' : '#fff'} />{' '}
                  <Label className={`${showForm ? 'active' : ''}`}>
                    {saveYourResultsLinkText}
                  </Label>
                </SaveResultsButton>
              )}
              {showDropAHint && (
                <DropAHintBtn
                  text={dropAHintLinkText}
                  handleClick={handleDropAHintClick}
                />
              )}
            </ButtonsContainer>
            {showForm && (
              <StyledIconClose
                className="ico-close"
                aria-label="close"
                role="img"
                onClick={() => setShowForm(false)}
              />
            )}
          </AnimatedButtons>
        )}
        <FormAreaContainer>
          <FormArea
            title={emailCaptureTitle}
            showForm={showForm}
            onClose={() => null}
            emailCaptureBtn={emailCaptureBtn}
            emailCapturePlaceholder={emailCapturePlaceholder}
            emailCaptureErrors={emailCaptureErrors}
          />
        </FormAreaContainer>
      </StyledMobileSaveYourResults>
    );
  },
);

MobileSaveYourResults.propTypes = {
  showForm: PropTypes.bool,
  setShowForm: PropTypes.func,
  mobileResponsiveUtil: PropTypes.func,
  bgColor: PropTypes.string,
  saveYourResultsLinkText: PropTypes.string,
  dropAHintLinkText: PropTypes.string,
  handleDropAHintClick: PropTypes.func,
};

MobileSaveYourResults.defaultProps = {
  showForm: false,
  setShowForm: () => {},
  mobileResponsiveUtil: () => {},
  bgColor: '',
  saveYourResultsLinkText: '',
  dropAHintLinkText: '',
  handleDropAHintClick: () => {},
};

const StyledMobileSaveYourResults = styled.div``;

const AnimatedButtons = animated(
  withResponsiveUtils(styled.div`
    &&& {
      background-color: ${({ bgColor }) => bgColor};
      position: relative;
      padding-top: ${({ mobileResponsiveUtil }) =>
        mobileResponsiveUtil.getWidthValue(15)}px;
      padding-right: ${({ mobileResponsiveUtil }) =>
        mobileResponsiveUtil.getWidthValue(50)}px;
      padding-bottom: ${({ mobileResponsiveUtil }) =>
        mobileResponsiveUtil.getWidthValue(15)}px;
      padding-left: ${({ mobileResponsiveUtil }) =>
        mobileResponsiveUtil.getWidthValue(50)}px;
    }
  `),
);

const StyledIconClose = withResponsiveUtils(styled(IconClose)`
  &&& {
    position: absolute;
    right: ${({ mobileResponsiveUtil }) =>
      mobileResponsiveUtil.getWidthValue(20)}px;
    top: ${({ mobileResponsiveUtil }) =>
      mobileResponsiveUtil.getWidthValue(20)}px;
  }
`);

const ButtonsContainer = styled.div`
  &&& {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: 100%;

    &.centered {
      justify-content: center;
    }
  }
`;

const SaveResultsButton = styled(Button)`
  &.clicked {
    color: #dce9d5;
  }

  svg {
    height: 120%;
    position: relative;
    top: -1px;
  }
`;

const Label = withResponsiveUtils(styled.span`
  &&& {
    display: block;
    font-size: ${({ mobileResponsiveUtil }) =>
      mobileResponsiveUtil.getWidthValue(12)}px;
    line-height: normal;
    max-width: ${({ mobileResponsiveUtil }) =>
      mobileResponsiveUtil.getWidthValue(120)}px;
    font-family: ${fontVariables.families.primaryFont};
    font-weight: ${fontVariables.weights.bold};
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    margin-left: ${({ mobileResponsiveUtil }) =>
      mobileResponsiveUtil.getWidthValue(15)}px;

    &.active {
      color: #dce9d5;
    }
  }
`);

const FormAreaContainer = withResponsiveUtils(styled.div`
  background-color: #518a70;
  box-sizing: border-box;
  margin-top: ${({ mobileResponsiveUtil }) =>
    mobileResponsiveUtil.getWidthValue(-30)}px;

  .form {
    .form--header {
      display: none;
    }

    .input-container {
      margin: 0 auto;
      margin-bottom: ${({ mobileResponsiveUtil }) =>
        mobileResponsiveUtil.getWidthValue(16)}px;
    }
  }

  .form.sent {
    .submit {
      margin: 0 auto;
      padding: ${({ mobileResponsiveUtil }) =>
        mobileResponsiveUtil.getWidthValue(16)}px;

      .submit--icon {
        height: ${({ mobileResponsiveUtil }) =>
          mobileResponsiveUtil.getWidthValue(18)}px;
      }
    }
  }
`);

export default MobileSaveYourResults;
