/**
 * helper function to get the next IDX taking in count the next and
 * the max value. This will return 0 if limit is reached or passed.
 * @param {number} currentIDX
 * @param {number} maxIDX
 * @returns number
 */
export default function getNextPageIDX(currentIDX, maxIDX) {
  let nextIDX = currentIDX + 1;

  if (nextIDX >= maxIDX) {
    nextIDX = 0;
  }

  return nextIDX;
}
