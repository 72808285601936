export const leftToRightConfig = {
  config: { duration: 700 },
  from: { x: '-50%', opacity: 0.2 },
  to: { x: '0%', opacity: 1 },
  delay: 200,
};

export const rightToLeftConfig = {
  config: { duration: 700 },
  from: { x: '50%', opacity: 0.2 },
  to: { x: '0%', opacity: 1 },
  delay: 200,
};

export const fadingInConfig = {
  config: { duration: 700 },
  from: { opacity: 0 },
  to: { opacity: 1 },
  delay: 200,
};

export const fadingOutConfig = {
  config: { duration: 700 },
  from: { opacity: 1 },
  to: { opacity: 0 },
  delay: 200,
};

export const topToBottomConfig = {
  config: { duration: 700 },
  from: { y: '-50%', opacity: 0.2 },
  to: { y: '0%', opacity: 1 },
  delay: 200,
};

export const bottomToTopConfig = {
  config: { duration: 700 },
  from: { y: '50%', opacity: 0.2 },
  to: { y: '0%', opacity: 1 },
  delay: 200,
};

export const topToBottomRightToLeft = {
  config: { duration: 700 },
  from: {
    y: '-50%',
    x: '50%',
    opacity: 0.2,
  },
  to: {
    y: '0%',
    x: '0%',
    opacity: 1,
  },
  delay: 200,
};

export const fadingFromFarRight = {
  from: { x: '200%', opacity: 0 },
  to: { x: '0%', opacity: 1 },
};

export const fadingFromFarLeft = {
  from: { x: '-200%', opacity: 0 },
  to: { x: '0%', opacity: 1 },
};

export const fadingToFarRight = {
  from: { x: '0%', opacity: 1 },
  to: { x: '100%', opacity: 0 },
};

export const fadingToFarLeft = {
  from: { x: '0%', opacity: 1 },
  to: { x: '-200%', opacity: 0 },
};
