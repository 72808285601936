const DIRECTIONS = {
  TOP: 'TOP',
  TOP_LEFT: 'TOP_LEFT',
  TOP_RIGHT: 'TOP_RIGHT',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM: 'BOTTOM',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
};

export default DIRECTIONS;
