import BudgetTemplate from './BudgetTemplate';
import RadioIconQuestionTemplate from './RadioIconQuestionTemplate';
import FourAnswerIllustrationQuestionTemplate from './FourAnswerIllustrationQuestionTemplate';
import KickoffQuestionTemplate from './KickoffQuestionTemplate';
import MultipleChoiceQuestionTemplate from './MultipleChoiceQuestionTemplate';
import QuizStartTemplate from './QuizStartTemplate';
import SimpleQuestionTemplate from './SimpleQuestionTemplate';
import SliderQuestionTemplate from './SliderQuestionTemplate';
import TwoAnswerIllustrationTemplate from './TwoAnswerIllustrationTemplate';
import { YesOrNoTemplate } from './YesOrNoTemplate';
import ResultsTemplate from './ResultsTemplate';

const TEMPLATES = {
  QuizStartTemplate,
  KickoffQuestionTemplate,
  FourAnswerIllustrationQuestionTemplate,
  BudgetTemplate,
  TwoAnswerIllustrationTemplate,
  RadioIconQuestionTemplate,
  MultipleChoiceQuestionTemplate,
  SliderQuestionTemplate,
  SimpleQuestionTemplate,
  ResultsTemplate,
  YesOrNoTemplate,
};

export default TEMPLATES;
