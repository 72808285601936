import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring } from '@react-spring/web';
import { fontVariables } from '../styles/variables';
import {
  withResponsiveUtils,
  RESPONSIVE_UTIL_PROPTYPES,
} from '../utils/responsive/ResponsiveUtils';
import NavFooter from '../components/NavFooter';
import { rightToLeftConfig, leftToRightConfig } from '../utils/animations';
import { WEIGHTS_PROPTYPES } from '../utils/templatesPropTypes';
import DIRECTIONS from '../utils/enums/directions';
import { BLOCKING_SCREEN_TIME } from '../utils/constants';
import OptionCard, { optionCardParser } from '../components/OptionCard';
import normalizeLineBreaks from '../utils/normalizeLineBreaks';

function TwoAnswerIllustrationTemplate({
  mobileResponsiveUtil,
  webResponsiveUtil,
  isDesktop,
  pageData,
  handleNavigationPrevScreen,
  handleNextScreen,
  isTransitioning,
  userPreviousSelection: { selectedOptions },
  handleUnblockNavigation,
  isQr,
}) {
  const { title, titleColor, options, position } = pageData;

  const toXValue = isDesktop ? 3 : 10;

  const aniLtR = useSpring({
    ...leftToRightConfig,
    to: { x: `-${toXValue}%`, opacity: 1 },
    ...(isDesktop ? { from: { x: '-125%', opacity: 0 } } : {}),
  });

  const aniRtL = useSpring({
    ...rightToLeftConfig,
    to: { x: `${toXValue}%`, opacity: 1 },
    ...(isDesktop ? { from: { x: '125%', opacity: 0 } } : {}),
  });

  const titleAnimation = useSpring({
    ...rightToLeftConfig,
  });

  const dimensions = [
    {
      mobile: {
        height: isQr ? 285 : 250,
        left: -30,
        right: -210,
        width: 415,
      },
      desktop: {
        height: 410,
        left: -180,
        right: -370,
        width: 700,
      },
    },
    {
      mobile: {
        height: 283,
        right: 38,
        left: -195,
        width: 355,
      },
      desktop: {
        height: 410,
        left: -300,
        right: 50,
        width: 500,
      },
    },
  ];

  const positions = [DIRECTIONS.LEFT, DIRECTIONS.RIGHT];

  const handleNavNextScreen = (payload) => {
    handleNextScreen({ ...payload });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleUnblockNavigation();
    }, BLOCKING_SCREEN_TIME);

    return () => clearTimeout(timer);
  }, [handleUnblockNavigation]);

  return (
    <Container
      className={isTransitioning ? 'fade' : ''}
      {...{
        isDesktop,
        mobileResponsiveUtil,
        webResponsiveUtil,
        titleColor,
      }}
    >
      <animated.h1 className="page__title" style={titleAnimation}>
        {normalizeLineBreaks(title)}
      </animated.h1>
      <div className="card-container">
        {options
          .map(optionCardParser(dimensions))
          .map((item, index) => ({ ...item, imgPosition: positions[index] }))
          .map(
            ({
              value,
              ComponentImage,
              altText,
              imgPosition,
              weights,
              text,
              summaryText,
            }) => (
              <OptionCard
                key={value}
                active={selectedOptions === value}
                style={imgPosition === 'RIGHT' ? aniRtL : aniLtR}
                ComponentImage={ComponentImage}
                altText={altText}
                imgPosition={imgPosition}
                text={normalizeLineBreaks(text)}
                onClick={() =>
                  handleNavNextScreen({
                    selectedOptions: value,
                    weights,
                    selectedText: text,
                    summaryText,
                    questionPosition: position,
                  })
                }
              />
            ),
          )}
      </div>
      <NavFooter
        prevAction={handleNavigationPrevScreen}
        color="white"
        showPrev
      />
    </Container>
  );
}

TwoAnswerIllustrationTemplate.propTypes = {
  pageData: PropTypes.shape({
    pageID: PropTypes.string,
    templateType: PropTypes.string,
    title: PropTypes.string,
    titleColor: PropTypes.string,
    bgColor: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        svgSrc: PropTypes.string,
        altText: PropTypes.string,
        value: PropTypes.string,
        weights: WEIGHTS_PROPTYPES,
      }),
    ),
    navConfig: PropTypes.shape({}),
  }),
  handleNavigationPrevScreen: PropTypes.func,
  handleNextScreen: PropTypes.func,
  isTransitioning: PropTypes.bool,
  userPreviousSelection: PropTypes.shape({
    selectedOptions: PropTypes.string,
  }),
  handleUnblockNavigation: PropTypes.func,
  ...RESPONSIVE_UTIL_PROPTYPES,
};

TwoAnswerIllustrationTemplate.defaultProps = {
  pageData: {
    options: [],
    navConfig: {},
  },
  handleNavigationPrevScreen: () => null,
  handleNextScreen: () => null,
  isTransitioning: false,
  userPreviousSelection: {
    selectedOptions: '',
  },
  handleUnblockNavigation: () => null,
};

export default TwoAnswerIllustrationTemplate;

const Container = withResponsiveUtils(styled.div`
  &&& {
    color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    overflow-x: hidden;
    width: 100%;
    padding-top: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(50)
        : mobileResponsiveUtil.getWidthValue(32)}px;
    padding-left: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(18)
        : mobileResponsiveUtil.getWidthValue(18)}px;

    padding-right: ${({ webResponsiveUtil, mobileResponsiveUtil, isDesktop }) =>
      isDesktop
        ? webResponsiveUtil.getWidthValue(18)
        : mobileResponsiveUtil.getWidthValue(18)}px;

    .page__title {
      white-space: ${({ isDesktop }) => (isDesktop ? 'pre-wrap' : 'normal')};
      color: ${({ titleColor }) => titleColor};
      font-size: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(58)
          : mobileResponsiveUtil.getWidthValue(26)}px;
      font-family: ${fontVariables.families.primaryFont}, sans-serif;
      font-weight: ${fontVariables.weights.bold};
      width: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(950)
          : mobileResponsiveUtil.getWidthValue(300)}px;
      line-height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(60)
          : mobileResponsiveUtil.getWidthValue(35)}px;
    }

    .card-container {
      align-items: center;
      display: flex;
      flex-direction: ${({ isDesktop }) => (isDesktop ? 'row' : 'column')};
      justify-content: ${({ isDesktop }) =>
        isDesktop ? 'center' : 'space-between'};
      height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
        isDesktop
          ? webResponsiveUtil.getWidthValue(500)
          : mobileResponsiveUtil.getWidthValue(410)}px;

      margin-top: ${({ isDesktop, mobileResponsiveUtil }) =>
        isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(30)}px;

      margin-bottom: ${({ isDesktop, mobileResponsiveUtil }) =>
        isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(60)}px;

      .option-card {
        font-family: ${fontVariables.families.primaryFont};
        color: black;
        height: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(247)
            : mobileResponsiveUtil.getWidthValue(168)}px;
        width: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
          isDesktop
            ? webResponsiveUtil.getWidthValue(536)
            : mobileResponsiveUtil.getWidthValue(412)}px;

        .text-container {
          font-size: ${({
            isDesktop,
            webResponsiveUtil,
            mobileResponsiveUtil,
          }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(26)
              : mobileResponsiveUtil.getWidthValue(18)}px;
          width: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(210)
              : mobileResponsiveUtil.getWidthValue(100)}px;
          padding: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
            isDesktop
              ? `${webResponsiveUtil.getWidthValue(20)}px
            ${webResponsiveUtil.getWidthValue(10)}`
              : `${mobileResponsiveUtil.getWidthValue(20)}px
              ${mobileResponsiveUtil.getWidthValue(20)}`}px;

          box-sizing: content-box;
        }

        .img-container {
          left: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(60)
              : mobileResponsiveUtil.getWidthValue(-6)}px;
        }
      }

      .option-card.option-card--left {
        left: 50%;

        margin-bottom: ${({ isDesktop, mobileResponsiveUtil }) =>
          isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(50)}px;

        .text-container {
          width: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(150)
              : mobileResponsiveUtil.getWidthValue(100)}px;
          padding-left: ${({
            isDesktop,
            webResponsiveUtil,
            mobileResponsiveUtil,
          }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(100)
              : mobileResponsiveUtil.getWidthValue(56)}px;
        }

        .img-container .image {
          bottom: ${({ isDesktop, mobileResponsiveUtil }) =>
            isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(-7)}px;
        }
      }

      .option-card.option-card--right {
        right: 50%;

        .text-container {
          position: relative;
          left: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(-100)
              : mobileResponsiveUtil.getWidthValue(-40)}px;
          align-items: ${({ isDesktop }) =>
            isDesktop ? 'flex-start' : 'center'};
          padding-right: ${({ isDesktop, mobileResponsiveUtil }) =>
            isDesktop ? 0 : mobileResponsiveUtil.getWidthValue(56)}px;

          width: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
            isDesktop
              ? webResponsiveUtil.getWidthValue(130)
              : mobileResponsiveUtil.getWidthValue(90)}px;
        }

        .img-container {
          .image {
            overflow: unset;
          }

          svg {
            bottom: ${({
              isDesktop,
              webResponsiveUtil,
              mobileResponsiveUtil,
            }) =>
              isDesktop
                ? webResponsiveUtil.getWidthValue(169)
                : mobileResponsiveUtil.getWidthValue(115)}px;
            height: auto;
            left: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
              isDesktop
                ? webResponsiveUtil.getWidthValue(-321)
                : mobileResponsiveUtil.getWidthValue(-153)}px;
            position: relative;
            width: ${({ isDesktop, webResponsiveUtil, mobileResponsiveUtil }) =>
              isDesktop
                ? webResponsiveUtil.getWidthValue(530)
                : mobileResponsiveUtil.getWidthValue(357)}px;
          }
        }
      }
    }

    &.fade {
      .page__title,
      .card-container {
        opacity: 0;
        transition: 0.5s all ease-in;
      }
    }
  }
`);
