import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  withResponsiveUtils,
  isMobile,
  RESPONSIVE_UTIL_PROPTYPES,
} from '../utils/responsive/ResponsiveUtils';

const SVGWrapper = ({ svgSrc, altText, dimensions }) => (
  <StyledSVGWrapper
    className="image"
    mobile={dimensions.mobile}
    desktop={dimensions.desktop}
  >
    <img alt={altText} {...{ src: svgSrc }} />
  </StyledSVGWrapper>
);

const StyledSVGWrapper = withResponsiveUtils(styled.div`
  &&& {
    height: auto;
    overflow: hidden;
    max-height: ${({
      mobile,
      desktop,
      mobileResponsiveUtil,
      webResponsiveUtil,
    }) =>
      isMobile()
        ? mobileResponsiveUtil.getWidthValue(mobile.height)
        : webResponsiveUtil.getWidthValue(desktop.height)}px;

    right: ${({ mobile, desktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      isMobile()
        ? mobileResponsiveUtil.getWidthValue(mobile.right)
        : webResponsiveUtil.getWidthValue(desktop.right)}px;

    left: ${({ mobile, desktop, mobileResponsiveUtil, webResponsiveUtil }) =>
      isMobile()
        ? mobileResponsiveUtil.getWidthValue(mobile.left)
        : webResponsiveUtil.getWidthValue(desktop.left)}px;

    img {
      height: auto;
      width: ${({ mobile, desktop, mobileResponsiveUtil, webResponsiveUtil }) =>
        isMobile()
          ? mobileResponsiveUtil.getWidthValue(mobile.width)
          : webResponsiveUtil.getWidthValue(desktop.width)}px;
    }
  }
`);

SVGWrapper.propTypes = {
  dimensions: PropTypes.shape({}),
  svgSrc: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,

  ...RESPONSIVE_UTIL_PROPTYPES,
};

SVGWrapper.defaultProps = {
  dimensions: null,
};

export default SVGWrapper;
